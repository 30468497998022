import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';

import { Filters } from '../../services/entities/filters/filters';
import { CustomerFilters } from '../../services/entities/filters/customer-filters';
import { FilterType } from '../../constants/filters.constants';
import { FiltersManager } from '../filters/entities/filters-manager';
import { FilterSelection } from '../filters/entities/filter-selection';
import { BlockedFilter } from '../filters/entities/blocked-filter';
import { SelectedFilters } from '../../services/entities/filters/selected-filters';

import { FiltersService } from '../../services/filters.service';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { AlliancesComponent } from '../filters/components/alliances/alliances.component';
import { GrowthPrioritiesComponent } from '../filters/components/growth-priorities/growth-priorities.component';
import { FunctionsComponent } from '../filters/components/functions/functions.component';
import { IndustryComponent } from '../filters/components/industry/industry.component';
import { ServiceGroupComponent } from '../filters/components/service-group/service-group.component';
import { ClientGroupComponent } from '../filters/components/client-group/client-group.component';
import { MarketComponent } from '../filters/components/market/market.component';
import { WmuFcComponent } from '../filters/components/wmu-fc/wmu-fc.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared-module';

@Component({
  selector: 'app-filters-accordion',
  templateUrl: './filters-accordion.component.html',
  styleUrls: ['./filters-accordion.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    FontAwesomeModule,
    WmuFcComponent,
    MarketComponent,
    ClientGroupComponent,
    ServiceGroupComponent,
    IndustryComponent,
    FunctionsComponent,
    GrowthPrioritiesComponent,
    AlliancesComponent,
  ],
})
export class FiltersAccordionComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  @Output() hasChangedEvent = new EventEmitter<boolean>();
  @Output() filtersSelectionChangedEvent = new EventEmitter<FiltersManager>();

  filters: Filters;
  customerFilters: CustomerFilters;
  selectedFilters: SelectedFilters;
  filtersManager = new FiltersManager();
  blockedFilters: Array<BlockedFilter> = [];

  faAngleRight = faAngleRight as IconProp;
  faAngleDown = faAngleDown as IconProp;

  public get filterTypes(): typeof FilterType {
    return FilterType;
  }

  constructor(
    private filtersService: FiltersService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getFilters();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getFilters(): void {
    this.subscription.add(
      this.filtersService.globalFiltersChanged.subscribe((x: Filters) => {
        this.filters = x;
        this.changeDetector.detectChanges();
      })
    );
    this.subscription.add(
      this.filtersService.selectedModalFiltersChanged.subscribe(
        (x: SelectedFilters) => {
          this.selectedFilters = x;
          this.changeDetector.detectChanges();
        }
      )
    );

    this.subscription.add(
      this.filtersService.customerFiltersChanged.subscribe(
        (x: CustomerFilters) => {
          this.customerFilters = x;
          this.changeDetector.detectChanges();
        }
      )
    );
    this.subscription.add(
      combineLatest([
        this.filtersService.isCloudFiltersChanged,
        this.filtersService.isCloudGlobalFiltersChanged,
      ]).subscribe(([x, y]: [CustomerFilters, Filters]) => {
        this.customerFilters = x;
        this.filters = y;
        this.changeDetector.detectChanges();
      })
    );
  }

  onItemSelection(selection: FilterSelection): void {
    switch (selection.type) {
      case FilterType.WMU:
        this.filtersManager.fc = null;
        this.filtersManager.wmu = selection;
        break;
      case FilterType.FinancialCustomer:
        this.filtersManager.wmu = null;
        this.filtersManager.fc = selection;
        break;
      case FilterType.Market:
        this.filtersManager.market = selection;
        break;
      case FilterType.ClientGroup:
        this.filtersManager.cg = selection;
        break;
      case FilterType.ServiceGroup:
        this.filtersManager.sg = selection;
        break;
      case FilterType.Industry:
        this.filtersManager.industry = selection;
        break;
      case FilterType.Function:
        this.filtersManager.functions = selection;
        break;
      case FilterType.GrowthPriority:
        this.filtersManager.gp = selection;
        break;
      case FilterType.Alliance:
        this.filtersManager.alliances = selection;
        break;
    }

    this.changeDetector.detectChanges();
    this.setBlockedFilters(selection);
    this.checkForChanges();
    this.filtersSelectionChangedEvent.emit(this.filtersManager);
  }

  setBlockedFilters(selection: FilterSelection): void {
    if (selection.count > 0) {
      if (
        selection.blockedFilters &&
        !this.blockedFilters.some(
          (x: BlockedFilter) => x.blocker === selection.type
        )
      ) {
        this.blockedFilters.push(
          new BlockedFilter({
            blocker: selection.type,
            blocked: selection.blockedFilters,
          })
        );
      }
    } else {
      const index: number = this.blockedFilters.findIndex(
        (x: BlockedFilter) => x.blocker === selection.type
      );
      if (index > -1) {
        this.blockedFilters.splice(index, 1);
      }
    }
  }

  isFilterBlocked(type: FilterType): boolean {
    return this.blockedFilters.some((x: BlockedFilter) =>
      x.blocked.some((x2) => x2 === type)
    );
  }

  checkForChanges(): void {
    this.hasChangedEvent.emit(
      this.filtersManager.hasChanged(this.selectedFilters)
    );
  }
}
