import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { CheckBoxGroupValue } from '../../entities/checkbox-groups-value';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'app-checkbox-groups-values',
  templateUrl: './checkbox-groups-values.component.html',
  styleUrls: ['./checkbox-groups-values.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, FormsModule],
})
export class CheckboxGroupsValuesComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  @Input() values: Array<CheckBoxGroupValue> = [];
  @Input() onHeaderSelectionChangedObs: Observable<Array<any>>;
  @Output() valuesSelectionChangeEvent = new EventEmitter<
    Array<CheckBoxGroupValue>
  >();
  @Output() internalValuesSelectionChangeEvent = new EventEmitter<
    Array<CheckBoxGroupValue>
  >();

  ngOnInit(): void {
    this.addSubscriptions();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  addSubscriptions(): void {
    if (this.onHeaderSelectionChangedObs) {
      this.subscription.add(
        this.onHeaderSelectionChangedObs.subscribe((x: Array<any>) => {
          this.values.forEach((v: CheckBoxGroupValue) => {
            v.checked = x.includes(v.value);
          });

          this.onValueSelection();
        })
      );
    }
  }

  onValueSelection(): void {
    if (this.values) {
      const selectedValues: Array<CheckBoxGroupValue> = this.values.filter(
        (x: CheckBoxGroupValue) => x.checked
      );
      this.valuesSelectionChangeEvent.emit(selectedValues);
    }
  }

  onInternalValueSelection(): void {
    if (this.values) {
      const selectedValues: Array<CheckBoxGroupValue> = this.values.filter(
        (x: CheckBoxGroupValue) => x.checked
      );
      this.internalValuesSelectionChangeEvent.emit(selectedValues);
    }
  }
}
