import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable, of, OperatorFunction, Subscription } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
} from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';

import { ShareBoardModalConfig } from './entities/share-board-modal-config';
import { BoardResponseItem } from 'src/app/shared/services/entities/board-response';
import { BoardMapper } from '../../../board-modal/utils/board-modal-utils';
import { BoardSaveModel } from '../../../board-modal/entities/board-save-model';
import { MessageTemplates } from 'src/app/shared/constants/messages.constants';
import { MrdrPerson } from 'src/app/shared/services/entities/filters/mrdr-person';

import { BoardModalService } from 'src/app/shared/services/modals/board-modal.service';
import { MrdrService } from 'src/app/shared/services/mrdr.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AppMessagesService } from 'src/app/shared/services/app-messages.service';
import { BoardService } from 'src/app/shared/services/board.service';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ClipboardModule } from 'ngx-clipboard';
import { NgIf, NgFor } from '@angular/common';
import { AutoCompleteComponent } from '../../../base/auto-complete/auto-complete.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-share-board-modal',
  templateUrl: './share-board-modal.component.html',
  styleUrls: ['./share-board-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FontAwesomeModule,
    AutoCompleteComponent,
    NgIf,
    NgFor,
    ClipboardModule,
  ],
})
export class ShareBoardModalComponent implements OnInit {
  subscription = new Subscription();
  @ViewChild('shareBoardModal', { static: false }) shareBoardModal: NgbModalRef;

  currentModal: NgbModalRef;
  isOpen = false;
  config: ShareBoardModalConfig;

  personSearch: OperatorFunction<string, readonly string[]>;
  selectedPeople: Array<MrdrPerson>;

  faTimes = faTimes as IconProp;

  boardLink: string;
  isCopied = false;

  constructor(
    private modalService: NgbModal,
    private boardModalService: BoardModalService,
    private boardService: BoardService,
    private mrdrService: MrdrService,
    private sharedService: SharedService,
    private appMessagesService: AppMessagesService
  ) {}

  ngOnInit(): void {
    this.personSearch = (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((x: string) =>
          this.mrdrService.getEnterpriseIds(x).pipe(
            catchError(() => {
              return of([]);
            })
          )
        )
      );

    this.subscription.add(
      this.boardModalService.openShareBoardModalEmitted.subscribe(
        (x: ShareBoardModalConfig) => {
          this.config = x;
          const peoplekey = this.sharedService.getPeopleKey();
          const customerNumber =
            this.config.board.filters.Customer.MasterCustomerNumber;
          const boardId = this.config.board.boardId;
          const currentRoute = window.location.origin;
          this.boardLink = `${currentRoute}/share-board/${peoplekey}/${customerNumber}/${boardId}`;
          this.openModal();
        }
      )
    );
  }

  onCopied(e: any) {
    this.isCopied = e.isSuccess;
  }

  openModal() {
    if (!this.isOpen) {
      this.currentModal = this.modalService.open(this.shareBoardModal, {
        windowClass: 'share-board-modal',
        centered: true,
        size: 'sm',
        backdrop: 'static',
      });

      this.isOpen = true;

      this.subscription.add(
        this.currentModal.dismissed.subscribe(() => {
          this.isOpen = false;
        })
      );
    }
  }

  onCloseModal(): void {
    this.currentModal.dismiss();
    this.selectedPeople = null;
    this.isCopied = false;
  }

  onPersonSelected(enterpriseId: string): void {
    this.selectedPeople = this.selectedPeople ?? [];

    if (
      enterpriseId?.trim().length > 0 &&
      enterpriseId !== this.sharedService.getEnterpriseId() &&
      !this.selectedPeople.some(
        (x: MrdrPerson) => x.EnterpriseID === enterpriseId
      )
    ) {
      this.mrdrService
        .getPerson(enterpriseId)
        .then((x: MrdrPerson) => {
          this.selectedPeople.push(x);
        })
        .catch(() => {
          this.appMessagesService.show(MessageTemplates.UnexpectedError, {
            centered: true,
          });
        });
    }
  }

  onDeletePerson(person: MrdrPerson): void {
    const index: number = this.selectedPeople.findIndex(
      (x: MrdrPerson) => x.EnterpriseID === person.EnterpriseID
    );

    if (index > -1) {
      this.selectedPeople.splice(index, 1);
    }
  }

  onShare(): void {
    if (this.selectedPeople?.length > 0) {
      Promise.all(
        this.selectedPeople
          .map((x: MrdrPerson) => {
            const board: BoardResponseItem = cloneDeep(this.config.board);
            board.boardId = `${uuidv4()}#${x.PeopleKey}`;
            board.name = `${
              this.config.board.name
            } shared by ${this.sharedService.getEnterpriseId()}`;
            board.sharedBy = `${this.sharedService.getEnterpriseId()}#${
              x.EnterpriseID
            }`;
            return BoardMapper.getSaveModel(board);
          })
          .map((x: BoardSaveModel) => this.boardService.createBoard(x))
      )
        .then((x: Array<boolean>) => {
          if (x.every((x: boolean) => x)) {
            this.appMessagesService.show(
              MessageTemplates.BoardSuccessfulShared,
              {
                centered: true,
              }
            );
          } else {
            this.appMessagesService.show(MessageTemplates.UnexpectedError, {
              centered: true,
            });
          }
        })
        .catch(() => {
          this.appMessagesService.show(MessageTemplates.UnexpectedError, {
            centered: true,
          });
        })
        .finally(() => {
          this.onCloseModal();
        });
    }
  }
}
