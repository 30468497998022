import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AppToast } from '../../services/entities/app-toast';

import { AppToastsService } from '../../services/app-toasts.service';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { NgFor, NgClass } from '@angular/common';
import { SharedModule } from 'src/app/shared-module';

@Component({
  selector: 'app-toasts',
  templateUrl: './app-toasts.component.html',
  styleUrls: ['./app-toasts.component.scss'],
  standalone: true,
  imports: [NgFor, SharedModule, NgClass],
})
export class AppToastsComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  toasts: Array<AppToast>;

  constructor(private appToastService: AppToastsService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.appToastService.toastListChanged.subscribe((x: Array<AppToast>) => {
        this.toasts = x;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  remove(toast: AppToast): void {
    this.appToastService.remove(toast);
  }
}
