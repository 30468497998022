import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbNavModule,
} from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { cloneDeep, has } from 'lodash';

import { CurrencyChanged } from 'src/app/shared/components/currency/entities/currency-events';
import { FiltersManager } from 'src/app/shared/components/filters/entities/filters-manager';
import { TimeframeItem } from 'src/app/shared/components/timeframe/entities/timeframe';
import { TimeframeChanged } from 'src/app/shared/components/timeframe/entities/timeframe-events';
import { Currency } from 'src/app/shared/services/entities/filters/currency';
import { FavoriteModalConfig } from './entities/favorite-modal-config';
import {
  FavoriteFilters,
  FavoriteResponseItem,
} from 'src/app/shared/services/entities/favorite-response';
import { FavoriteMapper } from './utils/favorite-modal-utils';
import { FavoriteSaveModel } from './entities/favorite-save-model';
import { TimeframeUtils } from 'src/app/shared/components/timeframe/utils/timeframe.utils';
import {
  ComponentNames,
  GridFilters,
} from '../analytics-grid/constants/analytics-grid.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { OptionsListConfig } from 'src/app/shared/components/base/options-list/entities/options-list-config';
import { OptionItem } from 'src/app/shared/components/base/options-list/entities/option-item';
import {
  CustomOptions,
  ViewOptions,
} from 'src/app/shared/constants/grid.constants';
import {
  CustomCurrencies,
  GroupsBy,
  Periods,
  TimePeriodCodes,
} from 'src/app/shared/constants/filters.constants';
import { FavoriteModalFiltersConfig } from './entities/favorite-modal-filters-config';
import { MmbDate } from 'src/app/shared/services/entities/filters/date';
import { MessageTemplates } from 'src/app/shared/constants/messages.constants';

import { FiltersService } from 'src/app/shared/services/filters.service';
import { FavoriteService } from 'src/app/shared/services/favorite.service';
import { FavoriteModalService } from 'src/app/shared/services/modals/favorite-modal.service';
import { AppMessagesService } from 'src/app/shared/services/app-messages.service';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { UnorderedListComponent } from '../../../../shared/components/base/unordered-list/unordered-list.component';
import { OptionsListComponent } from '../../../../shared/components/base/options-list/options-list.component';
import { CurrencyComponent } from '../../../../shared/components/currency/currency.component';
import { TimeframeComponent } from '../../../../shared/components/timeframe/timeframe.component';
import { FiltersAccordionComponent } from '../../../../shared/components/filters-accordion/filters-accordion.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared-module';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';

@Component({
  selector: 'app-favorite-modal',
  templateUrl: './favorite-modal.component.html',
  styleUrls: ['./favorite-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FormsModule,
    FontAwesomeModule,
    SharedModule,
    FiltersAccordionComponent,
    TimeframeComponent,
    CurrencyComponent,
    OptionsListComponent,
    UnorderedListComponent,
  ],
})
export class FavoriteModalComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  @ViewChild('favoriteModal', { static: false }) favoriteModal: NgbModalRef;

  currentModal: NgbModalRef;
  isOpen = false;
  config: FavoriteModalConfig;

  selectedTimeframe: TimeframeItem;
  selectedCurrency: Currency;
  selectedMetrics: Array<OptionItem>;
  selectedPeriod: TextValuePair;
  selectedGroup1: TextValuePair;
  selectedGroup2: TextValuePair;
  selectedGroup3: TextValuePair;
  selectedTarget1: TextValuePair;
  selectedTarget2: TextValuePair;
  filtersManager: FiltersManager;
  favorite: FavoriteResponseItem;
  favoriteFilters: FavoriteFilters;
  favoriteName: string;

  metricsListConfig: OptionsListConfig;
  favoriteFiltersConfig: FavoriteModalFiltersConfig;

  selectedBreadcrumbs: FavoriteResponseItem;
  totalFilters: number;

  faTimes = faTimes as IconProp;

  constructor(
    private modalService: NgbModal,
    private favoriteModalService: FavoriteModalService,
    private filterService: FiltersService,
    private favoriteService: FavoriteService,
    private appMessagesService: AppMessagesService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.favoriteModalService.openFavoriteModalEmitted.subscribe(
        (x: FavoriteModalConfig) => {
          this.config = x;
          this.favorite = this.favorite ?? new FavoriteResponseItem();
          this.selectedBreadcrumbs = new FavoriteResponseItem();
          Object.setPrototypeOf(this.favorite, FavoriteResponseItem.prototype);

          this.setFormData();
          this.initFavorite();
          this.loadFilters();
          this.openModal();

          this.filterService.modalFilters(
            this.favoriteName === '',
            this.mapBoardFilterSelectedFilter(this.favorite.filters)
          );
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setFilterManager(event: FiltersManager): void {
    this.filtersManager = event;

    this.totalFilters =
      (this.filtersManager.wmu ? this.filtersManager.wmu?.count : 0) +
      (this.filtersManager.fc ? this.filtersManager.fc?.count : 0) +
      (this.filtersManager.market ? this.filtersManager.market?.count : 0) +
      (this.filtersManager.cg ? this.filtersManager.cg?.count : 0) +
      (this.filtersManager.sg ? this.filtersManager.sg?.count : 0) +
      (this.filtersManager.industry ? this.filtersManager.industry?.count : 0) +
      (this.filtersManager.functions
        ? this.filtersManager.functions?.count
        : 0) +
      (this.filtersManager.gp ? this.filtersManager.gp?.count : 0) +
      (this.filtersManager.alliances
        ? this.filtersManager.alliances?.count
        : 0);
  }

  loadFilters(): void {
    const targets: Array<TextValuePair> = GridFilters.TargetOptions(
      this.config.selectedFilters
    );

    this.favoriteFiltersConfig = new FavoriteModalFiltersConfig({
      metricListConfig: new OptionsListConfig({
        items: GridFilters.Metrics,
        multipleSelect: true,
      }),
      periods: GridFilters.Periods.map((x: TextValuePair) => {
        if (x.value !== CustomOptions.None) {
          x.params.isDisabled = () => {
            return [
              TimePeriodCodes.CurrentMonth,
              TimePeriodCodes.MonthToDate,
            ].includes(this.selectedTimeframe?.code);
          };
        }
        return x;
      }),
      groups1: cloneDeep(GridFilters.Groups)
        .filter((x: TextValuePair) => x.value !== CustomOptions.None)
        .map((x: TextValuePair) => {
          x.params.isDisabled = () => {
            return this.isGroupOptionDisabled(ComponentNames.group1, x);
          };
          return x;
        }),
      groups2: cloneDeep(GridFilters.Groups).map((x: TextValuePair) => {
        x.params.isDisabled = () => {
          return this.isGroupOptionDisabled(ComponentNames.group2, x);
        };
        return x;
      }),
      groups3: cloneDeep(GridFilters.Groups).map((x: TextValuePair) => {
        x.params.isDisabled = () => {
          return this.isGroupOptionDisabled(ComponentNames.group3, x);
        };
        return x;
      }),
      datasets1: cloneDeep(targets)
        .filter(
          (x: TextValuePair) => x.value !== CustomOptions.None.toLowerCase()
        )
        .map((x: TextValuePair) => {
          x.params.isDisabled = () => {
            return this.isDatasetOptionDisabled(ComponentNames.dataset1, x);
          };
          return x;
        }),
      datasets2: cloneDeep(targets).map((x: TextValuePair) => {
        x.params.isDisabled = () => {
          return this.isDatasetOptionDisabled(ComponentNames.dataset2, x);
        };
        return x;
      }),
    });
  }

  setFormData(): void {
    this.favoriteName = this.config.name;
    this.favoriteFilters = this.config.favoriteFilters;

    this.selectedCurrency =
      this.config.favoriteFilters.Currency ??
      this.config.selectedFilters.currency;

    this.selectedTimeframe =
      this.config.favoriteFilters.Timeframe &&
      this.config.favoriteFilters.Timeframe.TimePeriodCode !== 'Custom'
        ? TimeframeUtils.getTimeframeItemByCode(
            this.config.filters,
            this.config.favoriteFilters.Timeframe.TimePeriodCode
          )
        : this.config.selectedFilters.timeframe;

    this.selectedMetrics =
      this.config.metrics?.length > 0
        ? this.config.metrics.split(',').map((x: string) => {
            const metric: TextValuePair = GridFilters.Metrics.find(
              (y: TextValuePair) => y.value === x
            );
            return new OptionItem({
              text: metric.text,
              value: metric.value,
              checked: true,
            });
          })
        : [];

    this.selectedPeriod = TextValuePair.findValueOrDefault(
      GridFilters.Periods,
      this.config.period,
      Periods.Quarter.id
    );
    this.selectedGroup1 = TextValuePair.findValueOrDefault(
      GridFilters.Groups,
      this.config.group1,
      GroupsBy.ClientGroup.id
    );
    this.selectedGroup2 = TextValuePair.findValueOrDefault(
      GridFilters.Groups,
      this.config.group2,
      CustomOptions.None
    );
    this.selectedGroup3 = TextValuePair.findValueOrDefault(
      GridFilters.Groups,
      this.config.group3,
      CustomOptions.None
    );
    const targets: Array<TextValuePair> = GridFilters.TargetOptions(
      this.config.selectedFilters
    );
    this.selectedTarget1 = TextValuePair.findValueOrDefault(
      targets,
      this.config.target1,
      ViewOptions.Current
    );
    this.selectedTarget2 = TextValuePair.findValueOrDefault(
      targets,
      this.config.target2,
      CustomOptions.None.toLowerCase()
    );
  }

  initFavorite(): void {
    this.favorite.favoriteId = this.config.favoriteId;
    this.favorite.name = this.config.name;
    this.favorite.filters = this.favoriteFilters;
    this.favorite.metric = this.config.metrics;
    this.favorite.period = this.config.period;
    this.favorite.group1 = this.selectedGroup1.value;
    this.favorite.group2 = this.selectedGroup2.value;
    this.favorite.group3 = this.selectedGroup3.value;
    this.favorite.target1 = this.selectedTarget1.value;
    this.favorite.target2 = this.selectedTarget2.value;

    this.selectedBreadcrumbs.metric = this.config.metrics;
    this.selectedBreadcrumbs.period = this.config.period;
    this.selectedBreadcrumbs.group1 = this.selectedGroup1.text;
    this.selectedBreadcrumbs.group2 = this.selectedGroup2.text;
    this.selectedBreadcrumbs.group3 = this.selectedGroup3.text;
    this.selectedBreadcrumbs.target1 = this.selectedTarget1.text;
    this.selectedBreadcrumbs.target2 = this.selectedTarget2.text;
  }

  isGroupOptionDisabled(groupName: string, value: TextValuePair): boolean {
    const groups: Array<string> = [
      ComponentNames.group1,
      ComponentNames.group2,
      ComponentNames.group3,
    ];

    const datasets: Array<string> = [
      ComponentNames.dataset1,
      ComponentNames.dataset2,
    ];

    return (
      (this.hasValueAlreadySelected(
        groups.filter((x: string) => x !== groupName),
        value.value
      ) ||
        ([GroupsBy.Composition.id, GroupsBy.ByRecord.id].includes(
          value.value
        ) &&
          this.hasValueAlreadySelected(datasets, ViewOptions.Plan) &&
          !this.config.appState.planCodeEnabled)) &&
      value.value !== CustomOptions.None
    );
  }

  isDatasetOptionDisabled(dataset: string, value: TextValuePair): boolean {
    const datasets: Array<string> = [
      ComponentNames.dataset1,
      ComponentNames.dataset2,
    ];

    const groups: Array<string> = [
      ComponentNames.group1,
      ComponentNames.group2,
      ComponentNames.group3,
    ];

    const isPlanDisabled: boolean =
      value.value === ViewOptions.Plan &&
      (this.config.selectedFilters.plan.timeId === -1 ||
        [
          TimePeriodCodes.TwelveRollingMonths,
          TimePeriodCodes.FourRollingQuarters,
        ].includes(this.selectedTimeframe.code) ||
        (this.selectedCurrency.Id === CustomCurrencies.FxAdjusted.id &&
          (this.config.selectedFilters.plan.timeId === -1 ||
            this.config.selectedFilters.plan.monthLabel.includes('WIP'))) ||
        (this.config.selectedFilters.plan.timeId === 0 &&
          (this.hasValueAlreadySelected(groups, GroupsBy.Composition.id) ||
            this.hasValueAlreadySelected(groups, GroupsBy.ByRecord.id))));

    const projectionDate: MmbDate = this.config.filters.dates.find(
      (x: MmbDate) =>
        x.Id === this.config.selectedFilters.projection.StartTimeId
    );

    const isProjectionDisabled: boolean =
      value.value === ViewOptions.Projection &&
      ([CustomCurrencies.FxAdjusted.id, CustomCurrencies.Constant.id].includes(
        this.selectedCurrency.Id
      ) ||
        this.selectedTimeframe.fiscalYear < projectionDate.FiscalYearNbr);

    return (
      this.hasValueAlreadySelected(
        datasets
          .filter((x: string) => x !== dataset)
          .map((x: string) => x.replace('dataset', 'target')),
        value.value
      ) ||
      isPlanDisabled ||
      isProjectionDisabled
    );
  }

  validateFilterSelection(): void {
    this.validatePeriodSelection();
    this.validateGroupsSelection();
    this.validateDataSetsSelection();
  }

  validatePeriodSelection(): void {
    const selected: TextValuePair = this.favoriteFiltersConfig.periods.find(
      (x: TextValuePair) => x.value === this.favorite.period
    );

    if (has(selected.params, 'isDisabled') && selected.params.isDisabled()) {
      this.selectedPeriod = this.favoriteFiltersConfig.periods.find(
        (x: TextValuePair) => x.value === CustomOptions.None
      );
    }
  }

  validateGroupsSelection(): void {
    const selectedGroup1: TextValuePair =
      this.favoriteFiltersConfig.groups1.find(
        (x: TextValuePair) => x.value === this.favorite.group1
      );
    const selectedGroup2: TextValuePair =
      this.favoriteFiltersConfig.groups2.find(
        (x: TextValuePair) => x.value === this.favorite.group2
      );
    const selectedGroup3: TextValuePair =
      this.favoriteFiltersConfig.groups3.find(
        (x: TextValuePair) => x.value === this.favorite.group3
      );
    if (
      has(selectedGroup1.params, 'isDisabled') &&
      selectedGroup1.params.isDisabled()
    ) {
      this.selectedGroup1 = this.favoriteFiltersConfig.groups1.find(
        (x: TextValuePair) => x.value === GroupsBy.ClientGroup.id
      );
    }
    if (
      has(selectedGroup2.params, 'isDisabled') &&
      selectedGroup2.params.isDisabled()
    ) {
      this.selectedGroup2 = this.favoriteFiltersConfig.groups2.find(
        (x: TextValuePair) => x.value === CustomOptions.None
      );
    }
    if (
      has(selectedGroup3.params, 'isDisabled') &&
      selectedGroup3.params.isDisabled()
    ) {
      this.selectedGroup3 = this.favoriteFiltersConfig.groups3.find(
        (x: TextValuePair) => x.value === CustomOptions.None
      );
    }
  }

  validateDataSetsSelection(): void {
    const selectedDataset1: TextValuePair =
      this.favoriteFiltersConfig.datasets1.find(
        (x: TextValuePair) => x.value === this.favorite.target1
      );
    const selectedDataset2: TextValuePair =
      this.favoriteFiltersConfig.datasets2.find(
        (x: TextValuePair) => x.value === this.favorite.target2
      );

    if (
      has(selectedDataset1.params, 'isDisabled') &&
      selectedDataset1.params.isDisabled()
    ) {
      this.selectedTarget1 = this.favoriteFiltersConfig.datasets1.find(
        (x: TextValuePair) => x.value === ViewOptions.Current
      );
    }
    if (
      has(selectedDataset2.params, 'isDisabled') &&
      selectedDataset2.params.isDisabled()
    ) {
      this.selectedTarget2 = this.favoriteFiltersConfig.datasets2.find(
        (x: TextValuePair) => x.value === CustomOptions.None.toLowerCase()
      );
    }
  }

  hasValueAlreadySelected(properties: Array<string>, value: string): boolean {
    return properties
      .map((x: string) => {
        return this.favorite[x] === value;
      })
      .some((x: boolean) => x);
  }

  openModal() {
    if (!this.isOpen) {
      this.currentModal = this.modalService.open(this.favoriteModal, {
        windowClass: 'favorite-modal',
        centered: true,
        size: 'lg',
      });

      this.isOpen = true;

      this.subscription.add(
        this.currentModal.dismissed.subscribe(() => {
          this.isOpen = false;
        })
      );
    }
  }

  onChangeTimeframe(event: TimeframeChanged): void {
    this.selectedTimeframe = event.timeframe;
    this.validateFilterSelection();
  }

  onChangeCurrency(event: CurrencyChanged): void {
    this.selectedCurrency = event.currency;
    this.validateFilterSelection();
  }

  onMetricChanged(metrics: Array<OptionItem>): void {
    this.favorite.metric = metrics.map((x: OptionItem) => x.value).join(',');
    this.selectedBreadcrumbs.metric = metrics
      .map((x: OptionItem) => x.text)
      .join(', ');
  }

  onPeriodChanged(period: TextValuePair): void {
    this.favorite.period = period.value;
    this.selectedBreadcrumbs.period = period.value;
    this.validateFilterSelection();
  }

  onGroupChanged(group: TextValuePair, number: number): void {
    this.favorite[`group${number}`] = group.value;
    this.selectedBreadcrumbs[`group${number}`] = group.text;
    this.validateFilterSelection();
  }

  onDatasetChanged(datatset: TextValuePair, number: number): void {
    this.favorite[`target${number}`] = datatset.value;
    this.selectedBreadcrumbs[`target${number}`] = datatset.text;
    this.validateFilterSelection();
  }

  onSave(close?: boolean) {
    this.config.selectedFilters = this.filtersManager.applySelection(
      this.config.selectedFilters,
      this.config.filters,
      this.config.customerFilters
    );

    this.config.selectedFilters.currency = this.selectedCurrency;
    this.config.selectedFilters.timeframe = this.selectedTimeframe;

    this.favorite.name = this.favoriteName;
    if (this.favorite.filters) {
      Object.setPrototypeOf(this.favorite.filters, FavoriteFilters.prototype);
      this.favorite.filters.setFilters(this.config.selectedFilters);
    }

    const payload: FavoriteSaveModel = FavoriteMapper.getSaveModel(
      this.favorite
    );

    (this.favorite.isNew()
      ? this.favoriteService.createFavorite(payload)
      : this.favoriteService.updateFavorite(payload)
    )
      .then((x: boolean) => {
        this.filterService.updateSelectedFilters(this.config.selectedFilters);
        this.filterService.applyFilters();
        this.favoriteModalService.applyFavoriteEmitted.next(this.favorite);

        if (close) {
          this.currentModal.dismiss();
        }
      })
      .catch(() => {
        this.appMessagesService.show(MessageTemplates.UnexpectedError, {
          centered: true,
        });
      });
  }

  private mapBoardFilterSelectedFilter(
    favoriteFilters: FavoriteFilters
  ): SelectedFilters {
    const selectedFilters = new SelectedFilters();
    const timeframe = new TimeframeItem();
    const copy: FavoriteFilters = cloneDeep(favoriteFilters);

    timeframe.end = copy.Timeframe.EndTimeId;
    timeframe.start = copy.Timeframe.StartTimeId;
    timeframe.code = copy.Timeframe.TimePeriodCode;
    timeframe.title = copy.Timeframe.Name;

    selectedFilters.customer = copy.Customer;
    selectedFilters.currency = copy.Currency;
    selectedFilters.growthPriorities = copy.Sgi;
    selectedFilters.functions = copy.Functions;
    selectedFilters.alliances = copy.Alliances;
    selectedFilters.industries = copy.Industries;
    selectedFilters.serviceGroups = copy.ServiceGroups;
    selectedFilters.markets = copy.Locations;
    selectedFilters.clientGroups = copy.ClientServiceGroups;
    selectedFilters.wmus = copy.Wmus;
    selectedFilters.financialCustomers = copy.FinancialCustomer;
    selectedFilters.timeframe = timeframe;

    return selectedFilters;
  }

  onSaveAndClose() {
    this.onSave(true);
  }

  onCloseModal(): void {
    this.currentModal.dismiss();
  }
}
