import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import { cloneDeep, isEmpty } from 'lodash';

import { NgbDropdown, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { BoardModalComponent } from '../board-modal/board-modal.component';
import { SelectedFilters } from '../../services/entities/filters/selected-filters';
import {
  BoardFilters,
  BoardResponseItem,
  BoardTab,
} from '../../services/entities/board-response';
import { BoardModalConfig } from '../board-modal/entities/board-modal-config';
import {
  AppMessage,
  AppMessageButton,
} from '../../services/entities/app-message';
import { MessageTemplates } from '../../constants/messages.constants';
import { CustomCurrencies } from '../../constants/filters.constants';
import { Filters } from '../../services/entities/filters/filters';
import { CustomerFilters } from '../../services/entities/filters/customer-filters';
import { BoardMapper } from '../board-modal/utils/board-modal-utils';
import { ShareBoardModalConfig } from './components/share-board-modal/entities/share-board-modal-config';
import { BoardSaveModel } from '../board-modal/entities/board-save-model';

import { FiltersService } from '../../services/filters.service';
import { BoardModalService } from '../../services/modals/board-modal.service';
import { BoardService } from '../../services/board.service';
import { AppMessagesService } from '../../services/app-messages.service';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faEdit,
  faShareAlt,
  faCopy,
  faTrash,
  faStar as faStarPainted,
} from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { ShareBoardModalComponent } from './components/share-board-modal/share-board-modal.component';
import { NgIf, NgFor } from '@angular/common';
import { SpinnerComponent } from '../base/spinner/spinner.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from 'src/app/shared-module';

@Component({
  selector: 'app-board-picker',
  templateUrl: './board-picker.component.html',
  styleUrls: ['./board-picker.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    FontAwesomeModule,
    SpinnerComponent,
    NgIf,
    NgFor,
    ShareBoardModalComponent,
  ],
})
export class BoardPickerComponent implements OnInit {
  @ViewChild('boardModalComponent', { static: false })
  boardModal: BoardModalComponent;
  @ViewChild('boardsDropdown', { static: false }) boardsDropdown: NgbDropdown;

  subscription = new Subscription();
  loaded: boolean;
  boards: Array<BoardResponseItem>;
  filters: Filters;
  selectedFilters: SelectedFilters;
  customerFilters: CustomerFilters;
  shareBoardModalConfig: ShareBoardModalConfig;
  isfirstChange = true;

  readonly noneBoardName = 'None';

  faEdit = faEdit as IconProp;
  faShare = faShareAlt as IconProp;
  faCopy = faCopy as IconProp;
  faTrash = faTrash as IconProp;
  faStarPainted = faStarPainted as IconProp;
  faStar = faStar as IconProp;

  constructor(
    private boardModalService: BoardModalService,
    private boardService: BoardService,
    private filtersService: FiltersService,
    private appMessagesService: AppMessagesService,
    private router: Router
  ) {}

  ngOnInit() {
    this.subscription.add(
      combineLatest([
        this.filtersService.globalFiltersChanged,
        this.filtersService.selectedFiltersChanged,
      ]).subscribe(([x, y]: [Filters, SelectedFilters]) => {
        const customerChanged: boolean =
          this.selectedFilters?.customer.MasterCustomerNumber !==
          y.customer.MasterCustomerNumber;

        this.filters = x;
        this.selectedFilters = y;

        if (!customerChanged) {
          this.getBoards();
        }
      })
    );

    this.subscription.add(
      combineLatest([
        this.filtersService.customerFiltersChanged,
        this.boardService.urlSharedBoard,
      ])
        .pipe(skipWhile(() => !this.selectedFilters && !this.filters))
        .subscribe(([x, y]: [CustomerFilters, BoardResponseItem]) => {
          this.customerFilters = x;
          this.getBoards();

          if (
            y?.filters.Customer.MasterCustomerNumber ===
            this.selectedFilters.customer.MasterCustomerNumber
          ) {
            this.onSelect(BoardMapper.mapContract(y, this.filters));
          }
        })
    );
  }

  loadStarted(): void {
    this.loaded = false;
  }

  loadCompleted(): void {
    this.loaded = true;
  }

  getBoards(): void {
    this.loadStarted();

    this.boardService
      .getBoards(this.selectedFilters, this.filters)
      .then((y: Array<BoardResponseItem>) => {
        y.sort((a, b) => (a.name > b.name ? 1 : -1));
        y.unshift(this.getNoneBoard());
        this.boards = y;
        this.loadCompleted();
        this.checkForSharedBoards();

        if (this.isfirstChange) {
          this.applyDefault();
          this.isfirstChange = false;
        }
      });
  }

  getNoneBoard(): BoardResponseItem {
    const boardFilters = new BoardFilters();
    boardFilters.setDefaultFilters(this.filters, this.selectedFilters);

    return new BoardResponseItem({
      boardId: '',
      name: this.noneBoardName,
      filters: boardFilters,
      tab: null,
    });
  }

  checkForSharedBoards(): void {
    const sharedBoards: Array<BoardResponseItem> = this.boards?.filter(
      (x: BoardResponseItem) => !!x.sharedBy
    );

    if (sharedBoards?.length > 0) {
      sharedBoards
        .reduce((x: Promise<void>, y: BoardResponseItem) => {
          return x.then(() => {
            return this.openSharedBoardConfirmation(y);
          });
        }, Promise.resolve())
        .then(() => {
          this.getBoards();
        });
    }
  }

  openSharedBoardConfirmation(board: BoardResponseItem): Promise<void> {
    return new Promise((resolve: () => void, reject: () => void): void => {
      const confirmationMessage: AppMessage = cloneDeep(
        MessageTemplates.BoardSharedConfirmation
      );
      confirmationMessage.body = confirmationMessage.body.map((x: string) => {
        x = x.replace('[from]', board.sharedBy);
        x = x.replace('[boardName]', board.name.split('shared by')[0].trim());
        return x;
      });

      confirmationMessage.buttons.forEach((x: AppMessageButton) => {
        x.action = (): void => {
          const model: BoardSaveModel = BoardMapper.getSaveModel(board);
          model.sharedBy = null;
          (x.text === 'Confirm'
            ? this.boardService.updateBoard(model)
            : this.boardService.deleteBoard(
                model.boardId,
                this.selectedFilters.customer.MasterCustomerNumber
              )
          ).then((x: boolean) => {
            this.appMessagesService.close(confirmationMessage.id);
            resolve();
          });
        };
      });

      this.appMessagesService.show(confirmationMessage, { centered: true });
    });
  }

  applyDefault(): void {
    if (this.boards.some((x: BoardResponseItem) => x.default)) {
      const defaultBoard: BoardResponseItem = this.boards.find(
        (x: BoardResponseItem) => x.default
      );
      this.onSelect(defaultBoard);
    }
  }

  onOpenBoardModal(): void {
    this.boardsDropdown?.close();
    const boardFilters = new BoardFilters();
    boardFilters.setFilters(this.selectedFilters);

    this.boardModalService.openBoardModalEmitted.next(
      new BoardModalConfig({
        name: '',
        boardId: '',
        tab: BoardTab.getCurrentTab(),
        filters: this.filters,
        customerFilters: this.customerFilters,
        selectedFilters: this.selectedFilters,
        boardFilters: boardFilters,
      })
    );
  }

  onDefaultChanged(board: BoardResponseItem): void {
    this.boardsDropdown?.close();
    this.boardService
      .markDefault(board)
      .then((x: boolean) => {
        if (x) {
          this.onSelect(board);
        }
      })
      .catch(() => {
        this.appMessagesService.show(MessageTemplates.UnexpectedError, {
          centered: true,
        });
      });
  }

  onSelect(board: BoardResponseItem): void {
    this.boardsDropdown?.close();
    if (board.filters) {
      if (!board.filters.Currency) {
        board.filters.Currency = {
          Id: CustomCurrencies.Global.id,
          Description: CustomCurrencies.Global.text,
        };
      }
      Object.setPrototypeOf(board.filters, BoardFilters.prototype);
      Object.setPrototypeOf(board.tab ?? {}, BoardTab.prototype);
      this.boardService.selectedBoard = board;
      this.filtersService.updateSelectedFilters(
        board.filters.toSelectedFilters(
          this.filters,
          this.selectedFilters,
          this.customerFilters,
          board
        )
      );

      if (board.tab) {
        this.router.navigate([board.tab.getUrl()]).then(() => {
          this.filtersService.applyFilters();
        });
      } else {
        this.filtersService.applyFilters();
      }
    }
  }

  onEdit(board: BoardResponseItem, isDuplicate?: boolean): void {
    this.boardsDropdown?.close();
    this.boardModalService.openBoardModalEmitted.next(
      new BoardModalConfig({
        name: board.name,
        boardId: isDuplicate ? '' : board.boardId,
        tab: isEmpty(board.tab) ? BoardTab.getCurrentTab() : board.tab,
        filters: this.filters,
        customerFilters: this.customerFilters,
        selectedFilters: this.selectedFilters,
        boardFilters: board.filters,
      })
    );
  }

  onDelete(board: BoardResponseItem): void {
    this.boardsDropdown?.close();
    const deleteConfirmMessage: AppMessage =
      MessageTemplates.BoardDeleteConfirm;

    deleteConfirmMessage.buttons.forEach((x: AppMessageButton) => {
      x.action = (): void => {
        if (x.text === 'Yes') {
          this.boardService
            .deleteBoard(
              board.boardId,
              this.selectedFilters.customer.MasterCustomerNumber
            )
            .then((x: boolean) => {
              this.appMessagesService.close(deleteConfirmMessage.id);
              this.getBoards();
            });
        } else {
          this.appMessagesService.close(deleteConfirmMessage.id);
        }
      };
    });

    this.appMessagesService.show(deleteConfirmMessage, {
      centered: true,
    });
  }

  onShare(board: BoardResponseItem): void {
    this.boardsDropdown?.close();
    this.boardModalService.openShareBoardModalEmitted.next(
      new ShareBoardModalConfig({
        board,
      })
    );
  }
}
