<!-- <div class="ag-custom-cell" [ngClass]="cellClass" [style.justify-content]="cellStyleTextAlign"
    [style.font-weight]="cellStyleFontWeight">
    <span id="cell-value" *ngIf="params">{{params.value}}</span>

    <div *ngFor="let item of iconName; let i = index" placement="top" ngbtooltip="iconTooltip[i]"
        [title]="iconTooltip[i]">
        <span id="cell-icon" *ngIf="hasIcon" [style.cursor]="iconCursor[i]"
            [attr.data-action-type]="iconAction[i]!==''?iconAction[i]:null"
            [class]="iconClass[i] + ' ' + iconName[i]"></span>
    </div>
</div> -->

<div class="ag-custom-cell" [ngClass]="data.cellClass" [style.justify-content]="data.cellStyleTextAlign"
    [style.font-weight]="data.cellStyleFontWeight">
    <span id="grid-cell-string" *ngIf="params">{{data.value}}</span>

    <!-- <div *ngFor="let item of iconName; let i = index" placement="top" ngbtooltip="iconTooltip[i]"
        [title]="iconTooltip[i]">
        <span id="cell-icon" *ngIf="hasIcon" [style.cursor]="iconCursor[i]"
            [attr.data-action-type]="iconAction[i]!==''?iconAction[i]:null"
            [class]="iconClass[i] + ' ' + iconName[i]"></span>
    </div> -->
</div>