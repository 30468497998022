import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { NavBarTab } from '../../../../services/entities/navbar-tab';
import {
  AppModulePaths,
  AppNavBarTabs,
} from '../../../../constants/navbar.constants';
import { BoardTab } from 'src/app/shared/services/entities/board-response';
import { TabsObservables } from './entities/tabs-observables';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { BoardAnalyticsTabComponent } from '../board-analytics-tab/board-analytics-tab.component';
import { BoardSpeculativeTabComponent } from '../board-speculative-tab/board-speculative-tab.component';
import { BoardPhasingTabComponent } from '../board-phasing-tab/board-phasing-tab.component';
import { BoardProjectionTabComponent } from '../board-projection-tab/board-projection-tab.component';
import { BoardBacklogTabComponent } from '../board-backlog-tab/board-backlog-tab.component';
import { BoardOverviewTabComponent } from '../board-overview-tab/board-overview-tab.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgFor, NgIf, NgClass } from '@angular/common';
import { SharedModule } from 'src/app/shared-module';

@Component({
  selector: 'app-tabs-picker',
  templateUrl: './tabs-picker.component.html',
  styleUrls: ['./tabs-picker.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    SharedModule,
    NgIf,
    FontAwesomeModule,
    NgClass,
    BoardOverviewTabComponent,
    BoardBacklogTabComponent,
    BoardProjectionTabComponent,
    BoardPhasingTabComponent,
    BoardSpeculativeTabComponent,
    BoardAnalyticsTabComponent,
  ],
})
export class TabsPickerComponent implements OnInit {
  @Input() startSelectedTab: BoardTab;
  @Input() tabsObservables: TabsObservables;
  @Output() selectionChangeEvent = new EventEmitter<BoardTab>();

  tabs: Array<NavBarTab>;
  selectedNavBarTab: NavBarTab;
  appModulePaths = AppModulePaths;

  faCheckCircle = faCheckCircle as IconProp;

  constructor() {}

  ngOnInit(): void {
    this.tabs = AppNavBarTabs;
    this.setSelectedTab();
  }

  setSelectedTab(): void {
    if (this.startSelectedTab) {
      this.selectedNavBarTab = this.tabs.find(
        (x: NavBarTab) => x.id === this.startSelectedTab.Name
      );
      this.onTabChanged(this.selectedNavBarTab);
    }
  }

  onTabChanged(navBarTab: NavBarTab): void {
    this.selectedNavBarTab = navBarTab;
  }

  getSelectedValues(tab: BoardTab): void {
    tab.Name = this.selectedNavBarTab.id;
    this.selectionChangeEvent.emit(tab);
  }
}
