import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormsModule,
} from '@angular/forms';
import {
  NgbTypeaheadConfig,
  NgbTypeaheadSelectItemEvent,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { OperatorFunction } from 'rxjs';
import { SharedModule } from 'src/app/shared-module';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
  providers: [
    NgbTypeaheadConfig,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutoCompleteComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [FormsModule, SharedModule],
})
export class AutoCompleteComponent implements OnChanges, ControlValueAccessor {
  @Input() value: string;
  @Input() placeholder: string;
  @Input() clearAfterSelect: boolean;
  @Input() isReadOnly: boolean;
  @Input() search: OperatorFunction<string, readonly string[]>;
  @Output() selectedItemChangedEvent = new EventEmitter<string>();

  selectedValue: string;

  constructor(config: NgbTypeaheadConfig) {
    config.editable = false;
    config.placement = 'bottom';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && changes.value.currentValue) {
      this.writeValue(this.value);
    }
  }

  onSelectedItemChanged(event: NgbTypeaheadSelectItemEvent): void {
    if (this.clearAfterSelect) {
      this.value = '';
      event.preventDefault();
    } else {
      this.writeValue(event.item);
    }

    this.selectedItemChangedEvent.emit(event.item);
  }

  onBlur(event: any): void {
    const isEmpty: boolean = event.target.value?.length === 0;

    if (isEmpty) {
      this.writeValue('');
      this.selectedItemChangedEvent.emit(this.selectedValue);
    }

    this.value = this.selectedValue ?? '';
  }

  onInputClick(event: any): void {
    (event.target as HTMLInputElement).select();
  }

  onChange = (_: any) => {};

  onTouched = () => {};

  writeValue(value: any): void {
    if (this.selectedValue !== value) {
      this.selectedValue = value;
      this.onChange(value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
