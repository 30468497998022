import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { TabConfig } from '../tabs-picker/components/tab/entities/tab-config';
import { BoardTab } from 'src/app/shared/services/entities/board-response';
import { AppModulePaths } from 'src/app/shared/constants/navbar.constants';
import { TabsComponent } from '../tabs-picker/components/tab/tab.component';

@Component({
  selector: 'app-board-analytics-tab',
  templateUrl: './board-analytics-tab.component.html',
  styleUrls: ['./board-analytics-tab.component.scss'],
  standalone: true,
  imports: [TabsComponent],
})
export class BoardAnalyticsTabComponent implements OnInit {
  @Output() selectionChangeEvent = new EventEmitter<BoardTab>();
  tabConfig: TabConfig;

  constructor() {}

  ngOnInit(): void {
    this.initializeTabs();
    this.selectionChangeEvent.emit({
      Name: AppModulePaths.analytics.id,
      SubTabName: null,
      Controls: [],
    } as BoardTab);
  }

  initializeTabs(): void {
    this.tabConfig = new TabConfig({
      name: AppModulePaths.analytics.id,
    });
  }
}
