import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AppState } from '../../services/entities/app-state/app-state';

import { AppStateService } from '../../services/app-state.service';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-training-mode-indicator',
  templateUrl: './training-mode-indicator.component.html',
  styleUrls: ['./training-mode-indicator.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class TrainingModeIndicatorComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  isTrainingMode: boolean;

  constructor(private appStateService: AppStateService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.appStateService.appStateChanged.subscribe((x: AppState) => {
        this.isTrainingMode = x.trainingModeEnabled;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
