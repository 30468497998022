import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { OptionItem } from '../../entities/option-item';
import { OptionsListConfig } from '../../entities/options-list-config';
import { FilterArrayPipe } from '../../../../../pipes/filter-array.pipe';
import { FormsModule } from '@angular/forms';
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-radio-button-list',
  templateUrl: './radio-button-list.component.html',
  styleUrls: ['./radio-button-list.component.scss'],
  standalone: true,
  imports: [NgFor, FormsModule, FilterArrayPipe],
})
export class RadioButtonListComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  @Input() config: OptionsListConfig;
  @Input() searchText: string;
  @Input() isDisabled: boolean;
  @Input() onDeselectAllObs: Observable<boolean>;
  @Output() itemSelectionChangeEvent = new EventEmitter<Array<OptionItem>>();
  selectedItem: OptionItem;

  ngOnInit(): void {
    this.addSubscriptions();
    this.checkSelectedItem();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  addSubscriptions(): void {
    if (this.onDeselectAllObs) {
      this.subscription.add(
        this.onDeselectAllObs.subscribe((x: boolean) => {
          if (!x) {
            this.onDeselectAll();
          }
        })
      );
    }
  }

  checkSelectedItem(): void {
    const selected: OptionItem = this.config?.items.find(
      (x: OptionItem) => x.checked
    );
    if (selected) {
      this.selectedItem = selected.value;
    }
  }

  onItemSelection(item: OptionItem): void {
    this.onDeselectAll();
    item.checked = true;
    this.selectedItem = item.value;
    this.itemSelectionChangeEvent.emit([item]);
  }

  onDeselectAll(): void {
    this.selectedItem = null;
    this.config.items.map((x: OptionItem) => (x.checked = false));
    this.itemSelectionChangeEvent.emit([]);
  }
}
