import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { NgbDropdown, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { SelectedFilters } from '../../services/entities/filters/selected-filters';
import { TimeframeGroup, TimeframeItem } from '../timeframe/entities/timeframe';
import { ActiveDate } from '../../services/entities/filters/active-date';
import { TimeframeChanged } from '../timeframe/entities/timeframe-events';

import { FiltersService } from '../../services/filters.service';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { TimeframeComponent } from '../timeframe/timeframe.component';
import { NgIf } from '@angular/common';
import { SharedModule } from 'src/app/shared-module';

@Component({
  selector: 'app-timeframe-picker',
  templateUrl: './timeframe-picker.component.html',
  styleUrls: ['./timeframe-picker.component.scss'],
  standalone: true,
  imports: [SharedModule, NgIf, TimeframeComponent],
})
export class TimeframePickerComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('timeframeDropdown', { static: false })
  timeframeDropdown: NgbDropdown;

  updatedSelectedTabEvent = new Subject<number>();

  subscription = new Subscription();

  timeframes: Array<TimeframeGroup>;
  selectedTimeframe: TimeframeItem;
  selectedTab: number;
  appDate: ActiveDate;

  faCheckCircle = faCheckCircle;

  constructor(private filtersService: FiltersService) {}

  ngOnInit(): void {
    this.getSelectedTimeframes();
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      this.timeframeDropdown.openChange.subscribe((x: boolean) => {
        if (x) {
          this.updatedSelectedTabEvent.next(0);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getSelectedTimeframes(): void {
    const subscription = new Subscription();

    subscription.add(
      this.filtersService.selectedFiltersChanged.subscribe(
        (x: SelectedFilters) => {
          this.selectedTimeframe = x.timeframe;
          subscription.unsubscribe();
        }
      )
    );
  }

  onTimeframeChanged(event: TimeframeChanged): void {
    const hasChanged: boolean =
      this.selectedTimeframe?.code !== event.timeframe.code;
    this.timeframeDropdown.close();
    this.selectedTimeframe = event.timeframe;

    if (
      (event.forceReload && hasChanged) ||
      (event.forceReload && event.timeframe.code === 'Custom') ||
      event.invalidStorage
    ) {
      this.filtersService.updateSelectedTimeframe(event.timeframe);
      this.filtersService.applyFilters();
    }
  }
}
