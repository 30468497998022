import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Subscription, OperatorFunction, Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  catchError,
} from 'rxjs/operators';

import { MessageTemplates } from 'src/app/shared/constants/messages.constants';
import { AppMessagesService } from 'src/app/shared/services/app-messages.service';
import { MrdrPerson } from 'src/app/shared/services/entities/filters/mrdr-person';
import { ShareFavoriteModalConfig } from './entities/share-favorite-modal-config';
import { FavoriteResponseItem } from 'src/app/shared/services/entities/favorite-response';
import { FavoriteMapper } from '../../favorite-modal/utils/favorite-modal-utils';
import { FavoriteSaveModel } from '../../favorite-modal/entities/favorite-save-model';

import { MrdrService } from 'src/app/shared/services/mrdr.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { FavoriteService } from 'src/app/shared/services/favorite.service';
import { FavoriteModalService } from 'src/app/shared/services/modals/favorite-modal.service';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgIf, NgFor } from '@angular/common';
import { AutoCompleteComponent } from '../../../../../shared/components/base/auto-complete/auto-complete.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-share-favorite-modal',
  templateUrl: './share-favorite-modal.component.html',
  styleUrls: ['./share-favorite-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [FontAwesomeModule, AutoCompleteComponent, NgIf, NgFor],
})
export class ShareFavoriteModalComponent implements OnInit {
  subscription = new Subscription();
  @ViewChild('shareFavoriteModal', { static: false })
  shareFavoriteModal: NgbModalRef;

  currentModal: NgbModalRef;
  isOpen = false;
  config: ShareFavoriteModalConfig;

  personSearch: OperatorFunction<string, readonly string[]>;
  selectedPeople: Array<MrdrPerson>;

  faTimes = faTimes as IconProp;

  constructor(
    private modalService: NgbModal,
    private favoriteModalService: FavoriteModalService,
    private favoriteService: FavoriteService,
    private mrdrService: MrdrService,
    private sharedService: SharedService,
    private appMessagesService: AppMessagesService
  ) {}

  ngOnInit(): void {
    this.personSearch = (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((x: string) =>
          this.mrdrService.getEnterpriseIds(x).pipe(
            catchError(() => {
              return of([]);
            })
          )
        )
      );

    this.subscription.add(
      this.favoriteModalService.openShareFavoriteModalEmitted.subscribe(
        (x: ShareFavoriteModalConfig) => {
          this.config = x;
          this.openModal();
        }
      )
    );
  }

  openModal() {
    if (!this.isOpen) {
      this.currentModal = this.modalService.open(this.shareFavoriteModal, {
        windowClass: 'share-favorite-modal',
        centered: true,
        size: 'sm',
        backdrop: 'static',
      });

      this.isOpen = true;

      this.subscription.add(
        this.currentModal.dismissed.subscribe(() => {
          this.isOpen = false;
        })
      );
    }
  }

  onCloseModal(): void {
    this.currentModal.dismiss();
    this.selectedPeople = null;
  }

  onPersonSelected(enterpriseId: string): void {
    this.selectedPeople = this.selectedPeople ?? [];

    if (
      enterpriseId?.trim().length > 0 &&
      enterpriseId !== this.sharedService.getEnterpriseId() &&
      !this.selectedPeople.some(
        (x: MrdrPerson) => x.EnterpriseID === enterpriseId
      )
    ) {
      this.mrdrService
        .getPerson(enterpriseId)
        .then((x: MrdrPerson) => {
          this.selectedPeople.push(x);
        })
        .catch(() => {
          this.appMessagesService.show(MessageTemplates.UnexpectedError, {
            centered: true,
          });
        });
    }
  }

  onDeletePerson(person: MrdrPerson): void {
    const index: number = this.selectedPeople.findIndex(
      (x: MrdrPerson) => x.EnterpriseID === person.EnterpriseID
    );

    if (index > -1) {
      this.selectedPeople.splice(index, 1);
    }
  }

  onShare(): void {
    if (this.selectedPeople?.length > 0) {
      Promise.all(
        this.selectedPeople
          .map((x: MrdrPerson) => {
            const favorite: FavoriteResponseItem = cloneDeep(
              this.config.favorite
            );
            favorite.favoriteId = `${uuidv4()}#${x.PeopleKey}`;
            favorite.name = `${
              this.config.favorite.name
            } shared by ${this.sharedService.getEnterpriseId()}`;
            favorite.sharedBy = `${this.sharedService.getEnterpriseId()}#${
              x.EnterpriseID
            }`;
            return FavoriteMapper.getSaveModel(favorite);
          })
          .map((x: FavoriteSaveModel) => this.favoriteService.createFavorite(x))
      )
        .then((x: Array<boolean>) => {
          if (x.every((x: boolean) => x)) {
            this.appMessagesService.show(
              MessageTemplates.FavoriteSuccessfulShared,
              {
                centered: true,
              }
            );
          } else {
            this.appMessagesService.show(MessageTemplates.UnexpectedError, {
              centered: true,
            });
          }
        })
        .catch(() => {
          this.appMessagesService.show(MessageTemplates.UnexpectedError, {
            centered: true,
          });
        })
        .finally(() => {
          this.onCloseModal();
        });
    }
  }
}
