import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { cloneDeep, get, set, sumBy } from 'lodash';
import { Subscription } from 'rxjs';
import {
  ColDef,
  GridApi,
  ColumnApi,
  Column,
  RowNode,
  RowClickedEvent,
  SelectionChangedEvent,
  SortChangedEvent,
  CellEditingStoppedEvent,
  CellEditingStartedEvent,
  CellValueChangedEvent,
  IRowNode,
} from 'ag-grid-community';
import { GridConfig } from './entities/grid-config';
import { GridData } from './entities/grid-data';
import { NgIf, NgClass } from '@angular/common';
import { SharedModule } from 'src/app/shared-module';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf, NgClass, SharedModule],
})
export class GridComponent implements OnDestroy {
  @Input() config: any;

  subscription = new Subscription();

  gridApi: GridApi;
  columnApi: ColumnApi;

  originalRowData: Array<Record<string, any>>;
  rowData: Array<Record<string, any>>;
  otherPinnedRows: Array<Record<string, any>>;
  isExpanded: boolean;
  filter: string;

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  subscribeToObservables(): void {
    if (this.config && this.config.observables)
      if (this.config.observables.loadChanged) {
        this.subscription.add(
          this.config.observables.loadChanged.subscribe((x: boolean) => {
            if (x) {
              //this.gridApi.setPinnedTopRowData([]);
              this.gridApi.setGridOption('pinnedTopRowData', null);
              //this.gridApi.setRowData(null);
              this.gridApi.setGridOption('rowData', null);
              this.gridApi.showLoadingOverlay();
            } else {
              this.gridApi.hideOverlay();
            }
          })
        );
      }

    if (this.config.observables.updateColDefs) {
      this.subscription.add(
        this.config.observables.updateColDefs.subscribe((x: Array<any>) => {
          this.config.columns = x;
          this.gridApi.setColumnDefs(x);
          this.gridApi.refreshHeader();
          this.handlePinnedRows();
        })
      );
    }

    if (this.config.observables.updateRowData) {
      this.subscription.add(
        this.config.observables.updateRowData.subscribe((x: GridData) => {
          this.rowData = x.rows;
          this.originalRowData = cloneDeep(this.rowData);
          this.otherPinnedRows = x.pinnedTop;
          //this.gridApi.setRowData(x.rows);
          this.gridApi.setGridOption('rowData', x.rows);
          this.handlePinnedRows(this.filter?.length > 0);
        })
      );
    }

    if (this.config.observables.filterChanged) {
      this.subscription.add(
        this.config.observables.filterChanged.subscribe((x: string) => {
          this.filter = x;
          this.gridApi.setQuickFilter(x);
        })
      );
    }

    if (this.config.observables.expandChanged) {
      this.subscription.add(
        this.config.observables.expandChanged.subscribe((x: boolean) => {
          this.isExpanded = x;
        })
      );
    }

    if (this.config.observables.stopEditing) {
      this.subscription.add(
        this.config.observables.stopEditing.subscribe((x: boolean) => {
          if (this.gridApi) {
            this.gridApi.stopEditing(x);
          }
        })
      );
    }
  }

  handlePinnedRows(filtered?: boolean): void {
    let pinnedTopRowData: Array<Record<string, any>> = [];

    if (this.config.features?.hasTotalsRow && this.rowData) {
      const totalsRow: Record<string, any> = this.calculateTotals(filtered);
      pinnedTopRowData.push(totalsRow);
    }

    if (this.otherPinnedRows && this.rowData) {
      pinnedTopRowData = pinnedTopRowData.concat(this.otherPinnedRows);
    }

    //this.gridApi.setPinnedTopRowData(pinnedTopRowData);
    this.gridApi.setGridOption('pinnedTopRowData', pinnedTopRowData);
  }

  fitGrid() {
    if (this.gridApi && !this.config.features?.autoSizeOnChange) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.columnApi?.autoSizeColumns(
        this.config.columns.map((x: any) => x.colId)
      );
    }
  }

  calculateTotals(filtered?: boolean) {
    let field: string;
    const totalRow: Record<string, any> = {};
    let currentRows: Array<Record<string, any>> = [];

    if (filtered) {
      this.gridApi.forEachNodeAfterFilter((x: IRowNode) =>
        currentRows.push(x.data)
      );
    } else {
      currentRows = this.rowData;
    }

    if (this.otherPinnedRows && this.rowData) {
      // This is to make sure pinned rows data is included in totals
      currentRows = currentRows.concat(this.otherPinnedRows);
    }

    this.columnApi.getValueColumns().forEach((x: Column) => {
      field = x.getColDef().field;
      set(
        totalRow,
        field,
        this.calculateAggregated(
          currentRows,
          field,
          x.getAggFunc(),
          x.getColDef()
        )
      );
    });

    return totalRow;
  }

  calculateAggregated(
    currentRows: Array<Record<string, any>>,
    column: string,
    func: string | Function,
    colDef: any
  ) {
    if (func === 'sum') {
      return sumBy(currentRows, (x: Record<string, any>) => {
        return get(x, column);
      });
    } else if (typeof func === 'function') {
      return func(currentRows, colDef);
    }
  }

  onFilterChanged(event: any) {
    this.handlePinnedRows(true);
  }

  onSelectionChanged(event: any) {
    if (this.config.observables.selectionChanged) {
      this.config.observables.selectionChanged.next(event);
    }
  }

  onRowClicked(event: any) {
    if (this.config.observables.rowClicked) {
      this.config.observables.rowClicked.next(event);
    }
  }

  onSortChanged(event: any) {
    if (this.config.observables.sortChanged) {
      this.config.observables.sortChanged.next(event);
    }
  }

  onCellValueChanged(event: any) {
    const editedRows: Array<IRowNode> = [];

    if (event && event.oldValue !== event.newValue) {
      (event.node as any).edited = true;
      (event.node as any).originalRowData =
        this.originalRowData[Number(event.node.id)];

      editedRows.push(this.gridApi.getDisplayedRowAtIndex(event.rowIndex));

      this.gridApi.redrawRows({ rowNodes: editedRows });
    }

    if (this.config.observables.cellValueChanged) {
      this.config.observables.cellValueChanged.next(event);
    }
  }

  onCellEditingStarted(event: any) {
    if (this.config.observables.cellEditingStarted) {
      this.config.observables.cellEditingStarted.next(event);
    }
  }

  onCellEditingStopped(event: any) {
    // const allColumnIds: Array<string> = [];

    // this.columnApi.getAllColumns().forEach((x: Column) => {
    //   allColumnIds.push(x.getColId());
    // });

    // this.columnApi?.autoSizeColumns(allColumnIds);

    this.fitGrid();

    if (this.config.observables.cellEditingStopped) {
      this.config.observables.cellEditingStopped.next(event);
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.subscribeToObservables();
    this.config.observables.gridReady.next(params);
  }
}
