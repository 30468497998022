import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import {
  StorageKeys,
  StorageType,
} from 'src/app/core/constants/storage.constants';
import { StorageUtils } from 'src/app/core/utils/storage.utils';
import { SharedService } from '../../services/shared.service';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-impersonation-indicator',
  templateUrl: './impersonation-indicator.component.html',
  styleUrls: ['./impersonation-indicator.component.scss'],
  standalone: true,
  imports: [NgIf, FontAwesomeModule],
})
export class ImpersonationIndicatorComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  impersonatedUser: string;
  isImpersonated: boolean;

  faTimes = faTimes as IconProp;

  constructor(private sharedService: SharedService) {}

  ngOnInit(): void {
    this.checkImpersonationStatus();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  checkImpersonationStatus(): void {
    this.subscription.add(
      this.sharedService.hasImpersonationVerified.subscribe((x: boolean) => {
        const enterpriseId: string = StorageUtils.get(
          StorageType.LocalStorage,
          StorageKeys.IMPERSONATED_USER_KEY
        );

        if (enterpriseId) {
          this.impersonatedUser = enterpriseId;
          this.isImpersonated = true;
        }
      })
    );
  }
}
