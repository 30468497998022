import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from '@angular/core';
import { isUndefined } from 'lodash';

import { TreeViewItem } from '../../entities/tree-view-item';
import { TreeViewConfig } from '../../entities/tree-view-config';
import { TreeViewUtils } from '../../utils/tree-view.utils';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgClass, NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'app-tree-view-check-item',
  templateUrl: './tree-view-check-item.component.html',
  styleUrls: ['./tree-view-check-item.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    FontAwesomeModule,
    FormsModule,
    NgFor,
    forwardRef(() => TreeViewCheckItemComponent),
  ],
})
export class TreeViewCheckItemComponent {
  @Input() config: TreeViewConfig;
  @Input() item: TreeViewItem;
  @Input() isSearch = false;
  @Output() itemSelectionChangeEvent = new EventEmitter<TreeViewItem>();

  faAngleRight = faAngleRight as IconProp;
  faAngleDown = faAngleDown as IconProp;

  onToggle(): void {
    this.item.isOpen = !this.item.isOpen;
  }

  onItemSelection(checked?: boolean): void {
    if (this.item.hasCheckBox) {
      this.item.checked = isUndefined(checked) ? !this.item.checked : checked;
      this.changeChildrenCheckState(this.item, true);
      this.itemSelectionChangeEvent.emit(this.item);

      if (this.isSearch) {
        const originalItem: TreeViewItem = TreeViewUtils.searchItem<any>(
          this.config.items,
          this.item.value
        );
        if (originalItem) {
          originalItem.checked = this.item.checked;
          this.changeChildrenCheckState(originalItem);
        }
      }
    }
  }

  onChildItemSelection(child: TreeViewItem): void {
    this.itemSelectionChangeEvent.emit(child);

    if (this.config) {
      if (!this.config.decoupleChildrenFromParent || !child.checked) {
        const areAllChildrenChecked: boolean =
          this.item.areAllChildrenChecked();

        if (areAllChildrenChecked !== this.item.checked) {
          this.item.checked = areAllChildrenChecked;
          this.itemSelectionChangeEvent.emit(this.item);
        }
      }
    }
  }

  changeChildrenCheckState(
    item: TreeViewItem,
    emitChangeEvent?: boolean
  ): void {
    if (
      this.config &&
      !this.config.decoupleParentFromChildren &&
      item.hasChildren()
    ) {
      TreeViewUtils.iterateItems([item], (x: TreeViewItem) => {
        x.checked = item.checked;
        if (emitChangeEvent && x.value !== item.value) {
          this.itemSelectionChangeEvent.emit(x);
        }
      });
    }
  }
}
