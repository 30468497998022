import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { CheckBoxGroupHeader } from '../../entities/checkbox-groups-header';
import { CheckBoxGroupValue } from '../../entities/checkbox-groups-value';
import { CheckBoxGroupsUtils } from '../../utils/checkbox-groups.utils';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'app-checkbox-groups-header',
  templateUrl: './checkbox-groups-header.component.html',
  styleUrls: ['./checkbox-groups-header.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, FormsModule],
})
export class CheckboxGroupsHeaderComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  @Input() headers: Array<CheckBoxGroupHeader> = [];
  @Input() onSelectAllChangedObs: Observable<boolean>;
  @Input() onValueSelectionChangedObs: Observable<Array<CheckBoxGroupValue>>;
  @Output() headerSelectionChangeEvent = new EventEmitter<Array<any>>();

  ngOnInit(): void {
    this.addSubscriptions();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  addSubscriptions(): void {
    if (this.onSelectAllChangedObs) {
      this.subscription.add(
        this.onSelectAllChangedObs.subscribe((x: boolean) => {
          this.headers.forEach((h: CheckBoxGroupHeader) => {
            h.checked = x;
          });
          this.onHeaderSelection();
        })
      );
    }

    if (this.onValueSelectionChangedObs) {
      this.subscription.add(
        this.onValueSelectionChangedObs.subscribe(
          (x: Array<CheckBoxGroupValue>) => {
            this.headers.forEach((h: CheckBoxGroupHeader) => {
              const total: number = h.values.length;
              const checkedCount: number = x.filter((v) =>
                h.values.includes(v.value)
              ).length;
              h.checked = total === checkedCount;
            });
          }
        )
      );
    }
  }

  onHeaderSelection(): void {
    this.headerSelectionChangeEvent.emit(
      CheckBoxGroupsUtils.getSelectedGroupValues<any>(this.headers)
    );
  }
}
