import { Component } from '@angular/core';
import { GridCellItemAlign } from 'src/app/shared/constants/grid.constants';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgClass, NgIf } from '@angular/common';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMinus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-icon-cell',
  templateUrl: './icon-cell.component.html',
  styleUrls: ['./icon-cell.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, FontAwesomeModule],
})
export class IconCellComponent {
  params: any;
  data: Record<string, any> = {
    cellStyleTextAlign: GridCellItemAlign.Center,
    cellClass: '',
  };

  agInit(params: any): void {
    this.params = params;

    if (this.params.align) {
      this.data.cellStyleTextAlign = this.params.align;
    }

    if (this.params.cellClass) {
      this.data.cellClass = this.params.cellClass;
    }

    if (params.cellClass === 'opp-phasing-status undefined') {
      this.params.value = faMinus as IconProp;
    }
  }

  refresh(params: any): void {
    this.agInit(params);
  }
}
