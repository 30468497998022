import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { SecurityService } from '../../services/security.service';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-readonly-mode-indicator',
  templateUrl: './readonly-mode-indicator.component.html',
  styleUrls: ['./readonly-mode-indicator.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class ReadonlyModeIndicatorComponent implements OnInit {
  subscription = new Subscription();
  isReadOnlyMode: boolean;

  constructor(private securityService: SecurityService) {}

  ngOnInit(): void {
    this.checkReadOnlyModeStatus();
  }

  checkReadOnlyModeStatus(): void {
    this.securityService.isReadOnlyMode().then((x: boolean) => {
      this.isReadOnlyMode = x;
    });
  }
}
