import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { of, Subject, Subscription } from 'rxjs';

import {
  StorageKeys,
  StorageType,
} from 'src/app/core/constants/storage.constants';
import { IStrictRequestOptions } from 'src/app/core/utils/http.utils';
import { StorageUtils } from 'src/app/core/utils/storage.utils';
import { Endpoints } from '../constants/endpoints.constants';
import { Customer } from './entities/filters/customer';
import { AccountInfo } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private impersonationVerifiedEvent = new Subject<boolean>();
  private loadingCompletedEvent = new Subject<boolean>();

  public get hasImpersonationVerified(): Subject<boolean> {
    return this.impersonationVerifiedEvent;
  }

  public get hasLoadingCompleted(): Subject<boolean> {
    return this.loadingCompletedEvent;
  }

  constructor(
    private msalService: MsalService,
    private http: HttpClient,
    private router: Router
  ) {}

  impersonationVerified(): void {
    this.impersonationVerifiedEvent.next(true);
  }

  checkImpersonation(): Promise<void> {
    return new Promise(
      (resolve: () => void, reject: (error: any) => void): void => {
        const subscription = new Subscription();

        subscription.add(
          this.router.events.subscribe(
            (event) => {
              if (event instanceof NavigationEnd) {
                const isRemove: boolean = event.url.startsWith('/remove-user');
                const isImpersonate: boolean = event.url.startsWith('/user');
                let enterpriseId: string = null;

                if (isImpersonate) {
                  enterpriseId = isImpersonate
                    ? event.url.split('/').pop()
                    : null;
                }

                if (enterpriseId) {
                  StorageUtils.set(
                    StorageType.LocalStorage,
                    StorageKeys.IMPERSONATED_USER_KEY,
                    enterpriseId
                  );
                }
                if (isRemove) {
                  StorageUtils.remove(
                    StorageType.LocalStorage,
                    StorageKeys.IMPERSONATED_USER_KEY
                  );
                }

                this.impersonationVerified();
                subscription.unsubscribe();
                resolve();
              }
            },
            (error: any) => {
              subscription.unsubscribe();
              resolve();
            }
          )
        );
      }
    );
  }

  loadStarted(): void {
    this.loadingCompletedEvent.next(false);
  }

  loadCompleted(): void {
    this.loadingCompletedEvent.next(true);
  }

  getUser(): AccountInfo {
    const account: AccountInfo = this.msalService.instance.getActiveAccount();
    return account;
  }

  getPeopleKey(): string {
    const account: AccountInfo = this.getUser();
    return account ? (account.idTokenClaims as any)['peoplekey'] : '';
  }

  getEnterpriseId(): string {
    const account: AccountInfo = this.getUser();
    if (account) {
      let userName: string = account.username;

      if (userName.indexOf('@') >= 0) {
        userName = userName.split('@')[0];
      }

      return userName;
    }

    return '';
  }

  saveLoginForMetrics(): Promise<any> {
    const options: IStrictRequestOptions = {};
    options.headers = new HttpHeaders();
    options.headers = options.headers.append('Content-Type', 'text/plain');
    options.responseType = 'text';

    return this.http
      .post(Endpoints.profilePic, `"${this.getEnterpriseId()}"`, options)
      .toPromise();
  }

  saveTabForMetrics(tabName: string, customer: Customer): Promise<boolean> {
    if (tabName) {
      const body: Record<string, any> = {
        Data: { name: tabName },
        MasterClientId: customer.MasterCustomerNumber,
        MasterClientName: customer.MasterCustomerName,
      };

      return this.http.post<boolean>(Endpoints.tabMetrics, body).toPromise();
    }
    return of(false).toPromise();
  }
}
