import { cloneDeep, sumBy } from 'lodash';

import { Periods } from 'src/app/shared/constants/filters.constants';
import {
  MetricsOptions,
  CustomOptions,
  ViewOptions,
} from 'src/app/shared/constants/grid.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { ColDef, CsvExportParams, GridOptions } from 'ag-grid-community';
import {
  GridToolbarConfig,
  GridToolbarDropdown,
} from 'src/app/shared/components/base/grid/components/grid-toolbar/entities/grid-toolbar-config';
import { TimeframeItem } from 'src/app/shared/components/timeframe/entities/timeframe';
import { GridUtils } from 'src/app/shared/components/base/grid/utils/grid.utils';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { SortColum } from 'src/app/shared/components/base/grid/entities/grid-config';

export const ComponentNames = {
  backlog: 'backlog',
  metrics: 'metricName',
  periods: 'period',
  targets: 'target',
  export: 'export',
  expand: 'expand',
  compress: 'compress',
  filterDefaults: 'filterDefaults',
  noActivity: 'noActivity',
};

export const GridFilters = {
  Metrics: [
    new TextValuePair({
      text: MetricsOptions.Summary,
      value: MetricsOptions.Summary,
    }),
    new TextValuePair({
      text: MetricsOptions.Revenue,
      value: MetricsOptions.Revenue,
    }),
    new TextValuePair({
      text: MetricsOptions.Cci,
      value: MetricsOptions.Cci,
    }),
    new TextValuePair({
      text: MetricsOptions.CciPercentage,
      value: MetricsOptions.CciPercentage,
    }),
  ],
  Periods: [
    new TextValuePair({
      text: Periods.Quarter.text,
      value: Periods.Quarter.id,
    }),
    new TextValuePair({
      text: Periods.Month.text,
      value: Periods.Month.id,
    }),
  ],
  TargetOptions: (selectedFilters: SelectedFilters) => {
    return [
      new TextValuePair({
        text: 'Projection',
        value: ViewOptions.Current,
      }),
      new TextValuePair({
        text: selectedFilters.projection.getText(),
        value: ViewOptions.Projection,
      }),
      new TextValuePair({
        text: `Projection vs ${selectedFilters.projection.getText()} Variance`,
        value: ViewOptions.CompareProjection,
      }),
    ];
  },
};

export const GridColDefs = {
  getColDefs: (
    toolbarConfig: GridToolbarConfig,
    filters: Filters,
    selectedFilters: SelectedFilters
  ): Array<ColDef> => {
    let columnDefs: Array<ColDef> = [];

    const metricsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
      ComponentNames.metrics
    );
    const periodsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
      ComponentNames.periods
    );
    const targetsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
      ComponentNames.targets
    );

    const isCustomTimeframe: boolean =
      selectedFilters.timeframe.title === 'Custom';

    const timeframe: TimeframeItem = cloneDeep(selectedFilters.timeframe);
    // timeframe.end = customTimeframe ? timeframe.end : timeframe.start + 11;

    if (metricsDropdown.selected.value !== MetricsOptions.Summary) {
      columnDefs = GridColDefs.Shared.getColDefsForMetrics(
        toolbarConfig,
        filters,
        selectedFilters
      );

      if (periodsDropdown.selected.value === Periods.Quarter.id) {
        columnDefs = columnDefs.concat(
          GridColDefs.Shared.addCustomRendererConfigs(
            GridUtils.getQuarterlyHeaders(
              timeframe,
              filters.dates,
              isCustomTimeframe
            ),
            metricsDropdown.selected.value,
            targetsDropdown.selected.value
          )
        );
      } else {
        columnDefs = columnDefs.concat(
          GridColDefs.Shared.addCustomRendererConfigs(
            GridUtils.getMonthlyHeaders(timeframe, filters.dates),
            metricsDropdown.selected.value,
            targetsDropdown.selected.value
          )
        );
      }
    } else {
      columnDefs = GridColDefs.Shared.getColDefsForSummary(
        toolbarConfig,
        filters,
        selectedFilters
      );
    }

    return GridColDefs.Shared.getBaseGridColDefs().concat(columnDefs);
  },
  Shared: {
    getBaseGridColDefs: (): Array<ColDef> => {
      return [
        {
          headerName: 'CONTRACT ID',
          colId: 'contractId',
          field: 'Link',
          type: 'linkColumn',
          valueGetter: (params: any) => {
            return params.data.Id === CustomOptions.NotAssigned.id
              ? CustomOptions.NotAssigned.text
              : params.data.Id;
          },
          pinned: true,
          minWidth: 100,
          sortable: true,
          resizable: true,
          sort: GridColDefs.Shared.paramSort('contractId'),
          pinnedRowCellRendererParams: { forceTotals: true },
          comparator: (valueA: string, valueB: string): number => {
            return valueA.localeCompare(valueB, 'en-US', { numeric: true });
          },
        },
        {
          headerName: 'CONTRACT NAME',
          colId: 'contractName',
          field: 'Link',
          type: 'linkColumn',
          valueGetter: (params: any) => {
            if (params.data.Name) {
              return params.data.Name.includes(`${params.data.Id}-`)
                ? params.data.Name.replace(`${params.data.Id}-`, '')
                : params.data.Name.includes(params.data.Id)
                ? params.data.Name.replace(params.data.Id, '')
                : params.data.Name === CustomOptions.NotAssigned.text
                ? '-'
                : params.data.Name;
            }
          },
          pinned: true,
          minWidth: 200,
          sortable: true,
          resizable: true,
          sort: GridColDefs.Shared.paramSort('contractName'),
          comparator: (valueA: string, valueB: string): number => {
            return valueA.localeCompare(valueB, 'en-US', { numeric: false });
          },
        },
        {
          headerName: 'SOURCE',
          colId: 'source',
          field: 'Source',
          type: 'textColumn',
          pinned: false,
          minWidth: 75,
          sortable: true,
          resizable: true,
          sort: GridColDefs.Shared.paramSort('source'),
        },
        {
          headerName: 'SAP UPDATED',
          colId: 'sapupdated',
          field: 'LastSubmitted',
          type: 'dateColumn',
          pinned: false,
          minWidth: 100,
          sortable: true,
          resizable: true,
          sort: GridColDefs.Shared.paramSort('sapupdated'),
        },
        {
          headerName: 'MME PUBLISHED',
          colId: 'mmepublished',
          field: 'LastPublished',
          type: 'dateColumn',
          pinned: false,
          minWidth: 100,
          sortable: true,
          resizable: true,
          sort: GridColDefs.Shared.paramSort('mmepublished'),
        },
      ] as Array<ColDef>;
    },
    getColDefsForMetrics: (
      toolbarConfig: GridToolbarConfig,
      filters: Filters,
      selectedFilters: SelectedFilters
    ): Array<ColDef> => {
      const metricsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
        ComponentNames.metrics
      );
      const targetsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
        ComponentNames.targets
      );

      const attrs: Record<string, string> =
        GridColDefs.Shared.getMetricAttributes(metricsDropdown.selected.value);

      return [
        {
          headerName: metricsDropdown.selected.value.toUpperCase(),
          pinned: false,
          colId: 'Total',
          field: `Total.${attrs.attrName}`,
          minWidth: 125,
          menuTabs: ['filterMenuTab'],
          sortable: true,
          resizable: true,
          sort: GridColDefs.Shared.paramSort('Total'),
          aggFunc:
            metricsDropdown.selected.value === MetricsOptions.CciPercentage
              ? targetsDropdown.selected.value === ViewOptions.CompareProjection
                ? GridColDefs.Shared.sumCciPercentageCompare
                : GridColDefs.Shared.sumCciPercentage
              : 'sum',
          type: attrs.columnType,
        },
        {
          headerName: `ACTUALS ${metricsDropdown.selected.value.toUpperCase()}`,
          pinned: false,
          colId: 'Actual',
          field: `Actual.${attrs.attrName}`,
          minWidth: 125,
          menuTabs: ['filterMenuTab'],
          sortable: true,
          resizable: true,
          sort: GridColDefs.Shared.paramSort('Actual'),
          aggFunc:
            metricsDropdown.selected.value === MetricsOptions.CciPercentage
              ? targetsDropdown.selected.value === ViewOptions.CompareProjection
                ? GridColDefs.Shared.sumCciPercentageCompare
                : GridColDefs.Shared.sumCciPercentage
              : 'sum',
          type: attrs.columnType,
        },
        {
          headerName: `BACKLOG ${metricsDropdown.selected.value.toUpperCase()}`,
          pinned: false,
          colId: 'Backlog',
          field: `Backlog.${attrs.attrName}`,
          minWidth: 125,
          menuTabs: ['filterMenuTab'],
          sortable: true,
          resizable: true,
          sort: GridColDefs.Shared.paramSort('Backlog'),
          aggFunc:
            metricsDropdown.selected.value === MetricsOptions.CciPercentage
              ? targetsDropdown.selected.value === ViewOptions.CompareProjection
                ? GridColDefs.Shared.sumCciPercentageCompare
                : GridColDefs.Shared.sumCciPercentage
              : 'sum',
          type: attrs.columnType,
        },
      ] as Array<ColDef>;
    },
    getColDefsForSummary: (
      toolbarConfig: GridToolbarConfig,
      filters: Filters,
      selectedFilters: SelectedFilters
    ): Array<ColDef> => {
      const metricsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
        ComponentNames.metrics
      );
      const targetsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
        ComponentNames.targets
      );

      return [
        {
          headerName: 'REVENUE',
          pinned: false,
          colId: 'Total',
          field: 'Total.revenue',
          minWidth: 125,
          menuTabs: ['filterMenuTab'],
          sortable: true,
          resizable: true,
          sort: GridColDefs.Shared.paramSort('Total'),
          aggFunc:
            metricsDropdown.selected.value === MetricsOptions.CciPercentage
              ? targetsDropdown.selected.value === ViewOptions.CompareProjection
                ? GridColDefs.Shared.sumCciPercentageCompare
                : GridColDefs.Shared.sumCciPercentage
              : 'sum',
          type: 'numberColumn',
        },
        {
          headerName: 'ACTUALS REVENUE',
          pinned: false,
          colId: 'Actual',
          field: 'Actual.revenue',
          minWidth: 125,
          menuTabs: ['filterMenuTab'],
          sortable: true,
          resizable: true,
          sort: GridColDefs.Shared.paramSort('Actual'),
          aggFunc:
            metricsDropdown.selected.value === MetricsOptions.CciPercentage
              ? targetsDropdown.selected.value === ViewOptions.CompareProjection
                ? GridColDefs.Shared.sumCciPercentageCompare
                : GridColDefs.Shared.sumCciPercentage
              : 'sum',
          type: 'numberColumn',
        },
        {
          headerName: 'BACKLOG REVENUE',
          pinned: false,
          colId: 'Backlog',
          field: 'Backlog.revenue',
          minWidth: 125,
          menuTabs: ['filterMenuTab'],
          sortable: true,
          resizable: true,
          sort: GridColDefs.Shared.paramSort('Backlog'),
          aggFunc:
            metricsDropdown.selected.value === MetricsOptions.CciPercentage
              ? targetsDropdown.selected.value === ViewOptions.CompareProjection
                ? GridColDefs.Shared.sumCciPercentageCompare
                : GridColDefs.Shared.sumCciPercentage
              : 'sum',
          type: 'numberColumn',
        },
        {
          headerName: 'CCI$',
          pinned: false,
          colId: 'TotalCCI',
          field: 'Total.cci',
          minWidth: 125,
          menuTabs: ['filterMenuTab'],
          sortable: true,
          resizable: true,
          sort: GridColDefs.Shared.paramSort('TotalCCI'),
          aggFunc:
            metricsDropdown.selected.value === MetricsOptions.CciPercentage
              ? targetsDropdown.selected.value === ViewOptions.CompareProjection
                ? GridColDefs.Shared.sumCciPercentageCompare
                : GridColDefs.Shared.sumCciPercentage
              : 'sum',
          type: 'numberColumn',
        },
        {
          headerName: 'ACTUALS CCI$',
          pinned: false,
          colId: 'Actual',
          field: 'Actual.cci',
          minWidth: 125,
          menuTabs: ['filterMenuTab'],
          sortable: true,
          resizable: true,
          sort: GridColDefs.Shared.paramSort('Actual'),
          aggFunc:
            metricsDropdown.selected.value === MetricsOptions.CciPercentage
              ? targetsDropdown.selected.value === ViewOptions.CompareProjection
                ? GridColDefs.Shared.sumCciPercentageCompare
                : GridColDefs.Shared.sumCciPercentage
              : 'sum',
          type: 'numberColumn',
        },
        {
          headerName: 'BACKLOG CCI$',
          pinned: false,
          colId: 'Backlog',
          field: 'Backlog.cci',
          minWidth: 125,
          menuTabs: ['filterMenuTab'],
          sortable: true,
          resizable: true,
          sort: GridColDefs.Shared.paramSort('Backlog'),
          aggFunc:
            metricsDropdown.selected.value === MetricsOptions.CciPercentage
              ? targetsDropdown.selected.value === ViewOptions.CompareProjection
                ? GridColDefs.Shared.sumCciPercentageCompare
                : GridColDefs.Shared.sumCciPercentage
              : 'sum',
          type: 'numberColumn',
        },
        {
          headerName: 'CCI%',
          pinned: false,
          colId: 'Total',
          field: 'Total.cciPct.cciPercent',
          minWidth: 125,
          menuTabs: ['filterMenuTab'],
          sortable: true,
          resizable: true,
          sort: GridColDefs.Shared.paramSort('TotalCCIPCT'),
          aggFunc:
            targetsDropdown.selected.value === ViewOptions.CompareProjection
              ? GridColDefs.Shared.sumCciPercentageCompare
              : GridColDefs.Shared.sumCciPercentage,
          type: 'percentColumn',
        },
        {
          headerName: 'ACTUALS CCI%',
          pinned: false,
          colId: 'Actual',
          field: 'Actual.cciPct.cciPercent',
          minWidth: 125,
          menuTabs: ['filterMenuTab'],
          sortable: true,
          resizable: true,
          sort: GridColDefs.Shared.paramSort('Actual'),
          aggFunc:
            targetsDropdown.selected.value === ViewOptions.CompareProjection
              ? GridColDefs.Shared.sumCciPercentageCompare
              : GridColDefs.Shared.sumCciPercentage,
          type: 'percentColumn',
        },
        {
          headerName: 'BACKLOG CCI%',
          pinned: false,
          colId: 'Backlog',
          field: 'Backlog.cciPct.cciPercent',
          minWidth: 125,
          menuTabs: ['filterMenuTab'],
          sortable: true,
          resizable: true,
          sort: GridColDefs.Shared.paramSort('Backlog'),
          aggFunc:
            targetsDropdown.selected.value === ViewOptions.CompareProjection
              ? GridColDefs.Shared.sumCciPercentageCompare
              : GridColDefs.Shared.sumCciPercentage,
          type: 'percentColumn',
        },
      ] as Array<ColDef>;
    },
    addCustomRendererConfigs: (
      periodColDefs: Array<any>,
      type: string,
      view: string
    ): Array<ColDef> => {
      const attrs: Record<string, string> =
        GridColDefs.Shared.getMetricAttributes(type);

      periodColDefs = periodColDefs.map((x: ColDef) => ({
        ...x,
        colId: x.field,
        minWidth: 65,
        field: `${x.field}.${attrs.attrName}`,
        sortable: true,
        resizable: true,
        sort: GridColDefs.Shared.paramSort(x.field),
        aggFunc:
          type === MetricsOptions.CciPercentage
            ? view === ViewOptions.CompareProjection
              ? GridColDefs.Shared.sumCciPercentageCompare
              : GridColDefs.Shared.sumCciPercentage
            : 'sum',
        type: attrs.columnType,
      }));

      return periodColDefs;
    },
    getMetricAttributes: (type: string): Record<string, string> => {
      const result: Record<string, string> = {
        attrName: '',
        columnType: 'numberColumn',
      };

      switch (type) {
        case MetricsOptions.Revenue:
          result.attrName = 'revenue';
          break;
        case MetricsOptions.Cci:
          result.attrName = 'cci';
          break;
        case MetricsOptions.CciPercentage:
          result.attrName = 'cciPct.cciPercent';
          result.columnType = 'percentColumn';
          break;
      }

      return result;
    },
    paramSort: (colId: string): string => {
      const sortColumsConfig: SortColum[] = [{ colId: 'Total', sort: 'desc' }];
      const sortColum = sortColumsConfig.find(
        (x: SortColum) => x.colId === colId
      );
      return sortColum ? sortColum.sort : '';
    },
    sumCciPercentage: (values: Array<number>, colDef?: ColDef): number => {
      let result = 0;

      if (colDef) {
        const totalCciAmount: number = sumBy(
          values,
          `${colDef.colId}.cciPct.cci`
        );
        const totalRevenueAmount: number = sumBy(
          values,
          `${colDef.colId}.cciPct.revenue`
        );

        if (typeof (totalCciAmount || totalRevenueAmount) === 'number') {
          result = (totalCciAmount / totalRevenueAmount || 0) * 100;
        }
      }

      return isFinite(result) ? result : 0;
    },
    sumCciPercentageCompare: (
      values: Array<number>,
      colDef?: ColDef
    ): number => {
      let result = 0;

      if (colDef) {
        const currentCci: number = sumBy(
          values,
          `${colDef.colId}.cciPct.currentCci`
        );
        const currentRevenue: number = sumBy(
          values,
          `${colDef.colId}.cciPct.currentRevenue`
        );
        const targetCci: number = sumBy(
          values,
          `${colDef.colId}.cciPct.targetCci`
        );
        const targetRevenue: number = sumBy(
          values,
          `${colDef.colId}.cciPct.targetRevenue`
        );

        if (
          typeof (
            currentCci ||
            currentRevenue ||
            targetCci ||
            targetRevenue
          ) === 'number'
        ) {
          let currentPercentage: number =
            currentCci / Math.abs(currentRevenue) || 0;
          let targetPercentage: number =
            targetCci / Math.abs(targetRevenue) || 0;
          currentPercentage = isFinite(currentPercentage)
            ? currentPercentage
            : 0;
          targetPercentage = isFinite(targetPercentage) ? targetPercentage : 0;

          result = (currentPercentage - targetPercentage) * 100;
        }
      }

      return result;
    },
  },
  getExtraGridOptions: (): GridOptions => {
    return {
      defaultCsvExportParams: {
        sheetName: 'Contracts',
        fileName: 'CBP Backlog',
      } as CsvExportParams,
    } as GridOptions;
  },
};
