import { EventEmitter, Injectable } from '@angular/core';

import {
  TrendModalFiltersChanged,
  TrendModalViewTypeChanged,
} from '../../components/tiles/trend-modal/entities/trend-modal-events';

import { TrendModalConfig } from '../../components/tiles/trend-modal/entities/trend-modal-config';
import { SelectedFilters } from '../entities/filters/selected-filters';
import { FileExportRequestPayload } from '../entities/file-export-request-payload';
import {
  FileExporterFileNames,
  FileExporterType,
} from '../../constants/file-exporter.constants';

import { FileExporterService } from '../file-exporter.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TrendModalService {
  constructor(
    private http: HttpClient,
    private fileExporterService: FileExporterService
  ) {}

  private openTrendModalEvent = new EventEmitter<TrendModalConfig>();
  private filtersChangedEvent = new EventEmitter<TrendModalFiltersChanged>();
  private viewTypeChangedEvent = new EventEmitter<TrendModalViewTypeChanged>();

  public get openTrendModalEmitted(): EventEmitter<TrendModalConfig> {
    return this.openTrendModalEvent;
  }

  public get filtersChanged(): EventEmitter<TrendModalFiltersChanged> {
    return this.filtersChangedEvent;
  }

  public get viewTypeChanged(): EventEmitter<TrendModalViewTypeChanged> {
    return this.viewTypeChangedEvent;
  }

  export(
    selectedFilters: SelectedFilters,
    extraParams: Record<string, any>,
    isCloud?: boolean
  ): Promise<boolean> {
    const payload: FileExportRequestPayload =
      FileExportRequestPayload.createRequest(selectedFilters, extraParams);

    const fileName: string = FileExporterFileNames.Trend;

    return this.fileExporterService.export(
      FileExporterType.table,
      payload,
      fileName,
      isCloud
    );
  }
}
