import { assign } from 'lodash';

import { SelectedFilters } from './filters/selected-filters';
import { ProductHierarchy } from './filters/product-hierarchy';
import { ServiceGroupValues } from './filters/service-group';
import { Wmu } from './filters/wmu';
import { ClientGroup } from './filters/client-group';
import { Industry } from './filters/industry';
import { FinancialCustomer } from './filters/financial-customer';
import { Location } from './filters/location';
import { FavoriteFilterDefaults } from 'src/app/modules/analytics/components/favorite-modal/entities/favorite-filter-defaults';

class AnalyticsFileExportRequestPayloadSchema {
  ServiceAttributes: Array<Record<string, any>>;
  Timeframe: Record<string, any>;
  customer: Record<string, any>;
  currency: Record<string, any>;
  locations: Array<Record<string, any>>;
  wmus: Array<Record<string, any>>;
  fc: Record<string, any>;
  serviceGroups: Array<Record<string, any>>;
  clientServiceGroups: Array<Record<string, any>>;
  attributesList: Array<Record<string, any>>;
  industries: Array<Record<string, any>>;
}

export class AnalyticsFileExportRequestPayload extends AnalyticsFileExportRequestPayloadSchema {
  constructor(filterAttributes?: AnalyticsFileExportRequestPayloadSchema) {
    super();
    assign(this, filterAttributes);
  }

  static createRequest(
    selectedFilters: SelectedFilters,
    favoriteFilterDefaults: FavoriteFilterDefaults,
    extraParams?: Record<string, any>,
    serviceAttributesExtraParams?: Array<Record<string, any>>
  ): AnalyticsFileExportRequestPayload {
    let request = new AnalyticsFileExportRequestPayload({
      ServiceAttributes: [
        {
          Name: 'CustomerNumber',
          Value: selectedFilters.customer.MasterCustomerNumber,
        },
        {
          Name: 'PortfolioId',
          Value: '',
        },
        {
          Name: 'ServiceGroupList',
          Value: this.getServiceGroups(selectedFilters),
        },
        {
          Name: 'OrganizationList',
          Value: this.getMarkets(selectedFilters),
        },
        {
          Name: 'TargetCurrency',
          Value: selectedFilters.getTargetCurrency(),
        },
        {
          Name: 'CurrencyType',
          Value: selectedFilters.getCurrencyType(),
        },
        {
          Name: 'RolledUpWmuList',
          Value: this.getWmus(selectedFilters),
        },
        {
          Name: 'ClientServiceGroupList',
          Value: this.getClientGroups(selectedFilters),
        },
        {
          Name: 'IndustryFilter',
          Value: this.getIndustries(selectedFilters),
        },
        {
          Name: 'FinancialCustomer',
          Value: this.getFinancialCustomers(selectedFilters),
        },
        {
          Name: 'IsCumulative',
          Value: '',
        },
        {
          Name: 'AttributesList',
          Value: this.getAttributes(selectedFilters),
        },
        {
          Name: 'View',
          Value: '',
        },
        {
          Name: 'GroupBy',
          Value: `${favoriteFilterDefaults.group1},${favoriteFilterDefaults.group2},${favoriteFilterDefaults.group3},${favoriteFilterDefaults.period}`,
        },
        {
          Name: 'DataSet1',
          Value: favoriteFilterDefaults.target1,
        },
        {
          Name: 'DataSet2',
          Value: favoriteFilterDefaults.target2,
        },
        {
          Name: 'Metrics',
          Value: favoriteFilterDefaults.metric,
        },
        {
          Name: 'SapCode',
          Value: '',
        },
        {
          Name: 'isMSA',
          Value: selectedFilters.isMSACvf.toString(),
        },
        {
          Name: 'fullyWeighted',
          Value: selectedFilters.fullyWeighted.toString(),
        },
      ],
      Timeframe: {
        StartTimeId: selectedFilters.timeframe.start,
        EndTimeId: selectedFilters.timeframe.end,
        TimePeriodCode: selectedFilters.timeframe.mmbPeriod,
        Name: selectedFilters.timeframe.title,
        FiscalYear: selectedFilters.timeframe.fiscalYear,
      },
      currency: {
        id: selectedFilters.currency.Id,
        value: selectedFilters.currency.Description,
      },
      customer: {
        id: selectedFilters.customer.MasterCustomerNumber,
        value: selectedFilters.customer.MasterCustomerName,
      },
      locations: this.getMarketsForInfoTab(selectedFilters),
      wmus: this.getWmusForInfoTab(selectedFilters),
      serviceGroups: this.getServiceGroupsForInfoTab(selectedFilters),
      attributesList: this.getAttributesForInfoTab(selectedFilters),
      clientServiceGroups: this.getClientGroupsForInfoTab(selectedFilters),
      fc: {
        id: this.getFinancialCustomers(selectedFilters),
        value: this.getFinancialCustomers(selectedFilters),
      },
      industries: this.getIndustriesForInfoTab(selectedFilters),
    });

    if (serviceAttributesExtraParams) {
      serviceAttributesExtraParams.forEach((x: Record<string, any>) => {
        const attr: Record<string, any> = request.ServiceAttributes.find(
          (y: Record<string, any>) => y.Name === x.Name
        );

        if (attr) {
          attr.Value = x.Value;
        } else {
          request.ServiceAttributes.push(x);
        }
      });
    }

    if (extraParams) {
      request = Object.assign({}, request, extraParams);
    }

    return request;
  }

  private static getAttributes(selectedFilters: SelectedFilters): string {
    const attributes: Array<ProductHierarchy> = [
      ...(selectedFilters.alliances ? selectedFilters.alliances : []),
      ...(selectedFilters.functions ? selectedFilters.functions : []),
      ...(selectedFilters.growthPriorities
        ? selectedFilters.growthPriorities
        : []),
    ];

    return (
      attributes.map((x: ProductHierarchy) => x.ProductCode).join(',') || ''
    );
  }

  private static getServiceGroups(selectedFilters: SelectedFilters): string {
    return (
      selectedFilters.serviceGroups
        ?.map((x: ServiceGroupValues) => x.ServiceGroupCd)
        .join(',') || ''
    );
  }

  private static getWmus(selectedFilters: SelectedFilters): string {
    return selectedFilters.wmus?.map((x: Wmu) => x.ID).join(',') || '';
  }

  private static getClientGroups(selectedFilters: SelectedFilters): string {
    return (
      selectedFilters.clientGroups
        ?.map((x: ClientGroup) => x.ClientGroupCd)
        .join(',') || ''
    );
  }

  private static getIndustries(selectedFilters: SelectedFilters): string {
    return (
      selectedFilters.industries
        ?.map((x: Industry) => x.EntityCode)
        .join(',') || ''
    );
  }

  private static getFinancialCustomers(
    selectedFilters: SelectedFilters
  ): string {
    return (
      selectedFilters.financialCustomers
        ?.map((x: FinancialCustomer) => x.CustomerNumber)
        .join(',') || ''
    );
  }

  private static getMarkets(selectedFilters: SelectedFilters): string {
    return (
      selectedFilters.markets
        ?.map((x: Location) => x.OrganizationId)
        .join(',') || ''
    );
  }

  private static getMarketsForInfoTab(
    selectedFilters: SelectedFilters
  ): Array<Record<string, any>> {
    return (
      selectedFilters.markets?.map((x: Location) => ({
        id: x.OrganizationId,
        value: x.OrganizationName,
      })) || []
    );
  }

  private static getWmusForInfoTab(
    selectedFilters: SelectedFilters
  ): Array<Record<string, any>> {
    return (
      selectedFilters.wmus?.map((x: Wmu) => {
        return { id: x.ID, value: x.UN };
      }) || []
    );
  }

  private static getServiceGroupsForInfoTab(
    selectedFilters: SelectedFilters
  ): Array<Record<string, string>> {
    return (
      selectedFilters.serviceGroups?.map((x: ServiceGroupValues) => {
        return { id: x.ServiceGroupCd, value: x.ServiceGroupNm };
      }) || []
    );
  }

  private static getClientGroupsForInfoTab(
    selectedFilters: SelectedFilters
  ): Array<Record<string, string>> {
    return (
      selectedFilters.clientGroups?.map((x: ClientGroup) => {
        return { id: x.ClientGroupCd, value: x.ClientGroupDesc };
      }) || []
    );
  }

  private static getIndustriesForInfoTab(
    selectedFilters: SelectedFilters
  ): Array<Record<string, string>> {
    return (
      selectedFilters.industries?.map((x: Industry) => {
        return { id: x.EntityCode, value: x.EntityDescription };
      }) || []
    );
  }

  private static getAttributesForInfoTab(
    selectedFilters: SelectedFilters
  ): Array<Record<string, string>> {
    const attributes: Array<ProductHierarchy> = [
      ...(selectedFilters.alliances ? selectedFilters.alliances : []),
      ...(selectedFilters.functions ? selectedFilters.functions : []),
      ...(selectedFilters.growthPriorities
        ? selectedFilters.growthPriorities
        : []),
    ];

    return (
      attributes.map((x: ProductHierarchy) => {
        return { id: x.ProductCode, value: x.ProductName };
      }) || []
    );
  }
}
