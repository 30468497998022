import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { BetaMessageService } from 'src/app/shared/services/beta-message.service';
import { WinsGridComponent } from './components/wins-grid/wins-grid.component';
import { WonCciTileComponent } from '../../../shared/components/tiles/projection-tile/components/won-cci-tile/won-cci-tile.component';
import { SalesGilcTileComponent } from '../../../shared/components/tiles/gilc-tile/components/sales-gilc-tile/sales-gilc-tile.component';
import { TargetsTileComponent } from '../../../shared/components/tiles/targets-tile/targets-tile.component';
import { TrendTileComponent } from '../../../shared/components/tiles/trend-tile/trend-tile.component';
import { SalesTileComponent } from '../../../shared/components/tiles/projection-tile/components/sales-tile/sales-tile.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-wins',
  templateUrl: './wins.component.html',
  styleUrls: ['./wins.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    SalesTileComponent,
    TrendTileComponent,
    TargetsTileComponent,
    SalesGilcTileComponent,
    WonCciTileComponent,
    WinsGridComponent,
  ],
})
export class WinsComponent implements OnInit {
  openTrendModalEmittedEvent = new Subject<boolean>();
  isGridExpanded: boolean;
  isCloud: boolean;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private betaMessage: BetaMessageService
  ) {}

  ngOnInit(): void {
    this.betaMessage.isCloudSubject$.subscribe((value) => {
      this.isCloud = value;
    });
  }

  public get metricType(): typeof MetricType {
    return MetricType;
  }

  onOpenTrendModalEmitted(isChartView: boolean, type: MetricType): void {
    this.openTrendModalEmittedEvent.next(isChartView);
  }

  onExpandChanged(isExpanded: boolean): void {
    this.isGridExpanded = isExpanded;
    this.changeDetectorRef.detectChanges();
  }
}
