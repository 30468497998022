import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { cloneDeep, isEmpty } from 'lodash';
import { Observable, ReplaySubject, Subscription, throwError, zip } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Headers } from 'src/app/core/constants/http.constants';
import { StorageType } from 'src/app/core/constants/storage.constants';
import { HttpUtils, IHttpOptions } from 'src/app/core/utils/http.utils';
import { StorageUtils } from 'src/app/core/utils/storage.utils';
import { Endpoints } from '../constants/endpoints.constants';
import {
  ActiveDates,
  CustomCurrencies,
  MmbTimePeriodCodes,
  PlanViews,
  SubMetrics,
  TimePeriodCodes,
} from '../constants/filters.constants';
import { ToastTemplates } from '../constants/toasts.constants';
import { MessageTemplates } from '../constants/messages.constants';
import { Currency } from './entities/filters/currency';
import { Customer } from './entities/filters/customer';
import { CustomerFilters } from './entities/filters/customer-filters';
import { Filters } from './entities/filters/filters';
import { SelectedFilters } from './entities/filters/selected-filters';
import { Timeframe } from './entities/filters/timeframe';
import { MmbFiltersUtils } from '../utils/mmb-filters.utils';
import { TimeframeItem } from '../components/timeframe/entities/timeframe';
import { Plan } from './entities/filters/plan';
import { PlanningWindow } from './entities/filters/planning-window';
import { Projection } from './entities/filters/projection';
import { ActiveDate } from './entities/filters/active-date';
import { ReasonCode } from './entities/reason-code';
import { Location } from './entities/filters/location';

import { AppMessagesService } from './app-messages.service';
import { FilterDefaultsService } from './filter-defaults.service';
import { SecurityService } from './security.service';
import { CustomerSettings } from './entities/customer-settings';
import { CustomerSettingsService } from './customersettings.service';
import { SharedService } from './shared.service';
import { AppStateService } from './app-state.service';
import { AppToastsService } from './app-toasts.service';
import { TextValuePair } from './entities/common/key-value';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  private isTesting = false;
  private isCloudFilters = false;
  private mmbParams: any;
  private planList: Array<Plan> = [];

  private globalFilters: Filters;
  private customerFilters: CustomerFilters;
  private customers: Array<Customer>;
  private selectedFilters: SelectedFilters;
  private customerSettings: CustomerSettings;
  isFlipFlopEnabled: boolean;

  private isCloudFiltersObservable = new ReplaySubject<CustomerFilters>(1);
  private isCloudGlobalFiltersObservable = new ReplaySubject<Filters>(1);
  private globalFiltersObservable = new ReplaySubject<Filters>(1);
  private customerFiltersObservable = new ReplaySubject<CustomerFilters>(1);
  private selectedFiltersObservable = new ReplaySubject<SelectedFilters>(1);
  private selectedMdlFiltObservable = new ReplaySubject<SelectedFilters>(1);
  private customersObservable = new ReplaySubject<Array<Customer>>(1);
  private reasonCodesObservable = new ReplaySubject<Array<ReasonCode>>(1);
  private customerSettingsObservable = new ReplaySubject<CustomerSettings>(1);

  public get globalFiltersChanged(): ReplaySubject<Filters> {
    return this.globalFiltersObservable;
  }

  public get customerFiltersChanged(): ReplaySubject<CustomerFilters> {
    return this.customerFiltersObservable;
  }

  public get customersChanged(): ReplaySubject<Array<Customer>> {
    return this.customersObservable;
  }

  public get isCloudFiltersChanged(): ReplaySubject<CustomerFilters> {
    return this.isCloudFiltersObservable;
  }
  public get isCloudGlobalFiltersChanged(): ReplaySubject<Filters> {
    return this.isCloudGlobalFiltersObservable;
  }

  public get selectedFiltersChanged(): ReplaySubject<SelectedFilters> {
    return this.selectedFiltersObservable;
  }
  public get selectedModalFiltersChanged(): ReplaySubject<SelectedFilters> {
    return this.selectedMdlFiltObservable;
  }

  public get reasonCodesChanged(): ReplaySubject<Array<ReasonCode>> {
    return this.reasonCodesObservable;
  }

  public get customerSettingsChanged(): ReplaySubject<CustomerSettings> {
    return this.customerSettingsObservable;
  }

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private filterDefaultsService: FilterDefaultsService,
    private securityService: SecurityService,
    private customerSettingsService: CustomerSettingsService,
    private appMessagesService: AppMessagesService,
    private sharedService: SharedService,
    private appToastsService: AppToastsService,
    private appStateService: AppStateService
  ) {}

  init(callback?: () => void): void {
    this.appStateService.appStateChanged.subscribe((AppState) => {
      this.isFlipFlopEnabled = AppState.gcpFlipFlopFlagEnabled;
      this.isCloudFilters = this.isFlipFlopEnabled;
    });
    if (this.hasUrlFilterParams()) {
      const routeSubscription = new Subscription();

      routeSubscription.add(
        this.activatedRoute.queryParams.subscribe((params: Params) => {
          if (!isEmpty(params)) {
            this.mmbParams = {};
            this.mmbParams.MC = params.MC;
            this.mmbParams.filters = JSON.parse(
              this.activatedRoute.snapshot.queryParams.filters
            );
            this.selectedFilters = new SelectedFilters();
            routeSubscription.unsubscribe();
            this.getGlobalFilters(callback);
          }
        })
      );
    } else {
      const cachedFilters: string = StorageUtils.get(
        StorageType.LocalStorage,
        'selectedFilters'
      );
      if (cachedFilters) {
        this.selectedFilters = new SelectedFilters(JSON.parse(cachedFilters));
      } else {
        this.selectedFilters = new SelectedFilters();
        this.selectedFilters.clear();
      }
      this.getGlobalFilters(callback);
    }
  }

  private hasUrlFilterParams(): boolean {
    return (
      (window.location.search !== '' &&
        window.location.search.includes('filters')) ||
      this.isTesting
    );
  }

  private getGlobalFilters(callback?: () => void): void {
    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);

    const customerUrl = `${Endpoints.customer}?isCloud=` + this.isCloudFilters;
    const globalFiltersUrl =
      `${Endpoints.globalFilters}?isCloud=` + this.isCloudFilters;
    const speculativeReasonCodeUrl =
      `${Endpoints.speculativeReasonCode}?isCloud=` + this.isCloudFilters;
    zip(
      this.http.get<Array<Customer>>(customerUrl, options),
      this.http.get<Filters>(globalFiltersUrl, options),
      this.http.get<Array<ReasonCode>>(speculativeReasonCodeUrl, options)
    ).subscribe(
      ([customers, filters, reasonCodes]) => {
        this.setCustomerList(customers);
        this.setGlobalFilters(filters);
        this.reasonCodesObservable.next(reasonCodes);

        this.refreshCustomerSettings(() => {
          this.applyFilters();

          if (callback) {
            callback();
            callback = null;
          }
        });
      },
      (err: HttpErrorResponse) => {
        this.appMessagesService.show(MessageTemplates.UnexpectedError, {
          centered: true,
        });

        if (callback) {
          callback();
          callback = null;
        }
      }
    );
  }

  private getGlobalFiltersOnToggle(callback?: () => void): void {
    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);

    const customerUrl = `${Endpoints.customer}?isCloud=` + this.isCloudFilters;
    const globalFiltersUrl =
      `${Endpoints.globalFilters}?isCloud=` + this.isCloudFilters;
    const speculativeReasonCodeUrl =
      `${Endpoints.speculativeReasonCode}?isCloud=` + this.isCloudFilters;
    zip(
      this.http.get<Array<Customer>>(customerUrl, options),
      this.http.get<Filters>(globalFiltersUrl, options),
      this.http.get<Array<ReasonCode>>(speculativeReasonCodeUrl, options)
    ).subscribe(
      ([customers, filters, reasonCodes]) => {
        this.globalFilters = filters;
        this.setStoredGlobalFilters();
        this.isCloudGlobalFiltersChanged.next(this.globalFilters);
      },
      (err: HttpErrorResponse) => {
        this.appMessagesService.show(MessageTemplates.UnexpectedError, {
          centered: true,
        });

        if (callback) {
          callback();
          callback = null;
        }
      }
    );
  }

  private getCustomerFilters(customerNbr: string, callback?: () => void): void {
    const body = {
      customerNumber: customerNbr,
    };
    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);
    const url = `${Endpoints.customerFilters}?isCloud=` + this.isCloudFilters;
    this.http.post<CustomerFilters>(url, body, options).subscribe(
      (x: CustomerFilters) => {
        this.setCustomerFilters(x);

        if (callback) {
          callback();
          callback = null;
        }
      },
      (err: HttpErrorResponse) => {
        this.appMessagesService.show(MessageTemplates.UnexpectedError, {
          centered: true,
        });

        if (callback) {
          callback();
          callback = null;
        }
      }
    );
  }

  private getCustomerFiltersOnToggle(
    customerNbr: string,
    callback?: () => void
  ): void {
    const body = {
      customerNumber: customerNbr,
    };
    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);
    const url = `${Endpoints.customerFilters}?isCloud=` + this.isCloudFilters;
    this.http.post<CustomerFilters>(url, body, options).subscribe(
      (x: CustomerFilters) => {
        this.customerFilters = new CustomerFilters(x);
        this.setStoredCustomerFilters();
        this.isCloudFiltersObservable.next(this.customerFilters);

        if (callback) {
          callback();
          callback = null;
        }
      },
      (err: HttpErrorResponse) => {
        this.appMessagesService.show(MessageTemplates.UnexpectedError, {
          centered: true,
        });

        if (callback) {
          callback();
          callback = null;
        }
      }
    );
  }
  private setCustomerList(customers: Array<Customer>): void {
    this.customers = customers ?? new Array<Customer>();

    if (this.customers.length === 0) {
      this.appMessagesService.show(MessageTemplates.NoAccess, {
        centered: true,
      });
    } else {
      this.setStoredCustomer();

      if (
        this.selectedFilters.customer &&
        !this.customers.find(
          (x: Customer) =>
            x.MasterCustomerNumber ===
            this.selectedFilters.customer.MasterCustomerNumber
        )
      ) {
        StorageUtils.remove(StorageType.LocalStorage, 'selectedFilters');
        this.selectedFilters = new SelectedFilters();
      }

      if (!this.selectedFilters.customer) {
        this.selectedFilters.customer = this.customers[0];
      }

      if (this.selectedFilters.customer) {
        this.updateSelectedCustomer();
      }
    }

    this.customersObservable.next(this.customers);
  }

  updateIsCloudFiltersList(isCloudFilter: boolean): void {
    this.isCloudFilters = isCloudFilter ?? false;
    this.getCustomerFiltersOnToggle(
      this.selectedFilters.customer.MasterCustomerNumber
    );
    this.getGlobalFiltersOnToggle();
  }

  private setGlobalFilters(filters: Filters): void {
    this.globalFilters = filters;
    this.validateTimeframe();
    this.addCustomCurrencies();
    this.setStoredGlobalFilters();
    this.globalFiltersObservable.next(this.globalFilters);
  }

  private setCustomerFilters(customerFilters: CustomerFilters): void {
    this.customerFilters = new CustomerFilters(customerFilters);
    this.setStoredCustomerFilters();
    this.customerFilters.setPrototypes();
    this.customerFiltersObservable.next(this.customerFilters);
  }

  private setStoredCustomer(): void {
    if (this.mmbParams) {
      this.selectedFilters.customer = MmbFiltersUtils.getMMBCustomer(
        this.customers,
        this.mmbParams.customer || this.mmbParams.MC
      );
    } else if (window.location?.pathname?.startsWith('/share-board')) {
      const urlCustomerNbr: string = window.location.pathname.split('/')[3];

      if (urlCustomerNbr) {
        const customer: Customer = this.customers.find(
          (x: Customer) => x.MasterCustomerNumber === urlCustomerNbr
        );
        this.selectedFilters.customer = customer;
      }
    }
  }

  private setStoredGlobalFilters(): void {
    if (this.mmbParams) {
      const appDate: ActiveDate = this.globalFilters.activeDates.find(
        (x: ActiveDate) => x.DateTypeCd === ActiveDates.ApplicationDate
      );

      this.selectedFilters.currency = MmbFiltersUtils.getMMBCurrency(
        this.globalFilters.currencies,
        this.mmbParams.filters
      );
      this.selectedFilters.industries = MmbFiltersUtils.getMMBIndustry(
        this.globalFilters.industry,
        this.mmbParams.filters
      );
      this.selectedFilters.alliances = MmbFiltersUtils.getMMBAttributes(
        this.globalFilters.alliances,
        this.mmbParams.filters
      );
      this.selectedFilters.growthPriorities = MmbFiltersUtils.getMMBAttributes(
        this.globalFilters.sgi,
        this.mmbParams.filters
      );
      this.selectedFilters.functions = MmbFiltersUtils.getMMBAttributes(
        this.globalFilters.functions,
        this.mmbParams.filters
      );
      this.selectedFilters.markets = MmbFiltersUtils.getMMBLocation(
        this.globalFilters.locations,
        this.mmbParams.filters
      );
      this.selectedFilters.serviceGroups = MmbFiltersUtils.getMMBServiceGroup(
        this.globalFilters.serviceGroups.ServiceGroups,
        this.mmbParams.filters
      );
      this.selectedFilters.timeframe = MmbFiltersUtils.getMMBTimeframe(
        this.globalFilters.timeframe,
        cloneDeep(this.selectedFilters.defaultTimeframe),
        appDate,
        this.mmbParams.filters
      );
      // this.filter.Plan = this.mmbMapper.getMMBPlan(this.globalFilters.plan, this.mmbParams.filters);
      // this.filter.PlanningWindow = this.mmbMapper.getMMBPlanningWindow(this.globalFilters, this.mmbParams.filters);
    }
  }

  private setStoredCustomerFilters(): void {
    if (this.mmbParams) {
      this.selectedFilters.clientGroups =
        MmbFiltersUtils.getMMBClientServiceGroups(
          this.customerFilters.csg,
          this.mmbParams.filters
        );
      this.selectedFilters.financialCustomers =
        MmbFiltersUtils.getMMBFinancialCustomer(
          this.customerFilters.financialCustomer,
          this.mmbParams.filters
        );
      this.selectedFilters.wmus = MmbFiltersUtils.getMMBWmus(
        this.customerFilters.wmu,
        this.mmbParams.filters
      );
    }
  }

  private addCustomCurrencies(): void {
    const global = {
      Id: CustomCurrencies.Global.id,
      Description: CustomCurrencies.Global.text,
    } as Currency;
    const fxAdjusted = {
      Id: CustomCurrencies.FxAdjusted.id,
      Description: CustomCurrencies.FxAdjusted.text,
    } as Currency;
    const planRate = {
      Id: CustomCurrencies.Constant.id,
      Description: CustomCurrencies.Constant.text,
    } as Currency;
    const currencies: Array<Currency> = [global, fxAdjusted, planRate];

    this.globalFilters.currencies = currencies.concat(
      this.globalFilters.currencies
        .map(
          (x: Currency) =>
            new Currency({
              Id: x.Id,
              Description: x.Id,
            })
        )
        .sort((c1: Currency, c2: Currency) => {
          return c1.Id.toLowerCase() === c2.Id.toLowerCase()
            ? 0
            : c1.Id.toLowerCase() > c2.Id.toLowerCase()
            ? 1
            : -1;
        })
    );

    if (!this.selectedFilters.currency) {
      this.updateSelectedCurrency(this.globalFilters.currencies[0]);
    }
  }

  private validateTimeframe(): void {
    const cfy: Timeframe = this.globalFilters.timeframe.find(
      (x: Timeframe) => x.TimePeriodCode === TimePeriodCodes.CurrentFY
    );

    const appDate: ActiveDate = this.globalFilters.activeDates.find(
      (x: ActiveDate) => x.DateTypeCd === ActiveDates.ApplicationDate
    );

    this.selectedFilters.defaultTimeframe = new TimeframeItem({
      title: cfy.Name,
      start: cfy.StartTimeId,
      end: cfy.EndTimeId,
      fiscalYear: cfy.FiscalYear,
      code: TimePeriodCodes.CurrentFY,
      mmbPeriod: MmbTimePeriodCodes.Current,
      isPast: appDate.StartTimeId > cfy.EndTimeId,
      type: TimePeriodCodes.CurrentFY,
    });

    if (!this.selectedFilters.timeframe) {
      this.updateSelectedTimeframe(
        cloneDeep(this.selectedFilters.defaultTimeframe)
      );
    }
  }

  private updateDataTarget(): void {
    // this.updateForecastList();
    this.updateProjection();
    this.updatePlanList();
    this.updatePlanView();
    this.updatePlannigWindow();
  }

  private updateProjection(): void {
    this.selectedFilters.projection = this.globalFilters.projection;
  }

  private updatePlan(monthLabel: string): void {
    this.selectedFilters.plan = this.planList.find(
      (x: Plan) => x.monthLabel === monthLabel
    );
  }

  private updatePlanList(): void {
    const labelsPlan: Array<Plan> = cloneDeep(this.globalFilters.plan);
    const planForYear: Plan = labelsPlan.find(
      (f: Plan) => Number(f.year) === this.selectedFilters.timeframe.fiscalYear
    );

    if (planForYear) {
      this.planList.push(planForYear);
    } else {
      this.planList.push(
        new Plan({
          IfsLabel: '',
          applyBeforeMMBFilter: true,
          monthLabel: SubMetrics.Plan.toUpperCase(),
          sapCode: '',
          timeId: -1,
          year: -1,
        })
      );
    }

    const monthLabel: string = !this.planList.find(
      (x: Plan) => x.monthLabel === this.selectedFilters.plan?.monthLabel
    )
      ? this.planList[0].monthLabel
      : this.selectedFilters.plan?.monthLabel;

    this.updatePlan(monthLabel);
  }

  private updatePlanView(): void {
    this.selectedFilters.planView = PlanViews.Standard;
  }

  private updatePlannigWindow(): void {
    this.selectedFilters.planningWindow =
      this.globalFilters.planningWindow.find(
        (x: PlanningWindow) =>
          x.FiscalYear === this.selectedFilters.timeframe.fiscalYear
      );
  }

  applyFilters(filter?: SelectedFilters): void {
    // this.validateFilter();
    if (filter) {
      this.selectedFilters = filter;
    }
    StorageUtils.set(
      StorageType.LocalStorage,
      'selectedFilters',
      this.selectedFilters
    );
    this.updateDataTarget();
    this.selectedFilters.setPrototypes();
    this.selectedFiltersObservable.next(cloneDeep(this.selectedFilters));
    this.securityService.hasAccountPlanRole(
      this.selectedFilters.customer.MasterCustomerNumber
    );
    // this.filter.appliedBoardName = '';
    // this.mmbParams = undefined;
  }
  applyCustomerFilters(filter?: SelectedFilters): void {
    // this.validateFilter();
    if (filter) {
      this.selectedFilters = filter;
    }
    StorageUtils.set(
      StorageType.LocalStorage,
      'selectedFilters',
      this.selectedFilters
    );
  }

  updateSelectedCustomer(customerNbr?: string): void {
    if (!customerNbr) {
      this.getCustomerFilters(
        this.selectedFilters.customer.MasterCustomerNumber,
        () => this.applyFilters()
      );
    } else if (
      customerNbr !== this.selectedFilters.customer.MasterCustomerNumber
    ) {
      this.selectedFilters.clear();
      this.selectedFilters.customer = this.customers.find(
        (x: Customer) => x.MasterCustomerNumber === customerNbr
      );
      this.getCustomerFilters(
        this.selectedFilters.customer.MasterCustomerNumber,
        () => this.applyFilters()
      );
    }

    this.filterDefaultsService.getAllByCustomer(
      this.selectedFilters.customer.MasterCustomerNumber
    );
  }

  updateSelectedCurrency(currency: Currency): void {
    this.selectedFilters.currency = currency;
  }

  updateSelectedTimeframe(timeframe: TimeframeItem): void {
    this.selectedFilters.timeframe = timeframe;
  }

  updateSelectedFilters(selectedFilters: SelectedFilters): void {
    this.selectedFilters = selectedFilters;
  }

  updateSelectedMSACvf(isMSACvf: boolean, hasAccountPlan: boolean): void {
    this.selectedFilters.isMSACvf = isMSACvf;

    if (hasAccountPlan) {
      this.customerSettings.updateSetting('MsaCvfToggle', isMSACvf);
      this.customerSettings.updatedBy = this.sharedService.getEnterpriseId();
      this.customerSettingsService
        .updateCustomerSettings(this.customerSettings)
        .then(() => {
          this.appToastsService.show(ToastTemplates.MSACvfUpdated);
        });
    }
  }

  updateFullyWeighted(fullyWeighted: boolean) {
    this.selectedFilters.fullyWeighted = fullyWeighted;
    this.applyFilters();
  }

  updateTarget(target: TextValuePair) {
    this.selectedFilters.target = target;
    this.selectedFiltersObservable.next(cloneDeep(this.selectedFilters));
  }

  modalFilters(isNew: boolean, filters: SelectedFilters) {
    if (isNew) {
      this.selectedMdlFiltObservable.next(cloneDeep(this.selectedFilters));
    } else {
      this.selectedMdlFiltObservable.next(cloneDeep(filters));
    }
  }

  getLocationsByClientGroup(
    csg: string,
    isCloud: boolean
  ): Promise<Array<Location>> {
    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);

    return this.http
      .get<Array<Location>>(
        `${Endpoints.locationFilters}?csg=${csg}&isCloud=${isCloud}`,
        options
      )
      .pipe(catchError(this.errorHandler.bind(this)))
      .toPromise();
  }

  refreshCustomerSettings(callback?: () => void): void {
    this.customerSettingsService
      .getCustomerSettings(this.selectedFilters.customer)
      .then((x: CustomerSettings) => {
        this.customerSettings = x;
        this.customerSettingsObservable.next(x);

        const settings: Record<string, any> = JSON.parse(x.settings);
        this.selectedFilters.isMSACvf = settings['MsaCvfToggle'] || false;

        if (callback) {
          callback();
          callback = null;
        }
      });
  }

  private errorHandler(error: HttpErrorResponse): Observable<never> {
    return throwError(error || 'Server error (FiltersService)');
  }
}
