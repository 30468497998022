import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { StorageUtils } from './core/utils/storage.utils';
import { environment } from 'src/environments/environment';
import { StorageKeys, StorageType } from './core/constants/storage.constants';

import { RebarAuthService } from './core/rebarauth/rebar.auth.service';
import { FiltersService } from './shared/services/filters.service';
import { SharedService } from './shared/services/shared.service';
import { datadogRum } from '@datadog/browser-rum';
import { AppConfigService } from './core/services/app-config.service';
import { DatadogSessionService } from './core/services/datadog-session.service';
import { FooterComponent } from './footer/footer.component';
import { SpinnerComponent } from './shared/components/base/spinner/spinner.component';
import { FavoriteModalComponent } from './modules/analytics/components/favorite-modal/favorite-modal.component';
import { BoardModalComponent } from './shared/components/board-modal/board-modal.component';
import { SpeculativeModalComponent } from './shared/components/speculative-modal/speculative-modal.component';
import { OpportunityPhasingModalComponent } from './shared/components/opportunity-phasing-modal/opportunity-phasing-modal.component';
import { TrendModalComponent } from './shared/components/tiles/trend-modal/trend-modal.component';
import { TrainingModeIndicatorComponent } from './shared/components/training-mode-indicator/training-mode-indicator.component';
import { ImpersonationIndicatorComponent } from './shared/components/impersonation-indicator/impersonation-indicator.component';
import { ChatComponent } from './shared/components/notification-framework/chat/chat.component';
import { ToastComponent } from './shared/components/notification-framework/toast/toast.component';
import { AlertComponent } from './shared/components/notification-framework/alert/alert.component';
import { ModalComponent } from './shared/components/notification-framework/modal/modal.component';
import { UniversalHeaderComponent } from './shared/components/notification-framework/universal-header/universal-header.component';
import { AppToastsComponent } from './shared/components/app-toasts/app-toasts.component';
import { RouterOutlet } from '@angular/router';
import { NgIf } from '@angular/common';
import { ActualsIndicatorComponent } from './shared/components/actuals-indicator/actuals-indicator.component';
import { ReadonlyModeIndicatorComponent } from './shared/components/readonly-mode-indicator/readonly-mode-indicator.component';
import { AdminIndicatorComponent } from './shared/components/admin-indicator/admin-indicator.component';
import { FavoritePickerComponent } from './modules/analytics/components/favorite-picker/favorite-picker.component';
import { MsaFilterComponent } from './shared/components/msa-filter/msa-filter.component';
import { BoardPickerComponent } from './shared/components/board-picker/board-picker.component';
import { FiltersPickerComponent } from './shared/components/filters-picker/filters-picker.component';
import { CustomerPickerComponent } from './shared/components/customer-picker/customer-picker.component';
import { NavbarComponent } from './shared/components/navbar/navbar.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    NavbarComponent,
    CustomerPickerComponent,
    FiltersPickerComponent,
    BoardPickerComponent,
    MsaFilterComponent,
    FavoritePickerComponent,
    AdminIndicatorComponent,
    ReadonlyModeIndicatorComponent,
    ActualsIndicatorComponent,
    NgIf,
    RouterOutlet,
    AppToastsComponent,
    UniversalHeaderComponent,
    ModalComponent,
    AlertComponent,
    ToastComponent,
    ChatComponent,
    ImpersonationIndicatorComponent,
    TrainingModeIndicatorComponent,
    TrendModalComponent,
    OpportunityPhasingModalComponent,
    SpeculativeModalComponent,
    BoardModalComponent,
    FavoriteModalComponent,
    SpinnerComponent,
    FooterComponent,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  title = 'cbp-web';
  loadingCompleted = false;

  constructor(
    private authService: RebarAuthService,
    private filtersService: FiltersService,
    private sharedService: SharedService,
    private appConfigService: AppConfigService,
    private datadogSessionService: DatadogSessionService
  ) {}

  ngOnInit(): void {
    StorageUtils.set(
      StorageType.LocalStorage,
      StorageKeys.ENVIRONMENT_URL,
      `${environment.baseServiceUrl}gonefishing`
    );

    this.addSubscriptions();

    datadogRum.init({
      applicationId: (
        this.appConfigService.config['datadog'] as Record<string, string>
      )['appId'],
      clientToken: (
        this.appConfigService.config['datadog'] as Record<string, string>
      )['clientToken'],
      site: 'datadoghq.com',
      service: (
        this.appConfigService.config['datadog'] as Record<string, string>
      )['service'],
      env: (this.appConfigService.config['datadog'] as Record<string, string>)[
        'environment'
      ],
      sampleRate: 100,
      trackInteractions: true,
      allowedTracingOrigins: [
        (this.appConfigService.config['datadog'] as Record<string, string>)[
          'allowedTracingOriginsDNS'
        ],
      ],
    });

    datadogRum.setUser({
      id: this.datadogSessionService.setDataDogSessionId(),
    });

    // this.authService.authObserver$.subscribe((authStatus: boolean) => {
    //   console.log(`user is logged in ${authStatus}`);
    // });
  }

  addSubscriptions(): void {
    if (this.authService.isUserAuthenticated()) {
      this.sharedService.loadStarted();

      this.sharedService.saveLoginForMetrics();

      this.sharedService.checkImpersonation().then(() => {
        this.filtersService.init(() => {
          this.sharedService.loadCompleted();
        });
      });

      this.subscription.add(
        this.sharedService.hasLoadingCompleted.subscribe((x: boolean) => {
          this.loadingCompleted = x;
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
