import { Component, Input } from '@angular/core';

import {
  AppMessage,
  AppMessageButton,
  ButtonType,
} from 'src/app/shared/services/entities/app-message';

import { AppMessagesService } from '../../services/app-messages.service';
import { HtmlSanitizerPipe } from '../../pipes/html-sanitizer.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
  selector: 'app-message-content',
  templateUrl: './app-message-content.component.html',
  styleUrls: ['./app-message-content.component.scss'],
  standalone: true,
  imports: [NgIf, FontAwesomeModule, NgFor, NgClass, HtmlSanitizerPipe],
})
export class AppMessageContentComponent {
  @Input() id: string;
  @Input() message: AppMessage;

  constructor(private appMessagesService: AppMessagesService) {}

  close(): void {
    this.appMessagesService.close(this.id);
  }

  getButtonCss(button: AppMessageButton): string {
    switch (button.type) {
      case ButtonType.primary:
        return 'btn btn-primary';
      case ButtonType.secondary:
        return 'btn btn-secondary';
      default:
        return 'btn btn-light';
    }
  }
}
