import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { OptionItem } from '../../entities/option-item';
import { OptionsListConfig } from '../../entities/options-list-config';
import { FilterArrayPipe } from '../../../../../pipes/filter-array.pipe';
import { FormsModule } from '@angular/forms';
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss'],
  standalone: true,
  imports: [NgFor, FormsModule, FilterArrayPipe],
})
export class CheckboxListComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  @Input() config: OptionsListConfig;
  @Input() searchText: string;
  @Input() isDisabled: boolean;
  @Input() onSelectAllChangedObs: Observable<boolean>;
  @Output() itemSelectionChangeEvent = new EventEmitter<Array<OptionItem>>();

  itemSelectionEmitterTimeout: any;

  ngOnInit(): void {
    this.addSubscriptions();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  addSubscriptions(): void {
    if (this.onSelectAllChangedObs) {
      this.subscription.add(
        this.onSelectAllChangedObs.subscribe((x: boolean) => {
          this.onSelectAllChanged(x);
        })
      );
    }
  }

  onItemSelection(item: OptionItem): void {
    clearTimeout(this.itemSelectionEmitterTimeout);

    this.itemSelectionEmitterTimeout = setTimeout(() => {
      this.itemSelectionChangeEvent.emit(
        this.config.items.filter((x: OptionItem) => x.checked)
      );
    }, 100);
  }

  onSelectAllChanged(checked: boolean): void {
    if (this.config && this.config.items) {
      this.config.items.map((x: OptionItem) => {
        x.checked = checked;
      });

      this.itemSelectionChangeEvent.emit(
        this.config.items.filter((x: OptionItem) => x.checked)
      );
    }
  }
}
