import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { combineLatest, ReplaySubject, Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { cloneDeep } from 'lodash';

import { ColDef } from 'ag-grid-community';
import {
  GridToolbarConfig,
  GridToolbarDropdown,
  GridToolbarIcon,
  GridToolbarSwitch,
} from 'src/app/shared/components/base/grid/components/grid-toolbar/entities/grid-toolbar-config';
import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import {
  ComponentNames,
  GridColDefs,
  GridFilters,
} from './constants/actualsales-grid.constants';
import { MmbRequestPayload } from 'src/app/shared/services/entities/mmb-request-payload';
import {
  GridConfig,
  GridConfigFeatures,
} from 'src/app/shared/components/base/grid/entities/grid-config';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { GridObservables } from 'src/app/shared/components/base/grid/entities/grid-observables';
import { ActualSalesResponse } from 'src/app/shared/services/entities/grids/actualsales-response';
import {
  CustomOptions,
  ViewOptions,
} from 'src/app/shared/constants/grid.constants';
import { ActualSalesGridUtils } from './utils/actualsales-grid.utils';
import { MessageTemplates } from 'src/app/shared/constants/messages.constants';
import {
  AppMessage,
  AppMessageButton,
} from 'src/app/shared/services/entities/app-message';
import { GridData } from 'src/app/shared/components/base/grid/entities/grid-data';
import { GridValidationsUtils } from 'src/app/shared/utils/grid-validations.utils';
import { TabControlNames } from 'src/app/shared/components/board-modal/components/board-projection-tab/constants/actual-sales-tab.constants';

import { ActualSalesGridService } from 'src/app/shared/services/grids/actualsales-grid.service';
import { FiltersService } from 'src/app/shared/services/filters.service';
import { AppMessagesService } from 'src/app/shared/services/app-messages.service';
import { BoardService } from 'src/app/shared/services/board.service';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faDownload,
  faExpand,
  faCompress,
} from '@fortawesome/free-solid-svg-icons';
import { BoardTabControl } from 'src/app/shared/services/entities/board-response';
import { BetaMessageService } from 'src/app/shared/services/beta-message.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { AppState } from 'src/app/shared/services/entities/app-state/app-state';
import { GridComponent } from '../../../../../shared/components/base/grid/grid.component';
import { GridToolbarComponent } from '../../../../../shared/components/base/grid/components/grid-toolbar/grid-toolbar.component';

@Component({
  selector: 'app-actualsales-grid',
  templateUrl: './actualsales-grid.component.html',
  styleUrls: ['./actualsales-grid.component.scss'],
  standalone: true,
  imports: [GridToolbarComponent, GridComponent],
})
export class ActualSalesGridComponent implements OnInit, OnDestroy {
  @Output() expandChangedEvent = new EventEmitter<boolean>();
  @Input() isCloud: boolean;

  subscription = new Subscription();

  loadChangedEvent = new Subject<boolean>();
  updateRowDataEvent = new ReplaySubject<GridData>(1);
  updateColDefsEvent = new ReplaySubject<Array<ColDef>>(1);
  filtersChangedEvent = new Subject<string>();

  isBeta = false;
  isFlipFlopEnabled: boolean;
  filters: Filters;
  selectedFilters: SelectedFilters;
  params: Params;
  gridToolbarConfig: GridToolbarConfig;
  gridConfig: GridConfig;
  contracts: Array<ActualSalesResponse>;
  isExpanded: boolean;
  selectedTarget: TextValuePair;

  public get metricType(): typeof MetricType {
    return MetricType;
  }

  constructor(
    private filtersService: FiltersService,
    private actualSalesGridService: ActualSalesGridService,
    private appMessagesService: AppMessagesService,
    private activatedRoute: ActivatedRoute,
    private boardService: BoardService,
    private appStateService: AppStateService,
    private betaMessage: BetaMessageService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      combineLatest([
        this.filtersService.globalFiltersChanged,
        this.filtersService.selectedFiltersChanged,
        this.activatedRoute.queryParams,
        this.appStateService.appStateChanged,
      ]).subscribe(
        ([x, y, z, a]: [Filters, SelectedFilters, Params, AppState]) => {
          this.filters = x;
          this.isFlipFlopEnabled = a.gcpFlipFlopFlagEnabled;
          const timeframeChanged: boolean =
            (this.selectedFilters &&
              this.selectedFilters.timeframe.getDescription() !==
                y.timeframe.getDescription()) ||
            false;
          this.selectedFilters = y;
          this.params = z;
          const boardChanged: boolean =
            this.boardService.selectedBoard?.tab?.SubTabName ===
            TabControlNames.actualSales;

          if (!this.gridToolbarConfig || boardChanged) {
            this.initializeToolbar();
            this.filtersService.updateTarget(this.selectedTarget);
            this.initializeGridConfig();
          }

          this.validateFilterSelection(timeframeChanged);

          this.getData();
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initializeToolbar(): void {
    const targets: Array<TextValuePair> = GridFilters.TargetOptions(
      this.selectedFilters
    );
    let selectedPeriod: TextValuePair = this.params?.period
      ? GridFilters.Periods.find(
          (x: TextValuePair) => x.value === this.params.period
        )
      : GridFilters.Periods[0];

    this.selectedTarget = this.params?.target
      ? targets.find((x: TextValuePair) => x.value === this.params.target)
      : this.selectedFilters.target ?? targets[0];

    let isNoActivity: boolean;

    if (
      this.boardService.selectedBoard?.tab?.SubTabName ===
      TabControlNames.actualSales
    ) {
      const periodDropdown: BoardTabControl =
        this.boardService.selectedBoard.tab.Controls.find(
          (x: BoardTabControl) => x.key === TabControlNames.periods
        );
      const targetDropdown: BoardTabControl =
        this.boardService.selectedBoard.tab.Controls.find(
          (x: BoardTabControl) => x.key === TabControlNames.targets
        );
      const expandSwitch = this.boardService.selectedBoard.tab.Controls.find(
        (x: BoardTabControl) => x.key === TabControlNames.expand
      );
      isNoActivity = this.boardService.selectedBoard.tab.Controls.find(
        (x: BoardTabControl) => x.key === TabControlNames.noActivity
      )?.value;

      selectedPeriod = GridFilters.Periods.find(
        (x: TextValuePair) =>
          x.value.toLowerCase() === periodDropdown.value.toLowerCase()
      );

      this.selectedTarget = targets.find(
        (x: TextValuePair) =>
          x.value.toLowerCase() === targetDropdown.value.toLowerCase()
      );

      this.isExpanded = expandSwitch?.value;
      this.expandChangedEvent.emit(expandSwitch?.value);

      this.boardService.selectedBoard = null;
    }

    this.gridToolbarConfig = new GridToolbarConfig({
      componentName: ComponentNames.actualSales,
      controls: [
        new GridToolbarDropdown({
          controlName: ComponentNames.periods,
          items: GridFilters.Periods,
          onChange: this.onPeriodChanged.bind(this),
          selected: selectedPeriod,
        }),
        new GridToolbarDropdown({
          controlName: ComponentNames.targets,
          items: targets,
          onChange: this.onTargetChanged.bind(this),
          selected: this.selectedTarget,
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.export,
          icon: faDownload as IconProp,
          onClick: this.onFileExport.bind(this),
          text: 'EXPORT',
          isDisabled: (x: GridToolbarConfig) => {
            const targetsDropdown: GridToolbarDropdown =
              this.gridToolbarConfig.findControl(ComponentNames.targets);

            return (
              targetsDropdown.selected.value === ViewOptions.CompareProjection
            );
          },
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.expand,
          icon: faExpand as IconProp,
          onClick: () => {
            this.isExpanded = true;
            this.expandChangedEvent.emit(true);
          },
          text: 'EXPAND',
          isVisible: () => {
            return !this.isExpanded;
          },
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.compress,
          icon: faCompress as IconProp,
          onClick: () => {
            this.isExpanded = false;
            this.expandChangedEvent.emit(false);
          },
          text: 'EXIT',
          isVisible: () => {
            return this.isExpanded;
          },
        }),
        new GridToolbarSwitch({
          controlName: ComponentNames.noActivity,
          text: 'NO ACTIVITY',
          onSwitch: this.onNoActivitySwitchChanged.bind(this),
          switchValue: isNoActivity,
        }),
      ],
    });
  }

  initializeGridConfig(): void {
    this.gridConfig =
      this.gridConfig ??
      new GridConfig({
        columns: [],
        features: new GridConfigFeatures({
          hasTotalsRow: true,
        }),
        options: GridColDefs.getExtraGridOptions(),
        observables: new GridObservables({
          loadChanged: this.loadChangedEvent.asObservable(),
          updateColDefs: this.updateColDefsEvent,
          updateRowData: this.updateRowDataEvent,
          filterChanged: this.filtersChangedEvent.asObservable(),
          expandChanged: this.expandChangedEvent.asObservable(),
        }),
      });

    this.updateColDefs();
  }

  updateColDefs(): void {
    this.updateColDefsEvent.next(
      GridColDefs.getColDefs(
        this.gridToolbarConfig,
        this.filters,
        this.selectedFilters
      )
    );
  }

  getData(): void {
    this.loadChangedEvent.next(true);
    const periodsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.periods);
    const targetsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.targets);

    const serviceAttributesExtraParams: Array<Record<string, any>> = [
      {
        Name: 'GroupBy',
        Value: `Contract,${periodsDropdown.selected.value}`,
      },
      {
        Name: 'DataSet',
        Value: targetsDropdown.selected.value,
      },
      {
        Name: 'SapCode',
        Value: '-1',
      },
    ];

    this.subscription.add(
      this.betaMessage.isCloudSubject$.subscribe((betaFlag) => {
        this.isBeta = betaFlag;
        this.isCloud =
          (betaFlag && !this.isFlipFlopEnabled) ||
          (!betaFlag && this.isFlipFlopEnabled);
        this.actualSalesGridService
          .getContracts(
            MmbRequestPayload.createRequest(
              this.selectedFilters,
              null,
              serviceAttributesExtraParams
            ),
            this.isCloud
          )
          .then((x: Array<ActualSalesResponse>) => {
            this.contracts = x;
            this.updateRowDataEvent.next(this.mapToGridData(x));
            this.loadChangedEvent.next(false);
            this.expandChangedEvent.emit(this.isExpanded);
          });
      })
    );
  }

  mapToGridData(response: Array<ActualSalesResponse>): GridData {
    let contracts: Array<ActualSalesResponse> = cloneDeep(response);
    let notAssigned: Array<ActualSalesResponse> = [];

    const notAssignedIndex: number = contracts.findIndex(
      (y: ActualSalesResponse) => y.ContractId === '2'
    );

    if (notAssignedIndex > -1) {
      notAssigned = contracts.splice(notAssignedIndex, 1);
      notAssigned.map(
        (y: ActualSalesResponse) =>
          (y.ContractName = CustomOptions.NotAssigned.text)
      );
    }

    const noActivitySwitch: GridToolbarSwitch =
      this.gridToolbarConfig.findControl(ComponentNames.noActivity);

    if (noActivitySwitch.switchValue) {
      const periodsDropdown: GridToolbarDropdown =
        this.gridToolbarConfig.findControl(ComponentNames.periods);

      contracts = contracts.filter(
        (x: ActualSalesResponse) =>
          !x.isInactive(periodsDropdown.selected.value)
      );
    }

    const isCustom: boolean =
      this.selectedFilters.timeframe.title === 'Custom' ? true : false;

    return new GridData({
      rows: ActualSalesGridUtils.mapData(
        contracts,
        this.filters,
        this.gridToolbarConfig,
        isCustom
      ),
      pinnedTop: ActualSalesGridUtils.mapData(
        notAssigned,
        this.filters,
        this.gridToolbarConfig,
        isCustom
      ),
    });
  }

  validateFilterSelection(timeframeChanged?: boolean): void {
    let colDefsUpdated = false;

    const periodsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.periods);

    const targetsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.targets);

    GridValidationsUtils.validatePeriodSelection(
      periodsDropdown,
      this.selectedFilters,
      () => {
        this.updateColDefs();
        colDefsUpdated = true;
      }
    );

    GridValidationsUtils.validateSubMetricSelection(
      targetsDropdown,
      this.filters,
      this.selectedFilters,
      () => {
        this.updateColDefs();
        colDefsUpdated = true;
      }
    );

    if (timeframeChanged && !colDefsUpdated) {
      this.updateColDefs();
    }
  }

  onPeriodChanged(
    selected: TextValuePair,
    dropdown: GridToolbarDropdown
  ): void {
    dropdown.selected = selected;
    this.updateColDefs();
    this.getData();
  }

  onTargetChanged(
    selected: TextValuePair,
    dropdown: GridToolbarDropdown
  ): void {
    dropdown.selected = selected;
    this.filtersService.updateTarget(selected);
    this.updateColDefs();
    this.getData();
  }

  onFileExport(): void {
    new Promise(
      (
        resolve: (x: Record<string, boolean>) => void,
        reject: () => void
      ): void => {
        const sgMessage: AppMessage = MessageTemplates.ExportSgBreakdown;
        const wmuMessage: AppMessage = MessageTemplates.ExportWmuBreakdown;
        let isSgBreakdown = false;
        let isWmuBreakdown = false;
        sgMessage.buttons.forEach((x: AppMessageButton) => {
          x.action = (): void => {
            isSgBreakdown = x.text === 'Yes';
            this.appMessagesService.close(sgMessage.id);
            this.appMessagesService.show(wmuMessage, { centered: true });
          };
        });
        wmuMessage.buttons.forEach((x: AppMessageButton) => {
          x.action = (): void => {
            isWmuBreakdown = x.text === 'Yes';
            this.appMessagesService.close(wmuMessage.id);
            resolve({
              isSgBreakdown,
              isWmuBreakdown,
            });
          };
        });
        this.appMessagesService.show(sgMessage, { centered: true });
      }
    ).then((x: Record<string, boolean>) => {
      this.appMessagesService.show(MessageTemplates.FileExporterInit, {
        centered: true,
      });
      this.actualSalesGridService
        .export(this.selectedFilters, this.gridToolbarConfig, x, this.isCloud)
        .then((x: boolean) => {
          if (!x) {
            this.appMessagesService.show(MessageTemplates.UnexpectedError, {
              centered: true,
            });
          }
        });
    });
  }

  onSearchTextChanged(searchText: string): void {
    this.filtersChangedEvent.next(searchText);
  }

  onNoActivitySwitchChanged(
    value: boolean,
    switchControl: GridToolbarSwitch
  ): void {
    switchControl.switchValue = value;
    this.updateRowDataEvent.next(this.mapToGridData(this.contracts));
  }
}
