import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {
  NgbAccordionModule,
  NgbAlertModule,
  NgbDropdownModule,
  NgbNavModule,
  NgbPopoverModule,
  NgbToastModule,
  NgbTooltipModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { AgGridModule } from 'ag-grid-angular';
@NgModule({
  exports: [
    NgbDropdownModule,
    NgbNavModule,
    NgbTooltipModule,
    NgbToastModule,
    NgbTypeaheadModule,
    NgbAlertModule,
    NgbPopoverModule,
    NgbAccordionModule,
    VirtualScrollerModule,
    AgGridModule,
  ],
  imports: [
    HttpClientModule,
    NgbDropdownModule,
    NgbNavModule,
    NgbTooltipModule,
    NgbToastModule,
    NgbTypeaheadModule,
    NgbAlertModule,
    NgbPopoverModule,
    NgbAccordionModule,
    VirtualScrollerModule,
    AgGridModule,
  ],
})
export class SharedModule {}
