<div class="ag-custom-cell" [style.justify-content]="params?.align">
    <div class="grid-cell-link">
        <a id="cell-anchor" *ngIf="data.hasLink" [href]="data.link?.url" [target]="data.link?.target"
            [ngbTooltip]="data.hasTooltip ? params?.value : null" [container]="data.hasTooltip ? 'body' : null"
            [placement]="data.hasTooltip ? data.tooltip.placement : null" class="custom-link">{{params?.value}}</a>
        <span id="cell-value" *ngIf="!data.hasLink" [ngbTooltip]="data.hasTooltip ? params?.value : null"
            [container]="data.hasTooltip ? 'body' : null"
            [placement]="data.hasTooltip ? data.tooltip.placement : null">{{params?.value}}</span>
    </div>
    <div class="cell-icons">
        <div *ngFor="let i of data.icons" [ngbTooltip]="i.tooltip" container="body" placement="top"
            [style.cursor]="i.cursor" (click)="i.onClick(params?.data)" class="ml-2">
            <span *ngIf="i.icon">
                <fa-icon [icon]="i.icon">
                </fa-icon>
            </span>
        </div>
    </div>
</div>
