import { Component, EventEmitter, Input, Output } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { DropDownType } from 'src/app/shared/constants/filters.constants';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { SharedModule } from 'src/app/shared-module';

@Component({
  selector: 'app-drop-down-multiple',
  templateUrl: './drop-down-multiple.component.html',
  styleUrls: ['./drop-down-multiple.component.scss'],
  standalone: true,
  imports: [NgIf, SharedModule, NgClass, NgFor],
})
export class DropDownMultipleComponent {
  @Input() items: Array<TextValuePair> = [];
  @Input() selectedItems: Array<TextValuePair> = [];
  @Input() type: DropDownType = DropDownType.LinkBold;
  @Input() placeholder = 'List';
  @Output() itemsSelectionChangeEvent = new EventEmitter<
    Array<TextValuePair>
  >();

  id: string = uuidv4();

  onValueChanged(event: any, pair: TextValuePair): void {
    event.preventDefault();

    const index: number = this.selectedItems.findIndex(
      (x) => x.value === pair.value
    );

    if (index > -1) {
      this.selectedItems.splice(index, 1);
    } else {
      this.selectedItems.push(pair);
    }

    this.itemsSelectionChangeEvent.emit(this.selectedItems);
  }

  getClassName(): string {
    switch (this.type) {
      case DropDownType.Button:
        return 'btn-secondary';
      case DropDownType.Link:
        return 'btn-link link';
      case DropDownType.LinkBold:
        return 'btn-link link-bold';
      default:
        return '';
    }
  }

  getPlaceholder(): string {
    return `${this.placeholder} (${this.selectedItems.length})`;
  }

  isSelected(pair: TextValuePair): boolean {
    return this.selectedItems.some((x) => x.value === pair.value);
  }
}
