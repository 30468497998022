import { DecimalPipe, PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { isNull, isUndefined } from 'lodash';

import { ValueType } from '../constants/common.constants';

@Pipe({
  name: 'customNumber',
  standalone: true,
})
export class CustomNumberPipe implements PipeTransform {
  transform(value: number, type: ValueType, nullSymbol?: string): string {
    let result: string;
    const decimalPipe: DecimalPipe = new DecimalPipe('en-US');
    const percentPipe: PercentPipe = new PercentPipe('en-US');

    if (isUndefined(value) || isNull(value) || isNaN(value)) {
      if (nullSymbol) {
        return nullSymbol;
      }

      value = 0;
    }

    switch (type) {
      case ValueType.Numeric:
        result = decimalPipe.transform(Math.abs(value), '1.0-0');
        break;
      case ValueType.Decimal:
        result = decimalPipe.transform(Math.abs(value), '1.0-2');
        break;
      case ValueType.Percentage:
        result = percentPipe.transform(Math.abs(value), '1.0-1');
        break;
      case ValueType.BPS:
        result = decimalPipe.transform(Math.abs(value * 100 * 100), '1.1-1');
        break;
      case ValueType.BPS_Rounded:
        result = decimalPipe.transform(Math.abs(value * 100 * 100), '1.0-0');
        break;
      default:
        result = '';
        break;
    }

    return value < 0 ? `(${result})` : result;
  }
}
