import { cloneDeep, isNull, isUndefined, sortBy } from 'lodash';

import { TreeViewItem } from '../entities/tree-view-item';

export class TreeViewUtils {
  static getItems<T>(
    source: Array<T>,
    valueAttr: string,
    textAttr: string,
    parentAttr: string,
    callback?: (x: TreeViewItem, y: T) => void,
    children?: Array<T>
  ): Array<TreeViewItem> {
    const items = new Array<TreeViewItem>();
    let root: Array<any> = children
      ? children
      : source.filter(
          (x: T) =>
            isNull((x as any)[parentAttr]) ||
            (x as any)[parentAttr] === 0 ||
            (x as any)[parentAttr] === ''
        );
    const hasParent: boolean = children ? true : false;

    root = sortBy(root, textAttr);

    if (root) {
      root.forEach((s: T) => {
        const item = new TreeViewItem({
          text: (s as any)[textAttr],
          value: (s as any)[valueAttr],
          hasParent,
        });

        const childrenAux: Array<T> = source.filter(
          (c: T) => (c as any)[parentAttr] === (s as any)[valueAttr]
        );

        if (childrenAux?.length > 0) {
          item.children = this.getItems(
            source,
            valueAttr,
            textAttr,
            parentAttr,
            callback,
            childrenAux
          );

          item.children.forEach((x: TreeViewItem) => {
            x.parent = item;
          });
        }

        if (callback) {
          callback(item, s);
        }

        items.push(item);
      });
    }

    callback = null;
    return items;
  }

  static search(
    items: Array<TreeViewItem>,
    searchText: string
  ): Array<TreeViewItem> {
    if (
      !isNull(searchText) &&
      !isUndefined(searchText) &&
      searchText.length > 0
    ) {
      const clonedItems: Array<TreeViewItem> = cloneDeep(items);
      return clonedItems.filter(
        (item: TreeViewItem) => !isNull(this.searchInChildren(item, searchText))
      );
    }

    return items;
  }

  private static searchInChildren(item: TreeViewItem, searchText: string) {
    if (item.children?.length > 0) {
      item.children = item.children.filter(
        (child: TreeViewItem) =>
          !isNull(this.searchInChildren(child, searchText))
      );
    }

    if (item.children?.length > 0) {
      item.isOpen = true;
      return item;
    } else if (this.searchForText(item, searchText)) {
      return item;
    } else {
      return null;
    }
  }

  private static searchForText(
    item: TreeViewItem,
    searchText: string
  ): boolean {
    return (
      item.text.toLowerCase().trim().indexOf(searchText.trim().toLowerCase()) >=
      0
    );
  }

  static searchItem<T>(items: Array<TreeViewItem>, value: T): TreeViewItem {
    if (items?.length > 0 && value) {
      let item: TreeViewItem = items.find(
        (x: TreeViewItem) => x.value === value
      );

      if (item) {
        return item;
      } else {
        for (let i = 0; i < items.length; i++) {
          item = this.searchItem(items[i].children, value);

          if (item) {
            return item;
          }
        }
      }
    }

    return null;
  }

  static iterateItems(
    items: Array<TreeViewItem>,
    callback: (x: TreeViewItem) => void
  ): void {
    if (items?.length > 0) {
      items.forEach((item: TreeViewItem) => {
        if (item.hasChildren()) {
          this.iterateItems(item.children, callback);
        }

        callback(item);
      });
    }
  }

  static getSelectedCountByNodes(items: Array<TreeViewItem>): number {
    let result = 0;

    if (items?.length > 0) {
      for (let i = 0; i < items.length; i++) {
        const item: TreeViewItem = items[i];

        if (!item.hasParent) {
          if (item.checked) {
            result++;
          } else if (item.hasChildren() && item.areAllChildrenChecked()) {
            result += item.children.length;
          } else {
            result += this.getSelectedCountByNodes(item.children);
          }
        } else if (item.hasChildren()) {
          if (item.areAllChildrenChecked()) {
            result += item.checked ? 1 : item.children.length;
          } else {
            result += this.getSelectedCountByNodes(item.children);
          }
        } else if (item.checked) {
          result++;
        }
      }
    }

    return result;
  }

  static getSelectedNodes(items: Array<TreeViewItem>): Array<TreeViewItem> {
    const result: Array<TreeViewItem> = [];

    if (items?.length > 0) {
      for (let i = 0; i < items.length; i++) {
        const item: TreeViewItem = items[i];

        if (!item.hasParent) {
          if (item.checked) {
            result.push(item);
          } else if (item.hasChildren() && item.areAllChildrenChecked()) {
            result.push(...item.children);
          } else {
            result.push(...this.getSelectedNodes(item.children));
          }
        } else if (item.hasChildren()) {
          if (item.areAllChildrenChecked()) {
            if (item.checked) {
              result.push(item);
            } else {
              result.push(...item.children);
            }
          } else {
            result.push(...this.getSelectedNodes(item.children));
          }
        } else if (item.checked) {
          result.push(item);
        }
      }
    }

    return result;
  }

  static expandNode(item: TreeViewItem, expand: boolean): void {
    if (item) {
      let aux: TreeViewItem = item.parent;

      while (aux) {
        aux.isOpen = expand;
        aux = aux.parent;
      }
    }
  }
}
