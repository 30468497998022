import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AppModulePaths } from 'src/app/shared/constants/navbar.constants';
import { BoardTab } from 'src/app/shared/services/entities/board-response';
import { TabsObservables } from '../tabs-picker/entities/tabs-observables';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { BoardWinsTabComponent } from './components/board-wins-tab/board-wins-tab.component';
import { BoardActualSalesTabComponent } from './components/board-actual-sales-tab/board-actual-sales-tab.component';
import { BoardOpportunitiesTabComponent } from './components/board-opportunities-tab/board-opportunities-tab.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgFor, NgIf, NgClass } from '@angular/common';
import { SharedModule } from 'src/app/shared-module';

@Component({
  selector: 'app-board-projection-tab',
  templateUrl: './board-projection-tab.component.html',
  styleUrls: ['./board-projection-tab.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    SharedModule,
    NgIf,
    FontAwesomeModule,
    NgClass,
    BoardOpportunitiesTabComponent,
    BoardActualSalesTabComponent,
    BoardWinsTabComponent,
  ],
})
export class BoardProjectionTabComponent implements OnInit {
  @Input() startSelectedTab: BoardTab;
  @Input() tabsObservables: TabsObservables;
  @Output() selectionChangeEvent = new EventEmitter<BoardTab>();

  selectedSubtab: Record<string, any>;
  subtabs: Array<Record<string, any>> = [
    AppModulePaths.ieopportunity.subtabs.opportunities,
    AppModulePaths.ieopportunity.subtabs.actualsales,
    AppModulePaths.ieopportunity.subtabs.wins,
  ];
  valuesToSave: BoardTab;
  appModulePaths = AppModulePaths;

  faCheckCircle = faCheckCircle as IconProp;

  constructor() {}

  ngOnInit(): void {
    this.setSelectedSubtab();
  }

  setSelectedSubtab(): void {
    const selected: Record<string, any> =
      this.subtabs.find(
        (x: Record<string, any>) => x.path === this.startSelectedTab.SubTabName
      ) || AppModulePaths.ieopportunity.subtabs.opportunities;

    this.onSubtabChanged(selected);
  }

  getSelectedValues(tab: BoardTab): void {
    this.selectionChangeEvent.emit(tab);
  }

  onSubtabChanged(subtab: Record<string, any>): void {
    this.selectedSubtab = subtab;
  }
}
