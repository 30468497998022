import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { combineLatest, ReplaySubject, Subject, Subscription } from 'rxjs';
import { cloneDeep, difference } from 'lodash';

import { ColDef } from 'ag-grid-community';
import {
  GridToolbarConfig,
  GridToolbarDropdown,
  GridToolbarIcon,
  GridToolbarSwitch,
} from 'src/app/shared/components/base/grid/components/grid-toolbar/entities/grid-toolbar-config';
import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import {
  ComponentNames,
  GridColDefs,
  GridFilters,
} from './constants/backlog-grid.constants';
import {
  MetricsOptions,
  CustomOptions,
  ViewOptions,
} from 'src/app/shared/constants/grid.constants';
import { MmbRequestPayload } from 'src/app/shared/services/entities/mmb-request-payload';
import { BacklogResponse } from 'src/app/shared/services/entities/grids/backlog-response';
import {
  GridConfig,
  GridConfigFeatures,
} from 'src/app/shared/components/base/grid/entities/grid-config';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { BacklogGridUtils } from './utils/backlog-grid.utils';
import { GridObservables } from 'src/app/shared/components/base/grid/entities/grid-observables';
import { FilterDefaults } from 'src/app/shared/services/entities/filters/filter-defaults';
import { FilterDefaultsSaveResponse } from 'src/app/shared/services/entities/filters/filter-defaults-response';
import { ToastTemplates } from 'src/app/shared/constants/toasts.constants';
import { MessageTemplates } from 'src/app/shared/constants/messages.constants';
import {
  AppMessage,
  AppMessageButton,
} from 'src/app/shared/services/entities/app-message';
import { GridData } from 'src/app/shared/components/base/grid/entities/grid-data';
import { GridValidationsUtils } from 'src/app/shared/utils/grid-validations.utils';
import { BoardTabControl } from 'src/app/shared/services/entities/board-response';
import { TabControlNames } from 'src/app/shared/components/board-modal/components/board-backlog-tab/constants/backlog-tab.constants';

import { FiltersService } from 'src/app/shared/services/filters.service';
import { FilterDefaultsService } from 'src/app/shared/services/filter-defaults.service';
import { BacklogGridService } from 'src/app/shared/services/grids/backlog-grid.service';
import { AppToastsService } from 'src/app/shared/services/app-toasts.service';
import { AppMessagesService } from 'src/app/shared/services/app-messages.service';
import { BoardService } from 'src/app/shared/services/board.service';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faDownload,
  faExpand,
  faCompress,
  faStar as faStartPainted,
} from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { BetaMessageService } from 'src/app/shared/services/beta-message.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { AppState } from 'src/app/shared/services/entities/app-state/app-state';
import { GridComponent } from '../../../../shared/components/base/grid/grid.component';
import { GridToolbarComponent } from '../../../../shared/components/base/grid/components/grid-toolbar/grid-toolbar.component';

@Component({
  selector: 'app-backlog-grid',
  templateUrl: './backlog-grid.component.html',
  styleUrls: ['./backlog-grid.component.scss'],
  standalone: true,
  imports: [GridToolbarComponent, GridComponent],
})
export class BacklogGridComponent implements OnInit, OnDestroy {
  @Output() expandChangedEvent = new EventEmitter<boolean>();
  @Output() metricChangedEvent = new EventEmitter<TextValuePair>();

  subscription = new Subscription();

  loadChangedEvent = new Subject<boolean>();
  updateRowDataEvent = new ReplaySubject<GridData>(1);
  updateColDefsEvent = new ReplaySubject<Array<ColDef>>(1);
  filtersChangedEvent = new Subject<string>();

  isFlipFlopEnabled: boolean;
  isBeta = false;
  filters: Filters;
  selectedFilters: SelectedFilters;
  params: Params;
  allFilterDefaults: Array<FilterDefaults> = [];
  filterDefaults: FilterDefaults;
  filterDefaultsAndSelectionDiff = false;
  gridToolbarConfig: GridToolbarConfig;
  gridConfig: GridConfig;
  contracts: Array<BacklogResponse>;
  isExpanded: boolean;
  selectedTarget: TextValuePair;
  isCloud: boolean;

  public get metricType(): typeof MetricType {
    return MetricType;
  }

  constructor(
    private filtersService: FiltersService,
    private filterDefaultsService: FilterDefaultsService,
    private backlogGridService: BacklogGridService,
    private appToastsService: AppToastsService,
    private appMessagesService: AppMessagesService,
    private activatedRoute: ActivatedRoute,
    private boardService: BoardService,
    private betaMessage: BetaMessageService,
    private appStateService: AppStateService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      combineLatest([
        this.filtersService.globalFiltersChanged,
        this.filtersService.selectedFiltersChanged,
        this.activatedRoute.queryParams,
        this.appStateService.appStateChanged,
      ]).subscribe(
        ([x, y, z, a]: [Filters, SelectedFilters, Params, AppState]) => {
          this.filters = x;
          this.isFlipFlopEnabled = a.gcpFlipFlopFlagEnabled;
          const timeframeChanged: boolean =
            (this.selectedFilters &&
              this.selectedFilters.timeframe.getDescription() !==
                y.timeframe.getDescription()) ||
            false;
          this.selectedFilters = y;

          this.initializeComponent(z, timeframeChanged);
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initializeComponent(params: Params, timeframeChanged?: boolean): void {
    const paramsChanged: boolean =
      (this.params && difference([this.params], [params])?.length > 0) || false;
    this.params = params;

    const boardChanged: boolean =
      this.boardService.selectedBoard?.tab?.Name === TabControlNames.backlog;

    const subscription = new Subscription();

    subscription.add(
      this.filterDefaultsService.filterDefaultsChanged.subscribe(
        (x: Array<FilterDefaults>) => {
          this.filterDefaultsAndSelectionDiff = false;
          this.allFilterDefaults = x;
          this.filterDefaults = this.allFilterDefaults.find(
            (y: FilterDefaults) => y.componentName === ComponentNames.backlog
          );

          if (!this.gridToolbarConfig || paramsChanged || boardChanged) {
            this.initializeToolbar();
            this.filtersService.updateTarget(this.selectedTarget);
            this.initializeGridConfig();
          }

          this.validateFilterSelection(timeframeChanged);

          this.getData();

          subscription.unsubscribe();
        }
      )
    );
  }

  initializeToolbar(): void {
    const targets: Array<TextValuePair> = GridFilters.TargetOptions(
      this.selectedFilters
    );
    let selectedMetric: TextValuePair = GridFilters.Metrics[0];
    let selectedPeriod: TextValuePair = GridFilters.Periods[0];
    this.selectedTarget = this.selectedFilters.target ?? targets[0];

    let isNoActivity: boolean;

    if (
      this.boardService.selectedBoard?.tab?.Name === TabControlNames.backlog
    ) {
      const metricsDropdown: BoardTabControl =
        this.boardService.selectedBoard.tab.Controls.find(
          (x: BoardTabControl) => x.key === TabControlNames.metrics
        );
      const periodDropdown: BoardTabControl =
        this.boardService.selectedBoard.tab.Controls.find(
          (x: BoardTabControl) => x.key === TabControlNames.periods
        );
      const targetDropdown: BoardTabControl =
        this.boardService.selectedBoard.tab.Controls.find(
          (x: BoardTabControl) => x.key === TabControlNames.targets
        );
      const expandSwitch = this.boardService.selectedBoard.tab.Controls.find(
        (x: BoardTabControl) => x.key === TabControlNames.expand
      );
      isNoActivity = this.boardService.selectedBoard.tab.Controls.find(
        (x: BoardTabControl) => x.key === TabControlNames.noActivity
      )?.value;

      selectedMetric = GridFilters.Metrics.find(
        (x: TextValuePair) =>
          x.value.toLowerCase() === metricsDropdown.value.toLowerCase()
      );

      selectedPeriod = GridFilters.Periods.find(
        (x: TextValuePair) =>
          x.value.toLowerCase() === periodDropdown.value.toLowerCase()
      );

      this.selectedTarget = targets.find(
        (x: TextValuePair) =>
          x.value.toLowerCase() === targetDropdown.value.toLowerCase()
      );

      this.isExpanded = expandSwitch?.value;
      this.expandChangedEvent.emit(this.isExpanded);

      this.boardService.selectedBoard = null;
    } else if (this.filterDefaults) {
      selectedMetric = GridFilters.Metrics.find(
        (x: TextValuePair) =>
          x.value.toLowerCase() ===
          this.filterDefaults
            .getFieldValue(ComponentNames.metrics)
            .toLowerCase()
      );
      selectedPeriod = GridFilters.Periods.find(
        (x: TextValuePair) =>
          x.value.toLowerCase() ===
          this.filterDefaults
            .getFieldValue(ComponentNames.periods)
            .toLowerCase()
      );
      this.selectedTarget = targets.find(
        (x: TextValuePair) =>
          x.value.toLowerCase() ===
          this.filterDefaults
            .getFieldValue(ComponentNames.targets)
            .toLowerCase()
      );
    }

    if (this.params) {
      selectedMetric = this.params?.metric
        ? GridFilters.Metrics.find((x: TextValuePair) => {
            const isPercentage: boolean = this.params.isPercentage === 'true';
            switch (Number(this.params.metric)) {
              case MetricType.Sales:
                return x.value === MetricsOptions.Sales;
              case MetricType.Revenue:
                return x.value === MetricsOptions.Revenue;
              case MetricType.Cci:
                return (
                  (x.value === MetricsOptions.Cci && !isPercentage) ||
                  (x.value === MetricsOptions.CciPercentage && isPercentage)
                );
              default:
                return x.value === MetricsOptions.Summary;
            }
          })
        : selectedMetric;

      selectedPeriod = this.params?.period
        ? GridFilters.Periods.find(
            (x: TextValuePair) => x.value === this.params.period
          )
        : selectedPeriod;

      this.selectedTarget = this.params?.target
        ? targets.find((x: TextValuePair) => x.value === this.params.target)
        : this.selectedTarget;
    }

    if (selectedMetric.value !== GridFilters.Metrics[0].value) {
      this.metricChangedEvent.next(selectedMetric);
    }

    this.gridToolbarConfig = new GridToolbarConfig({
      componentName: ComponentNames.backlog,
      controls: [
        new GridToolbarDropdown({
          controlName: ComponentNames.metrics,
          items: GridFilters.Metrics,
          onChange: this.onMetricChanged.bind(this),
          selected: selectedMetric,
        }),
        new GridToolbarDropdown({
          controlName: ComponentNames.periods,
          isVisible: this.isPeriodVisible,
          items: GridFilters.Periods,
          onChange: this.onPeriodChanged.bind(this),
          selected: selectedPeriod,
        }),
        new GridToolbarDropdown({
          controlName: ComponentNames.targets,
          items: targets,
          onChange: this.onTargetChanged.bind(this),
          selected: this.selectedTarget,
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.export,
          icon: faDownload as IconProp,
          onClick: this.onFileExport.bind(this),
          text: 'EXPORT',
          isDisabled: (x: GridToolbarConfig) => {
            const targetsDropdown: GridToolbarDropdown =
              this.gridToolbarConfig.findControl(ComponentNames.targets);

            return (
              targetsDropdown.selected.value === ViewOptions.CompareProjection
            );
          },
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.expand,
          icon: faExpand as IconProp,
          onClick: () => {
            this.isExpanded = true;
            this.expandChangedEvent.emit(true);
          },
          text: 'EXPAND',
          isVisible: () => {
            return !this.isExpanded;
          },
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.compress,
          icon: faCompress as IconProp,
          onClick: () => {
            this.isExpanded = false;
            this.expandChangedEvent.emit(false);
          },
          text: 'EXIT',
          isVisible: () => {
            return this.isExpanded;
          },
        }),
        new GridToolbarSwitch({
          controlName: ComponentNames.noActivity,
          text: 'NO ACTIVITY',
          onSwitch: this.onNoActivitySwitchChanged.bind(this),
          switchValue: isNoActivity,
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.filterDefaults,
          icon: faStartPainted as IconProp,
          tooltip: 'Set View as User Default',
          cssClass: 'star-icon',
          onClick: this.onSaveUserDefaultView.bind(this),
          isVisible: () => {
            return (
              !this.filterDefaultsAndSelectionDiff && !!this.filterDefaults
            );
          },
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.filterDefaults,
          icon: faStar as IconProp,
          tooltip: 'Set View as User Default',
          cssClass: 'star-icon',
          onClick: this.onSaveUserDefaultView.bind(this),
          isVisible: () => {
            return this.filterDefaultsAndSelectionDiff || !this.filterDefaults;
          },
        }),
      ],
    });
  }

  initializeGridConfig(): void {
    this.gridConfig =
      this.gridConfig ??
      new GridConfig({
        columns: [],
        features: new GridConfigFeatures({
          hasTotalsRow: true,
        }),
        options: GridColDefs.getExtraGridOptions(),
        observables: new GridObservables({
          loadChanged: this.loadChangedEvent.asObservable(),
          updateColDefs: this.updateColDefsEvent,
          updateRowData: this.updateRowDataEvent,
          filterChanged: this.filtersChangedEvent.asObservable(),
          expandChanged: this.expandChangedEvent.asObservable(),
        }),
      });

    this.updateColDefs();
  }

  updateColDefs(): void {
    this.updateColDefsEvent.next(
      GridColDefs.getColDefs(
        this.gridToolbarConfig,
        this.filters,
        this.selectedFilters
      )
    );
  }

  getData(): void {
    this.loadChangedEvent.next(true);
    const periodsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.periods);
    const targetsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.targets);

    const serviceAttributesExtraParams: Array<Record<string, any>> = [
      {
        Name: 'GroupBy',
        Value: `Contract,${periodsDropdown.selected.value}`,
      },
      {
        Name: 'DataSet',
        Value: targetsDropdown.selected.value,
      },
      {
        Name: 'SapCode',
        Value: '-1',
      },
    ];

    this.subscription.add(
      this.betaMessage.isCloudSubject$.subscribe((betaFlag) => {
        this.isBeta = betaFlag;
        this.isCloud =
          (betaFlag && !this.isFlipFlopEnabled) ||
          (!betaFlag && this.isFlipFlopEnabled);
        this.backlogGridService
          .getContracts(
            MmbRequestPayload.createRequest(
              this.selectedFilters,
              null,
              serviceAttributesExtraParams
            ),
            this.isCloud
          )
          .then((x: Array<BacklogResponse>) => {
            this.contracts = x;
            this.updateRowDataEvent.next(this.mapToGridData(x));
            this.loadChangedEvent.next(false);
            this.expandChangedEvent.emit(this.isExpanded);
          });
      })
    );
  }

  mapToGridData(response: Array<BacklogResponse>): GridData {
    let contracts: Array<BacklogResponse> = cloneDeep(response);
    let notAssigned: Array<BacklogResponse> = [];

    const notAssignedIndex: number = contracts.findIndex(
      (y: BacklogResponse) => y.GroupKey === CustomOptions.NotAssigned.id
    );

    if (notAssignedIndex > -1) {
      notAssigned = contracts.splice(notAssignedIndex, 1);
      notAssigned.map(
        (y: BacklogResponse) => (y.Name = CustomOptions.NotAssigned.text)
      );
    }

    const noActivitySwitch: GridToolbarSwitch =
      this.gridToolbarConfig.findControl(ComponentNames.noActivity);

    if (noActivitySwitch.switchValue) {
      const metricsDropdown: GridToolbarDropdown =
        this.gridToolbarConfig.findControl(ComponentNames.metrics);
      const periodsDropdown: GridToolbarDropdown =
        this.gridToolbarConfig.findControl(ComponentNames.periods);

      contracts = contracts.filter(
        (x: BacklogResponse) =>
          !x.isInactive(
            metricsDropdown.selected.value,
            periodsDropdown.selected.value
          )
      );
    }

    const isCustom: boolean =
      this.selectedFilters.timeframe.title === 'Custom' ? true : false;

    return new GridData({
      rows: BacklogGridUtils.mapData(
        contracts,
        this.filters,
        this.gridToolbarConfig,
        isCustom
      ),
      pinnedTop: BacklogGridUtils.mapData(
        notAssigned,
        this.filters,
        this.gridToolbarConfig,
        isCustom
      ),
    });
  }

  isPeriodVisible(config: GridToolbarConfig): boolean {
    const metricDropdown: GridToolbarDropdown = config.findControl(
      ComponentNames.metrics
    );
    return metricDropdown.selected.value !== MetricsOptions.Summary;
  }

  compareWithUserDefaultView(): void {
    if (this.filterDefaults) {
      const metricsDropdown: GridToolbarDropdown =
        this.gridToolbarConfig.findControl(ComponentNames.metrics);
      const periodsDropdown: GridToolbarDropdown =
        this.gridToolbarConfig.findControl(ComponentNames.periods);
      const targetsDropdown: GridToolbarDropdown =
        this.gridToolbarConfig.findControl(ComponentNames.targets);

      this.filterDefaultsAndSelectionDiff =
        this.filterDefaults.getFieldValue('metricName').toLowerCase() !==
          metricsDropdown.selected.value.toLowerCase() ||
        this.filterDefaults.getFieldValue('period').toLowerCase() !==
          periodsDropdown.selected.value.toLowerCase() ||
        this.filterDefaults.getFieldValue('target').toLowerCase() !==
          targetsDropdown.selected.value.toLowerCase();
    } else {
      this.filterDefaultsAndSelectionDiff = false;
    }
  }

  validateFilterSelection(timeframeChanged?: boolean): void {
    let colDefsUpdated = false;

    const periodDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.periods);

    const targetsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.targets);

    GridValidationsUtils.validatePeriodSelection(
      periodDropdown,
      this.selectedFilters,
      () => {
        this.updateColDefs();
        colDefsUpdated = true;
      }
    );

    GridValidationsUtils.validateSubMetricSelection(
      targetsDropdown,
      this.filters,
      this.selectedFilters,
      () => {
        this.updateColDefs();
        colDefsUpdated = true;
      }
    );

    if (timeframeChanged && !colDefsUpdated) {
      this.updateColDefs();
    }
  }

  onMetricChanged(
    selected: TextValuePair,
    dropdown: GridToolbarDropdown
  ): void {
    dropdown.selected = selected;
    this.updateColDefs();
    this.compareWithUserDefaultView();
    selected =
      selected.value === MetricsOptions.Summary
        ? GridFilters.Metrics[1]
        : selected;
    this.metricChangedEvent.next(selected);
  }

  onPeriodChanged(
    selected: TextValuePair,
    dropdown: GridToolbarDropdown
  ): void {
    dropdown.selected = selected;
    this.updateColDefs();
    this.getData();
    this.compareWithUserDefaultView();
  }

  onTargetChanged(
    selected: TextValuePair,
    dropdown: GridToolbarDropdown
  ): void {
    dropdown.selected = selected;
    this.filtersService.updateTarget(selected);
    this.updateColDefs();
    this.getData();
    this.compareWithUserDefaultView();
  }

  onFileExport(): void {
    new Promise(
      (
        resolve: (x: Record<string, boolean>) => void,
        reject: () => void
      ): void => {
        const sgMessage: AppMessage = MessageTemplates.ExportSgBreakdown;
        const wmuMessage: AppMessage = MessageTemplates.ExportWmuBreakdown;

        let isSgBreakdown = false;
        let isWmuBreakdown = false;

        sgMessage.buttons.forEach((x: AppMessageButton) => {
          x.action = (): void => {
            isSgBreakdown = x.text === 'Yes';
            this.appMessagesService.close(sgMessage.id);
            this.appMessagesService.show(wmuMessage, { centered: true });
          };
        });

        wmuMessage.buttons.forEach((x: AppMessageButton) => {
          x.action = (): void => {
            isWmuBreakdown = x.text === 'Yes';
            this.appMessagesService.close(wmuMessage.id);
            resolve({
              isSgBreakdown,
              isWmuBreakdown,
            });
          };
        });

        this.appMessagesService.show(sgMessage, { centered: true });
      }
    ).then((x: Record<string, boolean>) => {
      this.appMessagesService.show(MessageTemplates.FileExporterInit, {
        centered: true,
      });

      this.backlogGridService
        .export(this.selectedFilters, this.gridToolbarConfig, x, this.isCloud)
        .then((x: boolean) => {
          if (!x) {
            this.appMessagesService.show(MessageTemplates.UnexpectedError, {
              centered: true,
            });
          }
        });
    });
  }

  onSearchTextChanged(searchText: string): void {
    this.filtersChangedEvent.next(searchText);
  }

  onNoActivitySwitchChanged(
    value: boolean,
    switchControl: GridToolbarSwitch
  ): void {
    switchControl.switchValue = value;
    this.updateRowDataEvent.next(this.mapToGridData(this.contracts));
  }

  onSaveUserDefaultView(): void {
    if (this.filterDefaultsAndSelectionDiff || !this.filterDefaults) {
      const filterDefaults: FilterDefaults = this.filterDefaults
        ? cloneDeep(this.filterDefaults)
        : new FilterDefaults();

      const metricsDropdown: GridToolbarDropdown =
        this.gridToolbarConfig.findControl(ComponentNames.metrics);
      const periodsDropdown: GridToolbarDropdown =
        this.gridToolbarConfig.findControl(ComponentNames.periods);
      const targetsDropdown: GridToolbarDropdown =
        this.gridToolbarConfig.findControl(ComponentNames.targets);

      filterDefaults.componentName = ComponentNames.backlog;
      filterDefaults.customerNbr =
        this.selectedFilters.customer.MasterCustomerNumber;
      filterDefaults.setFieldValue(
        ComponentNames.metrics,
        metricsDropdown.selected.value
      );
      filterDefaults.setFieldValue(
        ComponentNames.periods,
        periodsDropdown.selected.value
      );
      filterDefaults.setFieldValue(
        ComponentNames.targets,
        targetsDropdown.selected.value
      );

      this.filterDefaultsService
        .save(filterDefaults)
        .then((x: FilterDefaultsSaveResponse) => {
          if (x.isSucessfull) {
            this.filterDefaultsAndSelectionDiff = false;
            this.filterDefaults = x.filterDefaults;
            this.appToastsService.show(ToastTemplates.FilterDefaultsSaved);
          }
        });
    }
  }
}
