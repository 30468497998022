import { flatMap } from 'lodash';
import moment from 'moment';

import {
  ActiveDates,
  MmbTimePeriodCodes,
  TimePeriodCodes,
} from 'src/app/shared/constants/filters.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { ActiveDate } from 'src/app/shared/services/entities/filters/active-date';
import { MmbDate } from 'src/app/shared/services/entities/filters/date';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { Timeframe } from 'src/app/shared/services/entities/filters/timeframe';
import {
  TimeframeGroup,
  TimeframeItem,
  TimeframeSubGroup,
} from '../entities/timeframe';

export class TimeframeUtils {
  static getDateByTimeId(timeId: number): Date {
    const dateZero = '2002-01-15T14:52:18+00:00';
    return moment(dateZero)
      .add(timeId - 1, 'M')
      .toDate();
  }

  static getFormattedFromDate(date: Date, format: string): string {
    return moment(date).format(format);
  }

  static getFormattedFromTimeId(timeId: number, format: string): string {
    const date: Date = this.getDateByTimeId(timeId);
    return this.getFormattedFromDate(date, format);
  }

  static getTimeframesRange(
    timeframe: TimeframeItem,
    dates: Array<MmbDate>
  ): Array<MmbDate> {
    const period: Array<MmbDate> = dates.filter(
      (x: MmbDate) => x.Id >= timeframe.start && x.Id <= timeframe.end
    );
    const response: Array<MmbDate> = [];

    period.forEach((y: MmbDate) => {
      if (!response.some((z) => z.Id === y.Id)) {
        response.push(y);
      }
    });
    return response;
  }

  static getTimeframes(
    timeframes: Array<Timeframe>,
    appDate: ActiveDate
  ): Array<TimeframeGroup> {
    const previousFy: Timeframe = this.getTimeframeByCode(
      timeframes,
      TimePeriodCodes.PreviousFY
    );
    const currentFy: Timeframe = this.getTimeframeByCode(
      timeframes,
      TimePeriodCodes.CurrentFY
    );
    const nextFy: Timeframe = this.getTimeframeByCode(
      timeframes,
      TimePeriodCodes.NextFY
    );

    const qtd: Timeframe = this.getTimeframeByCode(
      timeframes,
      TimePeriodCodes.QuarterToDate
    );
    const fytd: Timeframe = this.getTimeframeByCode(
      timeframes,
      TimePeriodCodes.FiscalYearToDate
    );
    const mtd: Timeframe = this.getTimeframeByCode(
      timeframes,
      TimePeriodCodes.MonthToDate
    );
    const currentMonth: Timeframe = this.getTimeframeByCode(
      timeframes,
      TimePeriodCodes.CurrentMonth
    );
    const currentQuarter: Timeframe = this.getTimeframeByCode(
      timeframes,
      TimePeriodCodes.CurrentQuarter
    );
    const rollingQuarters: Timeframe = this.getTimeframeByCode(
      timeframes,
      TimePeriodCodes.FourRollingQuarters
    );
    const rollingMonths: Timeframe = this.getTimeframeByCode(
      timeframes,
      TimePeriodCodes.TwelveRollingMonths
    );

    const pfy = new TimeframeGroup({
      title: 'Previous FY',
      subGroups: [
        new TimeframeSubGroup({
          title: '',
          items: [
            new TimeframeItem({
              title: previousFy.Name,
              start: previousFy.StartTimeId,
              end: previousFy.EndTimeId,
              fiscalYear: previousFy.FiscalYear,
              code: TimePeriodCodes.PreviousFY,
              mmbPeriod: MmbTimePeriodCodes.Previous,
              isPast: appDate.StartTimeId > previousFy.EndTimeId,
              type: TimePeriodCodes.PreviousFY,
            }),
          ],
        }),
        new TimeframeSubGroup({
          title: 'Fiscal Quarters',
          items: [
            new TimeframeItem({
              title: 'Q1',
              start: previousFy.StartTimeId,
              end: previousFy.StartTimeId + 2,
              fiscalYear: previousFy.FiscalYear,
              code: 'QuarterOnePreFY',
              mmbPeriod: MmbTimePeriodCodes.Previous,
              isPast: appDate.StartTimeId > previousFy.StartTimeId + 2,
              type: TimePeriodCodes.FiscalQuarters,
            }),
            new TimeframeItem({
              title: 'Q2',
              start: previousFy.StartTimeId + 3,
              end: previousFy.StartTimeId + 5,
              fiscalYear: previousFy.FiscalYear,
              code: 'QuarterTwoPreFY',
              mmbPeriod: MmbTimePeriodCodes.Previous,
              isPast: appDate.StartTimeId > previousFy.StartTimeId + 5,
              type: TimePeriodCodes.FiscalQuarters,
            }),
            new TimeframeItem({
              title: 'Q3',
              start: previousFy.StartTimeId + 6,
              end: previousFy.StartTimeId + 8,
              fiscalYear: previousFy.FiscalYear,
              code: 'QuarterThreePreFY',
              mmbPeriod: MmbTimePeriodCodes.Previous,
              isPast: appDate.StartTimeId > previousFy.StartTimeId + 8,
              type: TimePeriodCodes.FiscalQuarters,
            }),
            new TimeframeItem({
              title: 'Q4',
              start: previousFy.StartTimeId + 9,
              end: previousFy.EndTimeId,
              fiscalYear: previousFy.FiscalYear,
              code: 'QuarterFourPreFY',
              mmbPeriod: MmbTimePeriodCodes.Previous,
              isPast: appDate.StartTimeId > previousFy.EndTimeId,
              type: TimePeriodCodes.FiscalQuarters,
            }),
          ],
        }),
        new TimeframeSubGroup({
          title: 'Fiscal H1 + H2',
          items: [
            new TimeframeItem({
              title: 'H1',
              start: previousFy.StartTimeId,
              end: previousFy.StartTimeId + 5,
              fiscalYear: previousFy.FiscalYear,
              code: 'HalfYearOnePreFY',
              mmbPeriod: MmbTimePeriodCodes.Previous,
              isPast: appDate.StartTimeId > previousFy.StartTimeId + 5,
              type: TimePeriodCodes.FiscalHalves,
            }),
            new TimeframeItem({
              title: 'H2',
              start: previousFy.StartTimeId + 6,
              end: previousFy.EndTimeId,
              fiscalYear: previousFy.FiscalYear,
              code: 'HalfYearTwoPreFY',
              mmbPeriod: MmbTimePeriodCodes.Previous,
              isPast: appDate.StartTimeId > previousFy.EndTimeId,
              type: TimePeriodCodes.FiscalHalves,
            }),
          ],
        }),
      ],
    });

    const cfy = new TimeframeGroup({
      title: 'Current FY',
      subGroups: [
        new TimeframeSubGroup({
          title: 'Actuals',
          items: [
            new TimeframeItem({
              title: mtd.Name,
              start: mtd.StartTimeId,
              end: mtd.EndTimeId,
              fiscalYear: mtd.FiscalYear,
              code: mtd.TimePeriodCode,
              mmbPeriod: mtd.TimePeriodCode,
              isPast: true, //appDate.StartTimeId > mtd.EndTimeId,
              type: mtd.TimePeriodCode,
            }),
            new TimeframeItem({
              title: qtd.Name,
              start: qtd.StartTimeId,
              end: qtd.EndTimeId,
              fiscalYear: qtd.FiscalYear,
              code: qtd.TimePeriodCode,
              mmbPeriod: qtd.TimePeriodCode,
              isPast: true, //appDate.StartTimeId > qtd.EndTimeId,
              type: qtd.TimePeriodCode,
            }),
            new TimeframeItem({
              title: fytd.Name,
              start: fytd.StartTimeId,
              end: fytd.EndTimeId,
              fiscalYear: fytd.FiscalYear,
              code: fytd.TimePeriodCode,
              mmbPeriod: fytd.TimePeriodCode,
              isPast: true, //appDate.StartTimeId > fytd.EndTimeId,
              type: fytd.TimePeriodCode,
            }),
          ],
        }),
        new TimeframeSubGroup({
          title: 'Forecast',
          items: [
            new TimeframeItem({
              title: currentMonth.Name,
              start: currentMonth.StartTimeId,
              end: currentMonth.EndTimeId,
              fiscalYear: currentMonth.FiscalYear,
              code: currentMonth.TimePeriodCode,
              mmbPeriod: currentMonth.TimePeriodCode,
              isPast: appDate.StartTimeId > currentMonth.EndTimeId,
              type: currentMonth.TimePeriodCode,
            }),
            new TimeframeItem({
              title: currentQuarter.Name,
              start: currentQuarter.StartTimeId,
              end: currentQuarter.EndTimeId,
              fiscalYear: currentQuarter.FiscalYear,
              code: currentQuarter.TimePeriodCode,
              mmbPeriod: currentQuarter.TimePeriodCode,
              isPast: appDate.StartTimeId > currentQuarter.EndTimeId,
              type: currentQuarter.TimePeriodCode,
            }),
            new TimeframeItem({
              title: currentFy.Name,
              start: currentFy.StartTimeId,
              end: currentFy.EndTimeId,
              fiscalYear: currentFy.FiscalYear,
              code: TimePeriodCodes.CurrentFY,
              mmbPeriod: MmbTimePeriodCodes.Current,
              isPast: appDate.StartTimeId > currentFy.EndTimeId,
              type: TimePeriodCodes.CurrentFY,
            }),
          ],
        }),
        new TimeframeSubGroup({
          title: 'Fiscal Quarters',
          items: [
            new TimeframeItem({
              title: 'Q1',
              start: currentFy.StartTimeId,
              end: currentFy.StartTimeId + 2,
              fiscalYear: currentFy.FiscalYear,
              code: 'QuarterOneToDate',
              mmbPeriod: MmbTimePeriodCodes.Current,
              isPast: appDate.StartTimeId > currentFy.StartTimeId + 2,
              type: TimePeriodCodes.FiscalQuarters,
            }),
            new TimeframeItem({
              title: 'Q2',
              start: currentFy.StartTimeId + 3,
              end: currentFy.StartTimeId + 5,
              fiscalYear: currentFy.FiscalYear,
              code: 'QuarterTwoToDate',
              mmbPeriod: MmbTimePeriodCodes.Current,
              isPast: appDate.StartTimeId > currentFy.StartTimeId + 5,
              type: TimePeriodCodes.FiscalQuarters,
            }),
            new TimeframeItem({
              title: 'Q3',
              start: currentFy.StartTimeId + 6,
              end: currentFy.StartTimeId + 8,
              fiscalYear: currentFy.FiscalYear,
              code: 'QuarterThreeToDate',
              mmbPeriod: MmbTimePeriodCodes.Current,
              isPast: appDate.StartTimeId > currentFy.StartTimeId + 8,
              type: TimePeriodCodes.FiscalQuarters,
            }),
            new TimeframeItem({
              title: 'Q4',
              start: currentFy.StartTimeId + 9,
              end: currentFy.EndTimeId,
              fiscalYear: currentFy.FiscalYear,
              code: 'QuarterFourToDate',
              mmbPeriod: MmbTimePeriodCodes.Current,
              isPast: appDate.StartTimeId > currentFy.EndTimeId,
              type: TimePeriodCodes.FiscalQuarters,
            }),
          ],
        }),
        new TimeframeSubGroup({
          title: 'Fiscal H1 + H2',
          items: [
            new TimeframeItem({
              title: 'H1',
              start: currentFy.StartTimeId,
              end: currentFy.StartTimeId + 5,
              fiscalYear: currentFy.FiscalYear,
              code: 'HalfYearOneCurrent',
              mmbPeriod: MmbTimePeriodCodes.Current,
              isPast: appDate.StartTimeId > currentFy.StartTimeId + 5,
              type: TimePeriodCodes.FiscalHalves,
            }),
            new TimeframeItem({
              title: 'H2',
              start: currentFy.StartTimeId + 6,
              end: currentFy.EndTimeId,
              fiscalYear: currentFy.FiscalYear,
              code: 'HalfYearTwoCurrent',
              mmbPeriod: MmbTimePeriodCodes.Current,
              isPast: appDate.StartTimeId > currentFy.EndTimeId,
              type: TimePeriodCodes.FiscalHalves,
            }),
            new TimeframeItem({
              title: rollingQuarters.Name,
              start: rollingQuarters.StartTimeId,
              end: rollingQuarters.EndTimeId,
              fiscalYear: rollingQuarters.FiscalYear,
              code: rollingQuarters.TimePeriodCode,
              mmbPeriod: MmbTimePeriodCodes.Custom,
              isPast: appDate.StartTimeId > rollingQuarters.EndTimeId,
              type: rollingQuarters.TimePeriodCode,
            }),
            new TimeframeItem({
              title: rollingMonths.Name,
              start: rollingMonths.StartTimeId,
              end: rollingMonths.EndTimeId,
              fiscalYear: rollingMonths.FiscalYear,
              code: rollingMonths.TimePeriodCode,
              mmbPeriod: MmbTimePeriodCodes.Custom,
              isPast: appDate.StartTimeId > rollingMonths.EndTimeId,
              type: rollingMonths.TimePeriodCode,
            }),
          ],
        }),
      ],
    });

    const nfy = new TimeframeGroup({
      title: 'Next FY',
      subGroups: [
        new TimeframeSubGroup({
          title: '',
          items: [
            new TimeframeItem({
              title: nextFy.Name,
              start: nextFy.StartTimeId,
              end: nextFy.EndTimeId,
              fiscalYear: nextFy.FiscalYear,
              code: TimePeriodCodes.NextFY,
              mmbPeriod: MmbTimePeriodCodes.Future,
              isPast: appDate.StartTimeId > nextFy.EndTimeId,
              type: TimePeriodCodes.NextFY,
            }),
          ],
        }),
        new TimeframeSubGroup({
          title: 'Fiscal Quarters',
          items: [
            new TimeframeItem({
              title: 'Q1',
              start: nextFy.StartTimeId,
              end: nextFy.StartTimeId + 2,
              fiscalYear: nextFy.FiscalYear,
              code: 'QuarterOneNextFY',
              mmbPeriod: MmbTimePeriodCodes.Future,
              isPast: appDate.StartTimeId > nextFy.StartTimeId + 2,
              type: TimePeriodCodes.FiscalQuarters,
            }),
            new TimeframeItem({
              title: 'Q2',
              start: nextFy.StartTimeId + 3,
              end: nextFy.StartTimeId + 5,
              fiscalYear: nextFy.FiscalYear,
              code: 'QuarterTwoNextFY',
              mmbPeriod: MmbTimePeriodCodes.Future,
              isPast: appDate.StartTimeId > nextFy.StartTimeId + 5,
              type: TimePeriodCodes.FiscalQuarters,
            }),
            new TimeframeItem({
              title: 'Q3',
              start: nextFy.StartTimeId + 6,
              end: nextFy.StartTimeId + 8,
              fiscalYear: nextFy.FiscalYear,
              code: 'QuarterThreeNextFY',
              mmbPeriod: MmbTimePeriodCodes.Future,
              isPast: appDate.StartTimeId > nextFy.StartTimeId + 8,
              type: TimePeriodCodes.FiscalQuarters,
            }),
            new TimeframeItem({
              title: 'Q4',
              start: nextFy.StartTimeId + 9,
              end: nextFy.EndTimeId,
              fiscalYear: nextFy.FiscalYear,
              code: 'QuarterFourNextFY',
              mmbPeriod: MmbTimePeriodCodes.Future,
              isPast: appDate.StartTimeId > nextFy.EndTimeId,
              type: TimePeriodCodes.FiscalQuarters,
            }),
          ],
        }),
        new TimeframeSubGroup({
          title: 'Fiscal H1 + H2',
          items: [
            new TimeframeItem({
              title: 'H1',
              start: nextFy.StartTimeId,
              end: nextFy.StartTimeId + 5,
              fiscalYear: nextFy.FiscalYear,
              code: 'HalfYearOneNextFY',
              mmbPeriod: MmbTimePeriodCodes.Future,
              isPast: appDate.StartTimeId > nextFy.StartTimeId + 5,
              type: TimePeriodCodes.FiscalHalves,
            }),
            new TimeframeItem({
              title: 'H2',
              start: nextFy.StartTimeId + 6,
              end: nextFy.EndTimeId,
              fiscalYear: nextFy.FiscalYear,
              code: 'HalfYearTwoNextFY',
              mmbPeriod: MmbTimePeriodCodes.Future,
              isPast: appDate.StartTimeId > nextFy.EndTimeId,
              type: TimePeriodCodes.FiscalHalves,
            }),
          ],
        }),
      ],
    });

    return new Array<TimeframeGroup>(pfy, cfy, nfy);
  }

  static getCalendarMonths(): Array<TextValuePair> {
    return [
      {
        text: 'Sep',
        value: 9,
      },
      {
        text: 'Oct',
        value: 10,
      },
      {
        text: 'Nov',
        value: 11,
      },
      {
        text: 'Dec',
        value: 12,
      },
      {
        text: 'Jan',
        value: 1,
      },
      {
        text: 'Feb',
        value: 2,
      },
      {
        text: 'Mar',
        value: 3,
      },
      {
        text: 'Apr',
        value: 4,
      },
      {
        text: 'May',
        value: 5,
      },
      {
        text: 'Jun',
        value: 6,
      },
      {
        text: 'Jul',
        value: 7,
      },
      {
        text: 'Aug',
        value: 8,
      },
    ];
  }

  static getFiscalYears(
    dates: Array<MmbDate>,
    appDate: ActiveDate
  ): Array<TextValuePair> {
    const appDateTime: MmbDate = dates.find(
      (x: MmbDate) => x.Id === appDate.StartTimeId
    );
    return [
      {
        text: 'Previous FY',
        value: appDateTime.FiscalYearNbr - 1,
      },
      {
        text: 'Current FY',
        value: appDateTime.FiscalYearNbr,
      },
      {
        text: 'Next FY',
        value: appDateTime.FiscalYearNbr + 1,
      },
    ];
  }

  static getTimeframeItemByCode(filters: Filters, code: string): TimeframeItem {
    const appDate: ActiveDate = filters.activeDates.find(
      (x: ActiveDate) => x.DateTypeCd === ActiveDates.ApplicationDate
    );

    const timeframes: Array<TimeframeGroup> = TimeframeUtils.getTimeframes(
      filters.timeframe,
      appDate
    );

    const allTimeframes: Array<TimeframeItem> = flatMap(
      timeframes,
      (x: TimeframeGroup) =>
        flatMap(x.subGroups, (y: TimeframeSubGroup) => y.items)
    );

    const item: TimeframeItem = allTimeframes.find(
      (x: TimeframeItem) => x.code === code
    );

    return (
      item ||
      allTimeframes.find(
        (x: TimeframeItem) => x.code === TimePeriodCodes.CurrentFY
      )
    );
  }

  private static getTimeframeByCode(
    timeframes: Array<Timeframe>,
    code: string
  ): Timeframe {
    return timeframes.find((x: Timeframe) => x.TimePeriodCode === code);
  }

  static getAppDate(filters: Filters): ActiveDate {
    const appDate: ActiveDate = filters.activeDates.find(
      (x: ActiveDate) => x.DateTypeCd === ActiveDates.ApplicationDate
    );
    return appDate;
  }
}
