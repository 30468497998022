import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  forwardRef,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { TreeViewItem } from '../../entities/tree-view-item';
import { TreeViewConfig } from '../../entities/tree-view-config';
import { TreeViewUtils } from '../../utils/tree-view.utils';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgClass, NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'app-tree-view-radio-item',
  templateUrl: './tree-view-radio-item.component.html',
  styleUrls: ['./tree-view-radio-item.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    FontAwesomeModule,
    FormsModule,
    NgFor,
    forwardRef(() => TreeViewRadioItemComponent),
  ],
})
export class TreeViewRadioItemComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  @Input() config: TreeViewConfig;
  @Input() item: TreeViewItem;
  @Input() isSearch = false;
  @Input() onSelectAllChangedObs: Observable<boolean>;
  @Output() itemSelectionChangeEvent = new EventEmitter<TreeViewItem>();

  selectedItem: TreeViewItem;

  faAngleRight = faAngleRight as IconProp;
  faAngleDown = faAngleDown as IconProp;

  ngOnInit(): void {
    this.addSubscriptions();
    this.checkSelectedItem();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  addSubscriptions(): void {
    if (this.onSelectAllChangedObs) {
      this.subscription.add(
        this.onSelectAllChangedObs.subscribe((x: boolean) => {
          if (!x) {
            this.selectedItem = null;
          }
        })
      );
    }
  }

  checkSelectedItem(): void {
    let selected: Array<TreeViewItem> = [];

    if (this.config.selectByNodes) {
      selected = TreeViewUtils.getSelectedNodes(this.config.items);
    } else {
      TreeViewUtils.iterateItems(this.config.items, (x: TreeViewItem) => {
        if (x.checked) {
          selected.push(x);
        }
      });
    }

    if (selected && selected.length > 0) {
      this.selectedItem = selected[0].value;
    }
  }

  onToggle(): void {
    this.item.isOpen = !this.item.isOpen;
  }

  onItemSelection(): void {
    if (this.item.hasCheckBox) {
      TreeViewUtils.iterateItems(this.config.items, (x: TreeViewItem) => {
        x.checked = false;
      });

      this.item.checked = true;
      this.itemSelectionChangeEvent.emit(this.item);

      if (this.isSearch) {
        const originalItem: TreeViewItem = TreeViewUtils.searchItem<any>(
          this.config.items,
          this.item.value
        );
        if (originalItem) {
          originalItem.checked = this.item.checked;
        }
      }
    }
  }

  onChildItemSelection(child: TreeViewItem): void {
    this.itemSelectionChangeEvent.emit(child);
  }
}
