import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { v4 as uuidv4 } from 'uuid';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgFor, NgIf, NgClass } from '@angular/common';

@Component({
  selector: 'app-unordered-list',
  templateUrl: './unordered-list.component.html',
  styleUrls: ['./unordered-list.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, FontAwesomeModule, NgClass],
})
export class UnorderedListComponent implements OnChanges {
  @Input() items: Array<TextValuePair> = [];
  @Input() selectedItem: TextValuePair;
  @Output() itemSelectionChangeEvent = new EventEmitter<TextValuePair>();

  id: string = uuidv4();

  faCheckCircle = faCheckCircle as IconProp;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items && changes.items.currentValue) {
      this.initializeComponent();
    }
  }

  onValueChanged(value: TextValuePair): void {
    this.selectedItem = value;
    this.itemSelectionChangeEvent.emit(value);
  }

  initializeComponent(): void {
    if (this.items && this.items.length > 0 && !this.selectedItem) {
      this.selectedItem = this.items[0];
    }
  }
}
