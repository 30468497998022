import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';
import { isNumber } from 'lodash';

import Dictionary from 'src/app/core/utils/dictionary.utils';
import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { TargetsTileUtils } from '../utils/targets-tile.utils';
import { RequestPayload } from 'src/app/shared/services/entities/request-payload';
import { TargetsTileResponse } from '../entities/targets-tile-response';
import { TargetsTileErrorMessages } from './constants/targets-tile.constants';
import { TargetsChartConfig } from './components/targets-chart/entities/targets-chart-config';
import { TargetsTileDefinition } from '../entities/targets-tile-definition';
import { StorageUtils } from 'src/app/core/utils/storage.utils';
import { StorageType } from 'src/app/core/constants/storage.constants';

import { FiltersService } from 'src/app/shared/services/filters.service';
import { TargetTileService } from 'src/app/shared/services/tiles/target-tile.service';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { MetricsOptions } from 'src/app/shared/constants/grid.constants';
import { BetaMessageService } from 'src/app/shared/services/beta-message.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { AppState } from 'src/app/shared/services/entities/app-state/app-state';
import { SpinnerComponent } from '../../base/spinner/spinner.component';
import { TargetsChartComponent } from './components/targets-chart/targets-chart.component';
import { FeatureToggleComponent } from '../../feature-toggle/feature-toggle.component';
import { SwitchComponent } from '../../base/switch/switch.component';
import { NgIf } from '@angular/common';
import { DropDownComponent } from '../../base/drop-down/drop-down.component';

@Component({
  selector: 'app-targets-tile',
  templateUrl: './targets-tile.component.html',
  styleUrls: ['./targets-tile.component.scss'],
  standalone: true,
  imports: [
    DropDownComponent,
    NgIf,
    SwitchComponent,
    FeatureToggleComponent,
    TargetsChartComponent,
    SpinnerComponent,
  ],
})
export class TargetsTileComponent implements OnDestroy, OnChanges, OnInit {
  subscription = new Subscription();

  @Input() type: MetricType;
  @Output() configChangeEvent = new EventEmitter<TargetsChartConfig>();
  @Output() openTrendModalEmitted = new EventEmitter<boolean>();

  id: string = uuidv4();
  loaded: boolean;
  hasErrors: boolean;
  errorMessage: string;
  targetsChartConfig: TargetsChartConfig;
  isActuals = false;
  betaMessage: BetaMessageService;
  appStateService: AppStateService;
  isCloud: boolean;
  isBeta = false;
  isFlipFlopFlagEnabled: boolean;

  metrics: Array<TextValuePair> = [];
  selectedMetric: TextValuePair;

  constructor(
    private targetTileService: TargetTileService,
    private filterService: FiltersService,
    private errorHandlerService: ErrorHandlerService,
    private baseChangeDetector: ChangeDetectorRef,
    betaMessage: BetaMessageService,
    appStateService: AppStateService
  ) {
    this.betaMessage = betaMessage;
    this.appStateService = appStateService;
  }

  ngOnInit(): void {
    this.subscription.add(
      this.targetTileService.metricChanged.subscribe((x: TextValuePair) => {
        switch (x.value) {
          case MetricsOptions.Sales:
            this.type = MetricType.Sales;
            break;
          case MetricsOptions.Revenue:
            this.type = MetricType.Revenue;
            break;
          case MetricsOptions.Cci:
          case MetricsOptions.CciPercentage:
            this.type = MetricType.Cci;
            break;
          default:
            break;
        }

        const definition: TargetsTileDefinition =
          TargetsTileUtils.getDefinition(this.type);
        definition.isPercentage = x.value === MetricsOptions.CciPercentage;

        this.initializeComponent(definition);
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type && changes.type.currentValue) {
      this.initializeComponent();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initializeComponent(selectedDefinition?: TargetsTileDefinition): void {
    this.subscription.add(
      combineLatest([
        this.filterService.selectedFiltersChanged,
        this.appStateService.getAppState(),
      ]).subscribe(([x, y]: [SelectedFilters, AppState]) => {
        this.isActuals = x.timeframe.isPast;

        this.isFlipFlopFlagEnabled = y.gcpFlipFlopFlagEnabled;

        this.subscription.add(
          this.betaMessage.isCloudSubject$.subscribe((betaFlag) => {
            this.isBeta = betaFlag;
            this.isCloud =
              (betaFlag && !this.isFlipFlopFlagEnabled) ||
              (!betaFlag && this.isFlipFlopFlagEnabled);

            this.loadStarted();
            this.clearTile();
            this.getMetricList();

            this.targetTileService
              .getTileDataByType(
                this.type,
                RequestPayload.createRequest(x),
                this.isCloud
              )
              .then((y: TargetsTileResponse) => {
                if (isNumber(y)) {
                  throw new HttpErrorResponse({
                    status: y,
                  });
                }

                const definition: TargetsTileDefinition =
                  selectedDefinition ||
                  TargetsTileUtils.getDefinition(this.type);
                const params = new Dictionary<any>();
                params.add('projection', x.projection);
                params.add('plan', x.plan);
                params.add('allowPercentage', definition.allowPercentage);
                params.add('currency', x.currency);
                params.add('hasAttributesSelected', x.hasAttributesSelected());
                this.targetsChartConfig = new TargetsChartConfig({
                  allowPercentage: definition.allowPercentage,
                  isPercentage: definition.isPercentage,
                  tile: TargetsTileUtils.getItems(
                    definition,
                    this.type,
                    y,
                    params
                  ),
                });

                if (!selectedDefinition) {
                  this.getSessionSettings();
                }

                this.configChangeEvent.emit(this.targetsChartConfig);
              })
              .catch((error: HttpErrorResponse) => {
                this.handleTileError(error);
              })
              .finally(() => {
                this.loadCompleted();
              });
          })
        );
      })
    );
  }

  loadStarted(): void {
    this.loaded = false;
    this.baseChangeDetector.detectChanges();
  }

  loadCompleted(): void {
    this.loaded = true;
    this.baseChangeDetector.detectChanges();
  }

  handleTileError(error: HttpErrorResponse): void {
    this.hasErrors = true;

    switch (error.status as any) {
      case 504:
        this.errorMessage = TargetsTileErrorMessages.Service504;
        break;
      default:
        this.errorMessage = TargetsTileErrorMessages.ServiceDefault;
        break;
    }

    this.errorHandlerService.handleError(error);
    this.baseChangeDetector.detectChanges();
  }

  clearTile(): void {
    this.targetsChartConfig = null;
    this.configChangeEvent.emit(this.targetsChartConfig);
    this.hasErrors = false;
    this.errorMessage = '';
  }

  getMetricList(): void {
    this.metrics = TargetsTileUtils.getMetricList(
      this.isActuals,
      (x: TextValuePair) => {
        if (x.value === this.type) {
          this.selectedMetric = x;
        }
      }
    );
  }

  getSessionSettings(): void {
    const isPercentageString: string = StorageUtils.get(
      StorageType.SessionStorage,
      `targets-tile-percentage-${this.type}`
    );
    this.targetsChartConfig.isPercentage = isPercentageString
      ? isPercentageString === 'true'
      : this.targetsChartConfig.allowPercentage;
  }

  onChangeMetric(metric: TextValuePair): void {
    this.selectedMetric = metric;
    this.type = metric.value;
    this.initializeComponent();
  }

  onValueTypeSwitchChanged(isNumeric: boolean): void {
    this.targetsChartConfig.isPercentage = !isNumeric;
    this.configChangeEvent.emit(this.targetsChartConfig);

    if (this.targetsChartConfig.allowPercentage) {
      StorageUtils.set(
        StorageType.SessionStorage,
        `targets-tile-percentage-${this.type}`,
        this.targetsChartConfig.isPercentage
      );
    }
  }

  onOpenTrendModal(): void {
    // Boolean: isChartView
    this.openTrendModalEmitted.emit(false);
  }
}
