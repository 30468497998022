import {
  Component,
  AfterViewInit,
  Inject,
  ElementRef,
  ViewEncapsulation,
} from '@angular/core';
import { AppConfigService } from '../../../../core/services/app-config.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DOCUMENT, NgIf } from '@angular/common';
import { environment } from '../../../../../environments/environment';
@Component({
  selector: 'notificationframework-universal-header',
  template: `
    <div
      *ngIf="enable"
      id="acn-universal-header"
      [attr.data-widgetid]="component.WIDGET_ID"
      [attr.data-appid]="config.APP_ID"
      class="acn-auth-azure"
    ></div>
    <link *ngIf="enable" rel="stylesheet" [href]="CSS_SCRIPT" />
  `,
  styleUrls: ['./universal-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf],
})
export class UniversalHeaderComponent implements AfterViewInit {
  CSS_SCRIPT: SafeResourceUrl;
  config;
  component: Record<string, string>;
  enable;
  constructor(
    configService: AppConfigService,
    private sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef
  ) {
    this.config = (configService.config as Record<string, unknown>)
      .notification as Record<string, unknown>;
    this.component = this.config['universal-header'] as Record<string, string>;
    this.CSS_SCRIPT = sanitizer.bypassSecurityTrustResourceUrl(
      this.component.CSS_SCRIPT as string
    );
    this.enable = environment.providers !== 'mock';
  }

  ngAfterViewInit(): void {
    if (this.enable) {
      const nfs = this.document.createElement('script');
      nfs.type = 'text/javascript';
      nfs.src = this.component.SRC_SCRIPT;
      this.elementRef.nativeElement.appendChild(nfs);
    }
  }
}
