import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { isNumber } from 'lodash';
import { Router } from '@angular/router';

import Dictionary from 'src/app/core/utils/dictionary.utils';
import {
  WonCciPercentageResponse,
  WonCciResponse,
} from '../../entities/won-cci-response';
import { ProjectionTileComponent } from '../../projection-tile.component';
import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { RequestPayload } from 'src/app/shared/services/entities/request-payload';
import { TileUtils } from '../../../utils/tile.utils';
import { ProjectionTileConstants } from '../../constants/projection-tile.constants';
import {
  Periods,
  TimePeriodCodes,
} from 'src/app/shared/constants/filters.constants';

import { ProjectionTileService } from 'src/app/shared/services/tiles/projection-tile.service';
import { FiltersService } from 'src/app/shared/services/filters.service';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { BetaMessageService } from 'src/app/shared/services/beta-message.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { SpinnerComponent } from '../../../../base/spinner/spinner.component';
import { FeatureToggleComponent } from '../../../../feature-toggle/feature-toggle.component';
import { SwitchComponent } from '../../../../base/switch/switch.component';
import { NgIf, NgClass, NgFor } from '@angular/common';

@Component({
  selector: 'app-won-cci-tile',
  templateUrl: '../../projection-tile.component.html',
  styleUrls: ['../../projection-tile.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    SwitchComponent,
    FeatureToggleComponent,
    NgClass,
    NgFor,
    SpinnerComponent,
  ],
})
export class WonCciTileComponent
  extends ProjectionTileComponent
  implements OnInit
{
  isCloud: boolean;
  constructor(
    private projectionTileService: ProjectionTileService,
    private filterService: FiltersService,
    private errorHandlerService: ErrorHandlerService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    betaMessage: BetaMessageService,
    appStateService: AppStateService
  ) {
    super(
      projectionTileService,
      filterService,
      errorHandlerService,
      changeDetector,
      router,
      MetricType.WonCci,
      betaMessage,
      appStateService
    );
    this.isPercentage = true;
    this.allowPercentage = true;
  }

  ngOnInit(): void {
    super.initializeComponent((x: RequestPayload) => {
      super.setTempTitle(
        this.isActuals
          ? ProjectionTileConstants.WonCci.title.actuals
          : ProjectionTileConstants.WonCci.title.projection
      );

      this.betaMessage.isCloudSubject$.subscribe((betaFlag) => {
        this.isBetaTile = betaFlag;
        this.isCloud =
          (betaFlag && !this.isFlipFlopFlagEnabled) ||
          (!betaFlag && this.isFlipFlopFlagEnabled);

        super.loadStarted();
        super.clearTile();
        super.setTempItems(ProjectionTileConstants.WonCci.items);
        Promise.all([
          this.projectionTileService.getTileDataByType<WonCciPercentageResponse>(
            this.type,
            x,
            true,
            this.isCloud
          ),
          this.projectionTileService.getTileDataByType<WonCciResponse>(
            this.type,
            x,
            false,
            this.isCloud
          ),
        ])
          .then(([x1, x2]) => {
            if (isNumber(x1) || isNumber(x2)) {
              throw new HttpErrorResponse({
                status: isNumber(x1) ? x1 : Number(x2),
              });
            }

            const isSingleMonth: boolean = [
              TimePeriodCodes.CurrentMonth,
              TimePeriodCodes.MonthToDate,
            ].includes(this.selectedFilters.timeframe.code);

            const params1 = new Dictionary<any>();
            params1.add('allowPercentage', this.allowPercentage);
            params1.add('isPercentage', true);
            params1.add('isActuals', this.isActuals);
            params1.add(
              'period',
              isSingleMonth ? Periods.Month.id : Periods.Quarter.id
            );

            const params2 = new Dictionary<any>();
            params2.add('allowPercentage', this.allowPercentage);
            params2.add('isPercentage', false);
            params1.add('isActuals', this.isActuals);
            params2.add(
              'period',
              isSingleMonth ? Periods.Month.id : Periods.Quarter.id
            );

            this.projectionTile = TileUtils.combineTiles([
              TileUtils.getItems<WonCciPercentageResponse>(
                ProjectionTileConstants.WonCci,
                x1,
                params1
              ),
              TileUtils.getItems<WonCciResponse>(
                ProjectionTileConstants.WonCci,
                x2,
                params2
              ),
            ]);
          })
          .catch((error: HttpErrorResponse) => {
            super.handleTileError(error);
          })
          .finally(() => {
            super.loadCompleted();
          });
      });
    });
  }
}
