import { DatePipe, NgClass, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { DateUtils } from 'src/app/core/utils/date.utils';

import { GridCellItemAlign } from 'src/app/shared/constants/grid.constants';

@Component({
  selector: 'app-date-cell',
  templateUrl: './date-cell.component.html',
  styleUrls: ['./date-cell.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf],
})
export class DateCellComponent {
  params: any;
  data: Record<string, any> = {
    cellStyleTextAlign: GridCellItemAlign.Right,
    cellStyleFontWeight: 'normal',
    cellClass: '',
    dateFormat: new DatePipe('en-US'),
    value: '',
  };

  // hasIcon = false;
  // iconCursor = [];
  // iconStyle = [];
  // iconAction = [];
  // iconClass = [];
  // iconName = [];
  // iconTooltip = [];

  agInit(params: any): void {
    this.params = params;

    if (this.params) {
      // if (this.params.icon) {
      //   this.hasIcon = true;

      //   this.iconCursor = [];
      //   this.iconStyle = [];
      //   this.iconAction = [];
      //   this.iconClass = [];
      //   this.iconName = [];
      //   this.iconTooltip = [];

      //   this.params.icon.forEach((icon) => {
      //     if (icon.cursor && icon.cursor !== '') {
      //       this.iconCursor.push(icon.cursor);
      //     }
      //     if (icon.action && icon.action !== '') {
      //       this.iconAction.push(icon.action);
      //     }
      //     if (icon.class && icon.class !== '') {
      //       this.iconClass.push(icon.class);
      //     }
      //     if (icon.name) {
      //       this.iconName.push(CellUtils.decodeIcon(icon.name));
      //       this.iconTooltip.push(CellUtils.decodeTooltip(icon.name));
      //     }
      //   });
      // }

      if (this.params.align) {
        this.data.cellStyleTextAlign = this.params.align;
      }

      if (this.params.isBold) {
        this.data.cellStyleFontWeight = 'bold';
      }

      if (this.params.cellClass) {
        this.data.cellClass = this.params.cellClass;
      }

      this.data.value = this.format(params.value);
    }

    if (this.data.value === '-') {
      this.data.cellStyleTextAlign = GridCellItemAlign.Center;
    }
  }

  private format(value: any): string {
    const result: string = this.formatDate(value);

    if (!result) {
      return '-';
    }

    return result;
  }

  private formatDate(value: any): string {
    if (!value) {
      return undefined;
    } else {
      const date = new Date(value);

      if (!isNaN(date.getTime())) {
        return this.data.dateFormat.transform(
          DateUtils.normalizeDate(date),
          'MM/dd/yy'
        );
      } else {
        return undefined;
      }
    }
  }
}
