import { Component, Input, ViewEncapsulation } from '@angular/core';

import { TabConfig } from './entities/tab-config';
import { TabsControlType } from './entities/tab.constants';
import { SwitchComponent } from '../../../../../base/switch/switch.component';
import { DropDownComponent } from '../../../../../base/drop-down/drop-down.component';
import { DropDownMultipleComponent } from '../../../../../base/drop-down-multiple/drop-down-multiple.component';
import { NgIf, NgFor, NgSwitch, NgSwitchCase } from '@angular/common';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    DropDownMultipleComponent,
    DropDownComponent,
    SwitchComponent,
  ],
})
export class TabsComponent {
  @Input() config: TabConfig;

  public get controlType(): typeof TabsControlType {
    return TabsControlType;
  }
}
