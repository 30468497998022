import { Component } from '@angular/core';
import { BetaMessageService } from 'src/app/shared/services/beta-message.service';
import { AnalyticsGridComponent } from './components/analytics-grid/analytics-grid.component';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
  standalone: true,
  imports: [AnalyticsGridComponent],
})
export class AnalyticsComponent {
  isCloud: boolean;
}
