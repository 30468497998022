import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { isNull, isUndefined } from 'lodash';
import { ValueType } from 'src/app/shared/constants/common.constants';
import { CustomNumberPipe } from 'src/app/shared/pipes/custom-number.pipe';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'app-totals-row',
  templateUrl: './totals-row.component.html',
  styleUrls: ['./totals-row.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf],
})
export class TotalsRowComponent implements ICellRendererAngularComp {
  params: any;
  data: Record<string, any> = {
    roundFloor: false,
    roundDecimal: false,
    hideTotals: false,
    thousands: false,
    numberFormat: new CustomNumberPipe(),
    cellClass: '',
    align: '',
  };

  // hasIcon = false;
  // iconCursor = [];
  // iconStyle = [];
  // iconAction = [];
  // iconClass = [];
  // iconName = [];
  // iconTooltip = [];

  agInit(params: any): void {
    this.params = params;

    // if (this.params.icon) {
    //   this.hasIcon = true;

    //   this.iconCursor = [];
    //   this.iconStyle = [];
    //   this.iconAction = [];
    //   this.iconClass = [];
    //   this.iconName = [];
    //   this.iconTooltip = [];
    //   this.params.icon.forEach((icon) => {
    //     if (icon.cursor && icon.cursor !== '') {
    //       this.iconCursor.push(icon.cursor);
    //     }
    //     if (icon.action && icon.action !== '') {
    //       this.iconAction.push(icon.action);
    //     }
    //     if (icon.class && icon.class !== '') {
    //       this.iconClass.push(icon.class);
    //     }
    //     if (icon.name) {
    //       this.iconName.push(CellUtils.decodeIcon(icon.name));
    //       this.iconTooltip.push(CellUtils.decodeTooltip(icon.name));
    //     }
    //   });
    // }

    if (this.params.rowIndex === 0) {
      this.data.cellClass = 'totals-cell';
    }

    if (this.params.cellClass) {
      this.data.cellClass += params.cellClass;
    }

    if (this.params.round) {
      this.data.roundDecimal = params.round;
    }

    if (this.params.round) {
      this.data.roundFloor = params.round;
    }

    if (this.params.thousands) {
      this.data.thousands = params.thousands;
    }

    if (this.params.showTotalDash) {
      this.data.showTotalDash = params.showTotalDash;
    }

    if (this.params.align) {
      this.data.align = params.align;
    }

    if (
      (isNull(this.params.value) || isUndefined(this.params.value)) &&
      this.params.forceTotals
    ) {
      this.params.value = 'TOTALS';
    }
  }

  refresh(): boolean {
    return false;
  }

  format(value: any): string {
    switch (this.params.colDef.type) {
      case 'percentColumn':
        return this.formatPercent(value);
      case 'numberColumn':
        return this.formatNumber(value);
      default:
        return this.params.showTotalDash || this.params.forceTotals
          ? value
          : null;
    }
  }

  private formatNumber(value: any): string {
    value = this.data.thousands ? value / 1000 : value;
    value = this.data.roundFloor ? Math.floor(value) : value;
    return this.data.numberFormat.transform(value, ValueType.Numeric, '-');
  }

  private formatPercent(value: any): string {
    value = this.data.roundDecimal ? Math.round(value) : value;
    return this.data.numberFormat.transform(
      value / 100,
      ValueType.Percentage,
      '-'
    );
  }
}
