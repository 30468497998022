<nav class="app-navbar">
    <div class="app-navbar-wrapper">
        <div class="app-navbar-left">
            <div class="navbar-tab">
                <ng-container *ngFor="let t of tabs">
                    <a [attr.id]="t.id" [routerLink]="[t.url]"
                        [routerLinkActive]="isCurrentTabBeta ? [''] : ['navbar-tab-active']"
                        [ngClass]="{'navbar-tab-disabled' : !t.enable, 'navbar-sub': (t.id === 'ieopportunity' && updateClass)}"
                        (click)="changeSelectedTab(t, false)">{{t.text.toUpperCase()}}</a>
                </ng-container>
                <a [attr.id]="'betaTab'" *ngIf="isCurrentTabBeta" [routerLinkActive]="['navbar-tab-active']"
                    class="navbar-tab-active">{{ selectedTab.text.toUpperCase()}}
                    <span class="beta-icon"><app-mmb-feature-toggle></app-mmb-feature-toggle></span>
                </a>
            </div>

            <div class="custom-dropdown" *ngIf="isBetaUser">
                <span class="selected-option" (click)="toggleDropdown()">
                    <span class="select-option-text" *ngIf="isCurrentTabBeta">{{ selectedTab.text.toUpperCase() }}</span>
                    <span class="select-option-text" *ngIf="!isCurrentTabBeta">{{ 'OVERVIEW'}}
                    </span>
                    <span class="down-arrow"></span>
                    <div class="beta-icon"><app-mmb-feature-toggle></app-mmb-feature-toggle></div>

                </span>
                <ul class="options-list" [ngClass]="{'show': dropdownOpen}">
                    <li *ngFor="let t of tabs" [attr.id]="t.id" [routerLink]="[t.url]"
                        (click)="changeSelectedTab(t, true)">{{t.text.toUpperCase()}}
                        <app-mmb-feature-toggle></app-mmb-feature-toggle>
                    </li>
                </ul>
            </div>
        </div>
        <div class="app-navbar-right">
            <app-timeframe-picker></app-timeframe-picker>
            <app-currency-picker></app-currency-picker>
            <app-data-refresh></app-data-refresh>
            <a class="btn btn-link btn-singOut" (click)="logout()">
                <fa-icon [icon]="faSignOutAlt">
                </fa-icon>
            </a>
        </div>
    </div>
</nav>
<nav class="app-subnavbar" *ngIf="selectedTab && selectedTab.subtabs?.length > 0">
    <div class="app-subnavbar-wrapper">
        <div class="app-subnavbar-left">
            <div class="navbar-tab" *ngFor="let t of selectedTab.subtabs">
                <a [attr.id]="t.id" [routerLink]="[selectedTab.url, t.url]" [routerLinkActive]="['navbar-tab-active']"
                    (click)="updateClass = true">{{t.text}}</a>
            </div>
        </div>
    </div>
</nav>