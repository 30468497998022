import { ColDef, CsvExportParams, GridOptions } from 'ag-grid-community';
import {
  capitalize,
  cloneDeep,
  has,
  isNull,
  isNumber,
  isString,
  isUndefined,
  maxBy,
  minBy,
} from 'lodash';
import { GridUtils } from 'src/app/shared/components/base/grid/utils/grid.utils';
import { TimeframeItem } from 'src/app/shared/components/timeframe/entities/timeframe';
import {
  ActiveDates,
  SubMetrics,
  TimePeriodCodes,
} from 'src/app/shared/constants/filters.constants';
import { GridCellItemAlign } from 'src/app/shared/constants/grid.constants';
import { ActiveDate } from 'src/app/shared/services/entities/filters/active-date';
import { CustomerFilters } from 'src/app/shared/services/entities/filters/customer-filters';
import { MmbDate } from 'src/app/shared/services/entities/filters/date';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { Timeframe } from 'src/app/shared/services/entities/filters/timeframe';
import { SpeculativePhasingPeriod } from 'src/app/shared/services/entities/grids/speculative-response';
import { Speculative } from '../../../entities/speculative';
import { SpeculativeEditGridConfig } from '../entities/speculative-edit-grid-config';
import { StringCellComponent } from 'src/app/shared/components/base/grid/components/cell-renderers/string-cell/string-cell.component';
import { NumericCellComponent } from 'src/app/shared/components/base/grid/components/cell-renderers/numeric-cell/numeric-cell.component';
import { PercentageCellComponent } from 'src/app/shared/components/base/grid/components/cell-renderers/percentage-cell/percentage-cell.component';

export const GridColDefs = {
  getColDefs: (
    filters: Filters,
    selectedFilters: SelectedFilters,
    customerFilters: CustomerFilters
  ): Array<ColDef> => {
    const columnDefs: Array<ColDef> = GridColDefs.Shared.getBaseGridColDefs();

    return columnDefs.concat(
      GridColDefs.Shared.getPeriodColDefs(filters, selectedFilters)
    );
  },
  Shared: {
    getBaseGridColDefs: (): Array<ColDef> => {
      return [
        // children: [
        // {
        {
          headerName: 'Group',
          minWidth: 50,
          width: 50,
          field: 'group',
          rowGroup: true,
          hide: true,
          menuTabs: [],
          cellRendererSelector: GridColDefs.Shared.getRenderer,
          cellRendererParams: (params: any) => ({
            align: GridCellItemAlign.Center,
          }),
          groupDisplayType: 'groupRows',
        },
        {
          headerName: '',
          minWidth: 190,
          width: 190,
          field: 'name',
          menuTabs: [],
          cellRenderer: StringCellComponent,
          cellRendererParams: (params: any) => ({
            align: GridCellItemAlign.Left,
          }),
          groupDisplayType: 'groupRows',
          cellClassRules: GridColDefs.Shared.stringCellClassRules,
        },

        // ],

        // },
        {
          headerName: 'Total',
          minWidth: 80,
          width: 80,
          field: 'total',
          //pinned: true,
          cellRendererSelector: GridColDefs.Shared.getRenderer,
          menuTabs: [],
          cellRendererParams: (params: any) => {
            return {
              align: GridCellItemAlign.Right,
              // cellClass: {
              //   excelNumberFormat: params.data.group !== 'CCI%',
              //   excelPercentFormat: params.data.group === 'CCI%',
              // },
              nullSymbol: GridColDefs.Shared.isEditable(params) ? '0' : '-',
            };
          },
          cellClassRules: GridColDefs.Shared.customCellClassRules,
        },
        {
          headerName: 'Variance',
          minWidth: 80,
          width: 80,
          field: 'variance',
          //pinned: true,
          cellRendererSelector: GridColDefs.Shared.getRenderer,
          menuTabs: [],
          cellRendererParams: (params: any) => {
            return {
              align: GridCellItemAlign.Right,
              nullSymbol: GridColDefs.Shared.isEditable(params) ? '0' : '-',
              // cellClass: {
              //   excelNumberFormat: params.data.group !== 'CCI%',
              //   excelPercentFormat: params.data.group === 'CCI%',
              // },
            };
          },
          cellClassRules: GridColDefs.Shared.customCellClassRules,
        },
      ] as Array<ColDef>;
    },
    getPeriodColDefs: (
      filters: Filters,
      selectedFilters: SelectedFilters
    ): Array<ColDef> => {
      const appDate: ActiveDate = filters.activeDates.find(
        (x: ActiveDate) => x.DateTypeCd === ActiveDates.ApplicationDate
      );

      const isNextFY: boolean =
        selectedFilters.timeframe.code === TimePeriodCodes.NextFY;

      const nextFyTimeframe: Timeframe = filters.timeframe.find(
        (x: Timeframe) => x.TimePeriodCode === TimePeriodCodes.NextFY
      );

      const timeframe: TimeframeItem = cloneDeep(selectedFilters.timeframe);
      timeframe.start = appDate.StartTimeId;
      timeframe.end = appDate.StartTimeId + 23;

      const columnDefs: Array<ColDef> = GridColDefs.Shared.groupByFiscalYear(
        GridColDefs.Shared.addCustomRendererConfigs(
          GridUtils.getMonthlyHeaders(timeframe, filters.dates)
        ),
        isNextFY,
        nextFyTimeframe
      );

      columnDefs.push({
        headerName: 'BEYOND',
        colId: (appDate.StartTimeId + 24).toString(),
        minWidth: 100,
        width: 100,
        field: 'beyond',
        menuTabs: [],
        editable: GridColDefs.Shared.isEditable,
        cellEditor: 'numericEditor',
        cellRendererSelector: GridColDefs.Shared.getRenderer,
        cellRendererParams: (params: any) => {
          return {
            align: GridCellItemAlign.Right,
            nullSymbol: GridColDefs.Shared.isEditable(params) ? '0' : '-',
          };
        },
        cellClassRules: GridColDefs.Shared.customCellClassRules,
      });

      return columnDefs;
    },
    groupByFiscalYear: (
      colDefs: Array<ColDef>,
      isNextFY: boolean,
      nextFY: Timeframe
    ): Array<ColDef> => {
      let finalColumns: Array<ColDef> = colDefs;

      if (isNextFY) {
        finalColumns = [];

        const groupedCols: Record<string, any> = {
          headerName: '',
          children: [],
        };

        groupedCols.children.push({
          columnGroupShow: 'closed',
          headerName: 'Prior',
          minWidth: 100,
          field: 'prior',
          width: 100,
          cellRendererSelector: GridColDefs.Shared.getRenderer,
          menuTabs: [],
          pinned: true,
          editable: false,
          cellRendererParams: (params: any) => {
            return {
              align: GridCellItemAlign.Right,
              nullSymbol: GridColDefs.Shared.isEditable(params) ? '0' : '-',
            };
          },
          cellClassRules: GridColDefs.Shared.customCellClassRules,
        });

        colDefs.forEach((x: ColDef) => {
          if (Number(x.field) < nextFY.StartTimeId) {
            x.columnGroupShow = 'open';
            groupedCols.children.push(x);
          } else {
            finalColumns.push(x);
          }
        });

        finalColumns.unshift(groupedCols);
      }

      return finalColumns;
    },
    addCustomRendererConfigs: (colDefs: Array<ColDef>): Array<ColDef> => {
      colDefs = colDefs.map((x: ColDef) => ({
        ...x,
        headerName: capitalize(x.headerName),
        minWidth: 100,
        width: 100,
        field: x.colId,
        editable: GridColDefs.Shared.isEditable,
        cellEditor: 'numericEditor',
        cellRendererSelector: GridColDefs.Shared.getRenderer,
        menuTabs: [],
        cellRendererParams: (params: any) => {
          return {
            align: GridCellItemAlign.Right,
            nullSymbol: GridColDefs.Shared.isEditable(params) ? '0' : '-',
          };
        },
        cellClassRules: GridColDefs.Shared.customCellClassRules,
      }));

      return colDefs;
    },
    getRenderer: (params: any): Record<string, any> => {
      let rendererSelection: Record<string, any> = null;

      if (params.colDef.field === 'name' || params.colDef.field === 'group') {
        rendererSelection = {
          component: 'stringCellRenderer',
        };
      } else if (params.node.parent.key !== 'CCI%') {
        rendererSelection = {
          component: NumericCellComponent,
        };
      } else {
        rendererSelection = {
          component: PercentageCellComponent,
        };
      }

      return rendererSelection;
    },
    isEditable: (params: any): boolean => {
      const config: SpeculativeEditGridConfig = params.context.config;
      const appDate: ActiveDate = config.filters.activeDates.find(
        (x: ActiveDate) => x.DateTypeCd === ActiveDates.ApplicationDate
      );
      const cfy: number = config.filters.dates.find(
        (x: MmbDate) => x.Id === appDate.StartTimeId
      ).FiscalYearNbr;
      const nfyDates: Array<MmbDate> = config.filters.dates.filter(
        (x: MmbDate) => x.FiscalYearNbr === cfy + 1
      );
      const startNfy: MmbDate = minBy(nfyDates, 'Id');
      const endNfy: MmbDate = maxBy(nfyDates, 'Id');

      const result: boolean =
        params?.node?.parent?.key !== 'CCI%' &&
        !params?.data?.name.includes(SubMetrics.Projection) &&
        !(params?.data?.name.includes('Spec') && params?.data?.total === 0) &&
        (!config.isReadOnly || config.isAdmin) &&
        !(
          config.speculative.reasonCode &&
          config.speculative.reasonCode.ImpactedPlan &&
          (params.colDef.colId < startNfy.Id ||
            params.colDef.colId > endNfy.Id ||
            params.colDef.field === 'beyond')
        );

      return result;
    },
    isIncorrectValue: (params: any): boolean => {
      return !isNull(params?.value) && !isUndefined(params?.value)
        ? isNumber(params?.value)
          ? false
          : isNaN(Number(params?.value?.replace(/,/, '')))
        : false;
    },
    isString: (params: any): boolean => {
      return !isNull(params?.value) && !isUndefined(params?.value)
        ? isString(params?.value)
          ? true
          : false
        : true;
    },
    isEditedValue: (params: any, source: Speculative): boolean => {
      let result = false;
      if (
        !GridColDefs.Shared.isIncorrectValue(params) &&
        !params?.data?.name.includes(SubMetrics.Projection)
      ) {
        if (params.node.originalRowData) {
          result = has(params.node.originalRowData, params.colDef.colId)
            ? params.node.originalRowData[params.colDef.colId] !== params.value
            : params.value !== 0;
        } else if (
          source &&
          source.phasingMonthly.some(
            (x: SpeculativePhasingPeriod) =>
              x.timeId.toString() === params.colDef.colId
          )
        ) {
          result =
            source.phasingMonthly.find(
              (x: SpeculativePhasingPeriod) =>
                x.timeId.toString() === params.colDef.colId
            )[params?.data?.metric] !== params.value;
        }
      }
      return result;
    },
    customCellClassRules: {
      excelNumberFormat: (params: any) => {
        return true;
      },
      'editable-cell': (params: any) => {
        return GridColDefs.Shared.isEditable(params);
      },
      'disabled-cell': (params: any) => {
        return !GridColDefs.Shared.isEditable(params);
      },
      error: (params: any) => {
        return GridColDefs.Shared.isIncorrectValue(params);
      },
      edited: (params: any) => {
        const config: SpeculativeEditGridConfig = params.context.config;
        return GridColDefs.Shared.isEditedValue(params, config.speculative);
      },
    },
    stringCellClassRules: {
      excelNumberFormat: (params: any) => {
        return true;
      },
      'disabled-cell': (params: any) => {
        return !GridColDefs.Shared.isEditable(params);
      },
    },
  },
  getExtraGridOptions: (speculative: Speculative): GridOptions => {
    return {
      groupDisplayType: 'groupRows',
      defaultExportParams:
        speculative?.id !== ''
          ? ({
              sheetName: 'CBP Phasing (thousands)',
              fileName: `${speculative.id} - ${speculative.name}.xls`,
            } as CsvExportParams)
          : ({
              sheetName: 'CBP Phasing (thousands)',
              fileName: 'New Speculative',
            } as CsvExportParams),
      groupUseEntireRow: true,
      domLayout: 'autoHeight',
      headerHeight: 40,
      groupDefaultExpanded: 1,
      enableSorting: false,
      enableFilter: false,
    } as GridOptions;
  },
};
