import { assign } from 'lodash';

import { ColDef, GridOptions } from 'ag-grid-community';
import { GridObservables } from './grid-observables';
import { GridUtils } from '../utils/grid.utils';

class GridConfigSchema {
  columns: Array<ColDef>;
  options?: GridOptions;
  features?: GridConfigFeatures;
  observables?: GridObservables;
  expandable?: boolean = true;
}

export class GridConfig extends GridConfigSchema {
  constructor(filterAttributes?: GridConfigSchema) {
    super();
    assign(this, filterAttributes);

    if (filterAttributes) {
      this.columns = filterAttributes.columns ? filterAttributes.columns : [];
      this.options = assign(
        {},
        GridUtils.getDefaultOptions(),
        filterAttributes.options
      );
    }
  }
}

export class GridConfigFeaturesSchema {
  hasTotalsRow?: boolean;
  autoSizeOnChange?: boolean;
  // removeDirective?: boolean;
  // defaultFilter?: string;
  // keepSearch?: boolean;
}

export class GridConfigFeatures extends GridConfigFeaturesSchema {
  constructor(filterAttributes?: GridConfigFeaturesSchema) {
    super();
    assign(this, filterAttributes);
  }
}

export class DefaultColumnTypesSchema {
  [key: string]: ColDef;
}

export class DefaultColumnTypes extends DefaultColumnTypesSchema {
  constructor(filterAttributes?: DefaultColumnTypesSchema) {
    super();
    assign(this, filterAttributes);
  }
}

export class SortColumSchama {
  colId: string;
  sort: string;
}

export class SortColum extends SortColumSchama {
  constructor(filterAttributes?: SortColumSchama) {
    super();
    assign(this, filterAttributes);
  }
}
