import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';

import { FilterType } from 'src/app/shared/constants/filters.constants';
import { Function } from 'src/app/shared/services/entities/filters/product-hierarchy';
import { TreeViewConfig } from '../../../base/tree-view/entities/tree-view-config';
import { TreeViewItem } from '../../../base/tree-view/entities/tree-view-item';
import { TreeViewSelection } from '../../../base/tree-view/entities/tree-view-selection';
import { TreeViewUtils } from '../../../base/tree-view/utils/tree-view.utils';
import { FilterSelection } from '../../entities/filter-selection';
import { FilterSelectionKeyValue } from '../../entities/filter-selection-key-value';
import { TreeViewComponent } from '../../../base/tree-view/tree-view.component';
import { SelectionControlComponent } from '../../../base/selection-control/selection-control.component';
import { SearchInputComponent } from '../../../base/search-input/search-input.component';

@Component({
  selector: 'app-functions',
  templateUrl: './functions.component.html',
  styleUrls: ['./functions.component.scss'],
  standalone: true,
  imports: [SearchInputComponent, SelectionControlComponent, TreeViewComponent],
})
export class FunctionsComponent implements OnChanges {
  @Input() functions: Array<Function> = [];
  @Input() selectedFunctions: Array<Function> = [];
  @Input() searchText: string;
  @Output() itemSelectionChangeEvent = new EventEmitter<FilterSelection>();
  @Output() searchEvent = new EventEmitter<string>();
  selectAllChangedEvent = new Subject<boolean>();

  functionsTreeviewConfig: TreeViewConfig;
  startSelection: TreeViewSelection;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.functions && changes.functions.currentValue) {
      let items: Array<TreeViewItem> = TreeViewUtils.getItems<Function>(
        this.functions,
        'ProductId',
        'ProductName',
        'ParentProductId'
      );

      let filteredItems: Array<TreeViewItem> = [];
      items.forEach((x: TreeViewItem) => {
        if (
          (x && x.text === 'Functions') ||
          (x && x.text === 'zInactive Functions')
        ) {
          filteredItems = filteredItems.concat(x.children);
        } else {
          filteredItems.push(x);
        }
      });

      items = filteredItems;

      this.functionsTreeviewConfig = new TreeViewConfig({
        parentAttr: 'ParentProductId',
        textAttr: 'ProductName',
        valueAttr: 'ProductId',
        countByNodes: true,
        selectByNodes: true,
        items,
      });
    }

    if (changes.selectedFunctions && changes.selectedFunctions.currentValue) {
      this.startSelection = new TreeViewSelection({
        selectedItems: this.selectedFunctions.map(
          (x: Function) =>
            new TreeViewItem({
              value: x.ProductId,
              text: x.ProductName,
            })
        ),
      });
    }
  }

  onSearchTextChanged(searchText: string): void {
    this.searchText = searchText;
    this.searchEvent.emit(this.searchText);
  }

  onItemSelection(selectedFunctions: TreeViewSelection): void {
    this.itemSelectionChangeEvent.emit(
      new FilterSelection({
        type: FilterType.Function,
        keyValues: selectedFunctions.selectedItems.map(
          (x: TreeViewItem) =>
            new FilterSelectionKeyValue({
              key: x.value,
              value: x.text,
            })
        ),
        count: selectedFunctions.count,
        blockedFilters: [FilterType.Alliance, FilterType.GrowthPriority],
      })
    );
  }

  onAllSelectionChanged(selectAll: boolean): void {
    this.selectAllChangedEvent.next(selectAll);
  }
}
