import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';

import {
  CustomCurrencies,
  GroupsBy,
  Periods,
  SubMetrics,
} from 'src/app/shared/constants/filters.constants';
import { ValueType } from 'src/app/shared/constants/common.constants';
import {
  TrendTable,
  TrendTableRecord,
  TrendTableSet,
} from '../../../entities/trend-table-data';
import { TrendTableUtils } from '../../../utils/trend-table.utils';
import { TrendTableConfig } from './entities/trend-table-config';
import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { CustomNumberPipe } from '../../../../../pipes/custom-number.pipe';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
  selector: 'app-trend-table',
  templateUrl: './trend-table.component.html',
  styleUrls: ['./trend-table.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, NgClass, CustomNumberPipe],
})
export class TrendTableComponent implements OnChanges {
  @Input() config: TrendTableConfig;
  @Output() closeModalEvent = new EventEmitter<void>();

  public get valueType(): typeof ValueType {
    return ValueType;
  }

  trendTable: TrendTable;

  constructor(private router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config && changes.config.currentValue) {
      this.renderTable();
    }
  }

  renderTable(): void {
    this.trendTable = TrendTableUtils.getTableData(
      this.config.definition,
      this.config.response,
      this.config.trendFilters,
      this.config.filters.dates
    );
    if (this.config.trendFilters.groupBy.value === GroupsBy.ServiceGroup.id) {
      this.sortByServiceGroup();
    }

    this.validateData();
  }

  isQuarterly(): boolean {
    return this.config.trendFilters.period.value === Periods.Quarter.id;
  }

  validateData(): void {
    const removePlan: boolean =
      this.config.trendFilters.groupBy.value === GroupsBy.Industry.id ||
      (this.config.selectedFilters.currency.Id ==
        CustomCurrencies.FxAdjusted.id &&
        this.config.definition.type === MetricType.Cci) ||
      this.config.selectedFilters.hasAttributesSelected();

    const removeProjection: boolean = [
      CustomCurrencies.FxAdjusted.id,
      CustomCurrencies.Constant.id,
    ].includes(this.config.selectedFilters.currency.Id);

    if (
      (removeProjection &&
        this.config.trendFilters.subMetric.value === SubMetrics.Projection) ||
      (removePlan &&
        this.config.trendFilters.subMetric.value === SubMetrics.Plan)
    ) {
      this.trendTable.sets
        .filter((x) => x.title !== SubMetrics.Projection)
        .forEach((x: TrendTableSet) => {
          this.removeTableSet(x);
        });
    }
  }

  removeTableSet(set: TrendTableSet): void {
    set.records.forEach((x: TrendTableRecord) => {
      x.valuesByPeriod = x.valuesByPeriod.map((x: number) => null);
      x.total = null;
    });

    set.totalsByPeriod = set.totalsByPeriod.map((x: number) => null);
    set.total = null;
  }

  onLinkClicked(record: TrendTableRecord): void {
    if (record.link) {
      this.router.navigate([record.link], { queryParams: record.linkParams });

      this.closeModalEvent.emit();
    }
  }

  sortByServiceGroup(): void {
    const order: any = { CON: 1, SC: 2, SI: 3, AMS: 4, IMS: 5, BPMS: 6 };
    this.trendTable.sets.forEach((x: TrendTableSet) => {
      x.records.sort((a, b) => order[a.title] - order[b.title]);
    });
  }
}
