import { Inject, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AppState } from '../services/entities/app-state/app-state';
import { StorageUtils } from 'src/app/core/utils/storage.utils';
import {
  StorageKeys,
  StorageType,
} from 'src/app/core/constants/storage.constants';

import { AppStateService } from '../services/app-state.service';
import { RebarAuthService } from 'src/app/core/rebarauth/rebar.auth.service';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceModeGuard implements OnDestroy {
  subscription = new Subscription();

  constructor(
    @Inject('Window') private window: Window,
    private appStateService: AppStateService,
    private authService: RebarAuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (route.queryParams.overrideCode) {
      StorageUtils.set(
        StorageType.SessionStorage,
        StorageKeys.OVERRIDE_CODE,
        route.queryParams.overrideCode
      );
    }

    const overrideCode: string = StorageUtils.get(
      StorageType.SessionStorage,
      StorageKeys.OVERRIDE_CODE
    );

    if (this.authService.isUserAuthenticated()) {
      return this.appStateService.getAppState(overrideCode).pipe(
        switchMap((x: AppState) => {
          StorageUtils.set(
            StorageType.SessionStorage,
            StorageKeys.OVERRIDE_VALID,
            x.overrideValid
          );
          if (x.maintenanceModeEnabled && x.overrideValid) {
            return of(true);
          } else if (x.maintenanceModeEnabled) {
            this.window.location.href = './assets/gonefishing.html';
            return of(false);
          }
          return of(true);
        })
      );
    } else {
      return of(false);
    }
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.canActivate(next, state);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
