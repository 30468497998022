import { ChangeDetectorRef, Component, OnInit, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { isNumber } from 'lodash';
import { Router } from '@angular/router';

import Dictionary from 'src/app/core/utils/dictionary.utils';
import {
  CciPercentageResponse,
  CciResponse,
} from '../../entities/cci-response';
import { ProjectionTileComponent } from '../../projection-tile.component';
import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { RequestPayload } from 'src/app/shared/services/entities/request-payload';
import { TileUtils } from '../../../utils/tile.utils';
import { ProjectionTileConstants } from '../../constants/projection-tile.constants';
import {
  Periods,
  TimePeriodCodes,
} from 'src/app/shared/constants/filters.constants';

import { ProjectionTileService } from 'src/app/shared/services/tiles/projection-tile.service';
import { FiltersService } from 'src/app/shared/services/filters.service';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { Subscription } from 'rxjs';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { MetricsOptions } from 'src/app/shared/constants/grid.constants';
import { BetaMessageService } from 'src/app/shared/services/beta-message.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { SpinnerComponent } from '../../../../base/spinner/spinner.component';
import { FeatureToggleComponent } from '../../../../feature-toggle/feature-toggle.component';
import { SwitchComponent } from '../../../../base/switch/switch.component';
import { NgIf, NgClass, NgFor } from '@angular/common';

@Component({
  selector: 'app-cci-tile',
  templateUrl: '../../projection-tile.component.html',
  styleUrls: ['../../projection-tile.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    SwitchComponent,
    FeatureToggleComponent,
    NgClass,
    NgFor,
    SpinnerComponent,
  ],
})
export class CciTileComponent
  extends ProjectionTileComponent
  implements OnInit
{
  subscription: Subscription;
  isCloud: boolean;

  constructor(
    private projectionTileService: ProjectionTileService,
    private filterService: FiltersService,
    private errorHandlerService: ErrorHandlerService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    betaMessage: BetaMessageService,
    appStateService: AppStateService
  ) {
    super(
      projectionTileService,
      filterService,
      errorHandlerService,
      changeDetector,
      router,
      MetricType.Cci,
      betaMessage,
      appStateService
    );
    this.isPercentage = true;
    this.allowPercentage = true;
  }

  ngOnInit(): void {
    this.subscription.add(
      this.projectionTileService.metricChanged.subscribe((x: TextValuePair) => {
        switch (x.value) {
          case MetricsOptions.Cci:
            this.isPercentage = false;
            break;
          case MetricsOptions.CciPercentage:
            this.isPercentage = true;
            break;
          default:
            break;
        }
      })
    );

    super.initializeComponent((x: RequestPayload) => {
      super.setTempTitle(
        this.isActuals
          ? ProjectionTileConstants.Cci.title.actuals
          : ProjectionTileConstants.Cci.title.projection
      );

      this.betaMessage.isCloudSubject$.subscribe((betaFlag) => {
        this.isBetaTile = betaFlag;
        this.isCloud =
          (betaFlag && !this.isFlipFlopFlagEnabled) ||
          (!betaFlag && this.isFlipFlopFlagEnabled);

        super.loadStarted();
        super.clearTile();
        super.setTempItems(ProjectionTileConstants.Cci.items);
        Promise.all([
          this.projectionTileService.getTileDataByType<CciPercentageResponse>(
            this.type,
            x,
            true,
            this.isCloud
          ),
          this.projectionTileService.getTileDataByType<CciResponse>(
            this.type,
            x,
            false,
            this.isCloud
          ),
        ])
          .then(([x1, x2]) => {
            if (isNumber(x1) || isNumber(x2)) {
              throw new HttpErrorResponse({
                status: isNumber(x1) ? x1 : Number(x2),
              });
            }

            const isSingleMonth: boolean = [
              TimePeriodCodes.CurrentMonth,
              TimePeriodCodes.MonthToDate,
            ].includes(this.selectedFilters.timeframe.code);

            const params1 = new Dictionary<any>();
            params1.add('metric', this.type);
            params1.add('allowPercentage', this.allowPercentage);
            params1.add('isPercentage', true);
            params1.add('isActuals', this.isActuals);
            params1.add(
              'period',
              isSingleMonth ? Periods.Month.id : Periods.Quarter.id
            );

            const params2 = new Dictionary<any>();
            params2.add('metric', this.type);
            params2.add('allowPercentage', this.allowPercentage);
            params2.add('isPercentage', false);
            params2.add('isActuals', this.isActuals);
            params2.add(
              'period',
              isSingleMonth ? Periods.Month.id : Periods.Quarter.id
            );

            this.projectionTile = TileUtils.combineTiles([
              TileUtils.getItems<CciPercentageResponse>(
                ProjectionTileConstants.Cci,
                x1,
                params1
              ),
              TileUtils.getItems<CciResponse>(
                ProjectionTileConstants.Cci,
                x2,
                params2
              ),
            ]);
          })
          .catch((error: HttpErrorResponse) => {
            super.handleTileError(error);
          })
          .finally(() => {
            super.loadCompleted();
          });
      });
    });
  }
}
