import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { NgbDropdown, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { Customer } from '../../services/entities/filters/customer';
import { SelectedFilters } from '../../services/entities/filters/selected-filters';

import { FiltersService } from '../../services/filters.service';

import { faCheckCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FilterArrayPipe } from '../../pipes/filter-array.pipe';
import { NgIf } from '@angular/common';
import { VirtualListDropdownComponent } from '../base/virtual-list-dropdown/virtual-list-dropdown.component';
import { SearchInputComponent } from '../base/search-input/search-input.component';
import { SharedModule } from 'src/app/shared-module';

@Component({
  selector: 'app-customer-picker',
  templateUrl: './customer-picker.component.html',
  styleUrls: ['./customer-picker.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    SearchInputComponent,
    VirtualListDropdownComponent,
    NgIf,
    FilterArrayPipe,
  ],
})
export class CustomerPickerComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('customerDropdown', { static: false })
  customerDropdown: NgbDropdown;

  subscription = new Subscription();

  loadCompleted: boolean;
  customers: Array<Customer>;
  selectedCustomer: Customer;
  searchText: string;
  refreshEvent = new Subject<void>();

  faCheckCircle = faCheckCircle;
  faSearch = faSearch;

  constructor(private filtersService: FiltersService) {}

  ngAfterViewInit(): void {
    this.subscription.add(
      this.customerDropdown.openChange.subscribe((x: boolean) => {
        if (!x) {
          this.refreshEvent.next();
        }
      })
    );
  }

  ngOnInit(): void {
    this.getCustomers();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getCustomers(): void {
    this.subscription.add(
      combineLatest([
        this.filtersService.customersChanged,
        this.filtersService.selectedFiltersChanged,
      ]).subscribe(([x, y]: [Array<Customer>, SelectedFilters]) => {
        this.customers = x;
        this.loadCompleted = true;
        this.selectedCustomer = y.customer;
      })
    );
  }

  onSearchTextChanged(searchText: string): void {
    this.searchText = searchText;
  }

  onChangeCustomer(customer: Customer): void {
    this.selectedCustomer = customer;
    // this.clearCustomerSearch();
    this.filtersService.updateSelectedCustomer(customer.MasterCustomerNumber);
    this.filtersService.refreshCustomerSettings(() => {
      this.filtersService.applyFilters();
    });
  }
}
