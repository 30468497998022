import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import * as d3 from 'd3-selection';
import 'd3-transition';

import { ValueType } from 'src/app/shared/constants/common.constants';
import { TargetsTileProgress } from '../../../entities/targets-tile-progress';
import { CustomNumberPipe } from 'src/app/shared/pipes/custom-number.pipe';
import { TargetsTileTarget } from '../../../entities/targets-tile-item';
import { TargetsChartConfig } from './entities/targets-chart-config';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-targets-chart',
  templateUrl: './targets-chart.component.html',
  styleUrls: ['./targets-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class TargetsChartComponent implements OnInit, OnDestroy {
  subscription = new Subscription();

  @Input() onConfigChangedObs: Observable<TargetsChartConfig>;

  config: TargetsChartConfig;
  chartId = `chart-${uuidv4()}`;
  width = 420;
  height = 150;
  middleHorizontal: number = this.width / 2 + 5;
  minHorizontal: number = this.middleHorizontal - 45;
  maxHorizontal: number = this.middleHorizontal + 45;

  ngOnInit(): void {
    if (this.onConfigChangedObs) {
      this.subscription.add(
        this.onConfigChangedObs.subscribe((x: TargetsChartConfig) => {
          this.config = x;
          this.renderChart();
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  renderChart(): void {
    d3.selectAll(`#${this.chartId} > *`).remove();

    if (this.config) {
      // svg component
      const svg: any = d3
        .select(`#${this.chartId}`)
        .attr('width', this.width)
        .attr('height', this.height);

      // global progress
      const globalProgress: any = svg
        .append('g')
        .attr('class', 'global-progress');

      // global progress - dashed line
      globalProgress
        .append('line')
        .attr('x1', this.middleHorizontal)
        .attr('x2', this.middleHorizontal)
        .attr('y1', 20)
        .attr('y2', 20)
        .attr('class', 'progress-dashed-line')
        .transition()
        .duration(2000)
        .delay((d: any, i: any) => {
          return i * 1000;
        })
        .attr('y2', 120);

      globalProgress
        .append('text')
        .attr('x', this.middleHorizontal)
        .attr('y', 135)
        .attr('class', 'progress-value')
        .text(this.getProgress());

      // svg - common labels
      svg
        .append('text')
        .attr('x', 130)
        .attr('y', 20)
        .attr('class', 'title start')
        .text('Progress');

      svg
        .append('text')
        .attr('x', 320)
        .attr('y', 20)
        .attr('class', 'title end')
        .text('Target');

      svg
        .append('text')
        .attr('x', 400)
        .attr('y', 20)
        .attr('class', 'title end')
        .text('Variance');

      // svg - targets
      let rowPosition = 50;
      const rowMargin = 30;
      const targets: Array<TargetsTileTarget> = this.getTargets();

      targets.forEach((x: TargetsTileTarget) => {
        this.drawProgressBar(svg, x, rowPosition);
        this.drawVarianceBar(svg, x, rowPosition);

        svg
          .append('text')
          .attr('x', 10)
          .attr('y', rowPosition)
          .attr('class', 'target-title')
          .text(x.title);

        svg
          .append('text')
          .attr('x', 110)
          .attr('y', rowPosition)
          .attr('class', 'target-progress start')
          .text(x.getProgressText());

        svg
          .append('text')
          .attr('x', 320)
          .attr('y', rowPosition)
          .attr('class', 'target-value end')
          .text(x.getTargetText());

        svg
          .append('text')
          .attr('x', 400)
          .attr('y', rowPosition)
          .attr(
            'class',
            `target-variance end ${x.variance < 0 ? 'negative' : ''}`
          )
          .text(
            this.config.isPercentage && x.variance
              ? `${x.getVarianceText()} bps`
              : x.getVarianceText()
          );

        rowPosition += rowMargin;
      });
    }
  }

  private getProgress(): string {
    const customNumberPipe = new CustomNumberPipe();
    const type: ValueType = this.config.isPercentage
      ? ValueType.Percentage
      : ValueType.Numeric;
    const progress: TargetsTileProgress = this.config.tile.progress.find(
      (x: TargetsTileProgress) => x.type === type
    );
    return progress
      ? customNumberPipe.transform(progress.value, type, '-')
      : '-';
  }

  private getTargets(): Array<TargetsTileTarget> {
    const type: ValueType = this.config.isPercentage
      ? ValueType.Percentage
      : ValueType.Numeric;
    const targets: Array<TargetsTileTarget> = this.config.tile.targets.filter(
      (x: TargetsTileTarget) => x.targetType === type
    );
    return targets ? targets : [];
  }

  private drawProgressBar(
    svg: any,
    target: TargetsTileTarget,
    rowPosition: number
  ): void {
    if (target.progress) {
      const maxProgressWidth: number =
        this.middleHorizontal - this.minHorizontal;
      const progressWidth: number =
        target.progress > 2
          ? maxProgressWidth / target.progress
          : target.progress > 1
          ? maxProgressWidth
          : maxProgressWidth * target.progress;

      svg
        .append('rect')
        .attr('x', this.middleHorizontal - progressWidth - 1)
        .attr('y', rowPosition - 12)
        .attr('width', 0)
        .attr('height', 16)
        .attr('class', 'progress-bar')
        .transition()
        .duration(2000)
        .delay((d: any, i: any) => {
          return i * 1000;
        })
        .attr('width', progressWidth);
    }
  }

  private drawVarianceBar(
    svg: any,
    target: TargetsTileTarget,
    rowPosition: number
  ): void {
    if (target.progress && target.target) {
      const maxProgressWidth: number =
        this.middleHorizontal - this.minHorizontal;

      const maxVarianceWidth: number =
        target.progress > 1
          ? this.middleHorizontal - this.minHorizontal
          : this.maxHorizontal - this.middleHorizontal;

      const progressWidth: number =
        target.progress > 2
          ? maxVarianceWidth
          : target.progress > 1
          ? maxProgressWidth * (target.progress - 1)
          : maxVarianceWidth * (1 - target.progress);

      const position: number =
        target.progress > 1
          ? this.middleHorizontal - progressWidth - 1
          : this.middleHorizontal + 1;

      const varianceEndPosition: number =
        target.progress > 1
          ? this.middleHorizontal - progressWidth - 1
          : this.middleHorizontal + progressWidth + 1;

      svg
        .append('rect')
        .attr('x', position)
        .attr('y', rowPosition - 8)
        .attr('width', 0)
        .attr('height', 8)
        .attr(
          'class',
          `variance-bar ${target.variance < 0 ? 'negative' : 'positive'}`
        )
        .transition()
        .duration(2000)
        .delay((d: any, i: any) => {
          return i * 1000;
        })
        .attr('width', progressWidth);

      svg
        .append('line')
        .attr('x1', varianceEndPosition)
        .attr('x2', varianceEndPosition)
        .attr('y1', rowPosition - 15)
        .attr('y2', rowPosition - 15)
        .attr('class', 'variance-end')
        .transition()
        .duration(2000)
        .delay((d: any, i: any) => {
          return i * 1000;
        })
        .attr('y2', rowPosition + 7);
    }
  }
}
