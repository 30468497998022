import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Params, ResolveEnd, Router } from '@angular/router';
import {
  combineLatest,
  ReplaySubject,
  Subject,
  Subscription,
  catchError,
  concatMap,
  first,
  interval,
  Observable,
  switchMap,
  timeout,
} from 'rxjs';
import { cloneDeep, difference, includes, isArray, isUndefined } from 'lodash';

import {
  CellEditingStartedEvent,
  CellValueChangedEvent,
  ColDef,
  SelectionChangedEvent,
} from 'ag-grid-community';
import {
  GridToolbarConfig,
  GridToolbarDropdown,
  GridToolbarIcon,
  GridToolbarSwitch,
} from 'src/app/shared/components/base/grid/components/grid-toolbar/entities/grid-toolbar-config';
import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import {
  ComponentNames,
  GridColDefs,
  GridFilters,
} from './constants/opportunities-grid.constants';
import { RequestPayload } from 'src/app/shared/services/entities/request-payload';
import {
  GridConfig,
  GridConfigFeatures,
} from 'src/app/shared/components/base/grid/entities/grid-config';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { CustomerFilters } from 'src/app/shared/services/entities/filters/customer-filters';
import { GridObservables } from 'src/app/shared/components/base/grid/entities/grid-observables';
import { OpportunityResponseItem } from 'src/app/shared/services/entities/grids/opportunities-response';
import {
  OpportunityTypes,
  ViewOptions,
} from 'src/app/shared/constants/grid.constants';
import { MessageTemplates } from 'src/app/shared/constants/messages.constants';
import {
  AppMessage,
  AppMessageButton,
} from 'src/app/shared/services/entities/app-message';
import { ToastTemplates } from 'src/app/shared/constants/toasts.constants';
import { GridCellIcon } from 'src/app/shared/components/base/grid/entities/grid-cell-icon';
import { ActiveDate } from 'src/app/shared/services/entities/filters/active-date';
import { ActiveDates } from 'src/app/shared/constants/filters.constants';
import { OpportunityPhasingModalConfig } from 'src/app/shared/components/opportunity-phasing-modal/entities/opportunity-phasing-modal-config';
import { GridData } from 'src/app/shared/components/base/grid/entities/grid-data';
import { GridValidationsUtils } from 'src/app/shared/utils/grid-validations.utils';
import { StorageUtils } from 'src/app/core/utils/storage.utils';
import { StorageType } from 'src/app/core/constants/storage.constants';
import { TabControlNames } from 'src/app/shared/components/board-modal/components/board-projection-tab/constants/opportunities-tab.constants';
import { BoardTabControl } from 'src/app/shared/services/entities/board-response';
import { AppModulePaths } from 'src/app/shared/constants/navbar.constants';

import { OpportunitiesGridService } from 'src/app/shared/services/grids/opportunities-grid.service';
import { FiltersService } from 'src/app/shared/services/filters.service';
import { AppMessagesService } from 'src/app/shared/services/app-messages.service';
import { AppToastsService } from 'src/app/shared/services/app-toasts.service';
import { SecurityService } from 'src/app/shared/services/security.service';
import { OpportunityPhasingModalService } from 'src/app/shared/services/modals/opportunity-phasing-modal.service';
import { BoardService } from 'src/app/shared/services/board.service';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faDownload,
  faExpand,
  faCompress,
  faSave,
  faReply,
  faPencilAlt,
  faEye,
  faCloudUpload,
} from '@fortawesome/free-solid-svg-icons';
import { BetaMessageService } from 'src/app/shared/services/beta-message.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { AppState } from 'src/app/shared/services/entities/app-state/app-state';
import { GridComponent } from '../../../../../shared/components/base/grid/grid.component';
import { GridToolbarComponent } from '../../../../../shared/components/base/grid/components/grid-toolbar/grid-toolbar.component';

@Component({
  selector: 'app-opportunities-grid',
  templateUrl: './opportunities-grid.component.html',
  styleUrls: ['./opportunities-grid.component.scss'],
  standalone: true,
  imports: [GridToolbarComponent, GridComponent],
})
export class OpportunitiesGridComponent implements OnInit, OnDestroy {
  @Output() expandChangedEvent = new EventEmitter<boolean>();
  isCloud: boolean;
  isBeta = false;
  isFlipFlopEnabled: boolean;

  subscription = new Subscription();
  loadChangedEvent = new Subject<boolean>();
  updateRowDataEvent = new ReplaySubject<GridData>(1);
  updateColDefsEvent = new ReplaySubject<Array<ColDef>>(1);
  filtersChangedEvent = new ReplaySubject<string>(1);
  stopEditingEvent = new Subject<boolean>();

  filters: Filters;
  selectedFilters: SelectedFilters;
  customerFilters: CustomerFilters;
  params: Params;
  hasAccountPlan: boolean;
  isReadOnlyMode: boolean;
  isAdmin: boolean;
  gridToolbarConfig: GridToolbarConfig;
  gridConfig: GridConfig;
  opportunities: Array<OpportunityResponseItem> = [];
  selectedOpportunities: Array<OpportunityResponseItem> = [];
  isExpanded: boolean;
  hasEdited: boolean;
  maxEditedRecords = 50;
  selectedTarget: TextValuePair;
  showCloudIcon = false;
  cloudRequestIds: Array<string> = new Array<string>();
  public get metricType(): typeof MetricType {
    return MetricType;
  }

  constructor(
    private filtersService: FiltersService,
    private opportunitiesGridService: OpportunitiesGridService,
    private oppPhasingModalService: OpportunityPhasingModalService,
    private securityService: SecurityService,
    private appMessagesService: AppMessagesService,
    private appToastsService: AppToastsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private boardService: BoardService,
    private betaMessage: BetaMessageService,
    private appStateService: AppStateService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      combineLatest([
        this.filtersService.globalFiltersChanged,
        this.filtersService.selectedFiltersChanged,
        this.activatedRoute.queryParams,
        this.appStateService.appStateChanged,
      ]).subscribe(
        ([w, x, y, a]: [Filters, SelectedFilters, Params, AppState]) => {
          this.filters = w;
          this.isFlipFlopEnabled = a.gcpFlipFlopFlagEnabled;

          const timeframeChanged: boolean =
            (this.selectedFilters &&
              this.selectedFilters.timeframe.getDescription() !==
                x.timeframe.getDescription()) ||
            false;
          this.selectedFilters = x;
          const paramsChanged: boolean =
            (this.params && difference([this.params], [y])?.length > 0) ||
            false;
          this.params = y;
          const boardChanged: boolean =
            this.boardService.selectedBoard?.tab?.SubTabName ===
            TabControlNames.opportunities;

          Promise.all([
            this.securityService.hasAccountPlanRole(
              this.selectedFilters.customer.MasterCustomerNumber
            ),
            this.securityService.isReadOnly(),
            this.securityService.isAdmin(),
          ]).then((z) => {
            const accountPlanChanged: boolean = this.hasAccountPlan !== z[0];
            this.hasAccountPlan = z[0];
            this.isReadOnlyMode = z[1];
            this.isAdmin = z[2];

            if (
              !this.gridToolbarConfig ||
              paramsChanged ||
              accountPlanChanged ||
              boardChanged
            ) {
              this.initializeToolbar();
              this.filtersService.updateTarget(this.selectedTarget);
              this.initializeGridConfig();
            }

            this.validateFilterSelection(timeframeChanged);

            this.getData();
          });
        }
      )
    );

    this.subscription.add(
      this.filtersService.customerFiltersChanged.subscribe(
        (x: CustomerFilters) => {
          if (!isUndefined(this.customerFilters)) {
            StorageUtils.remove(StorageType.SessionStorage, 'search-filter');
            this.gridToolbarConfig.searchText = null;
          }

          this.customerFilters = x;
        }
      )
    );

    this.subscription.add(
      this.router.events.subscribe((x: any) => {
        if (x instanceof ResolveEnd) {
          if (
            !x.urlAfterRedirects.includes('phasing') &&
            !x.urlAfterRedirects.includes(
              AppModulePaths.ieopportunity.subtabs.opportunities.fullpath
            )
          ) {
            StorageUtils.remove(StorageType.SessionStorage, 'search-filter');
          }
        }
      })
    );
    this.oppPhasingModalService.closeOppPhasingModalEmitted.subscribe(
      (oppId) => {
        const val1 = Number(oppId);
        this.opportunities.forEach((opp) => {
          if (opp.Id == val1) {
            opp.isDataUpdatedOnGcp = true;
          }
        });
        const x = this.mapToGridData(this.opportunities);
        this.updateRowDataEvent.next(x);
      }
    );

    this.oppPhasingModalService.saveOppPhasingSuccessEmitted.subscribe(
      (requestId) => {
        this.getPhasingUpdateStatus(requestId).then(() => {
          this.filtersService.applyFilters();
        });
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initializeToolbar(): void {
    const targets: Array<TextValuePair> = GridFilters.TargetOptions(
      this.selectedFilters
    );

    let selectedType: TextValuePair = this.params?.stage
      ? GridFilters.Types.find(
          (x: TextValuePair) => x.value === this.params.stage
        )
      : GridFilters.Types[0];

    this.selectedTarget = this.params?.target
      ? targets.find((x: TextValuePair) => x.value === this.params.target)
      : this.selectedFilters.target ?? targets[0];

    let isNoActivity: boolean;
    let isMsaIndicator: boolean;

    if (
      this.boardService.selectedBoard?.tab?.SubTabName ===
      TabControlNames.opportunities
    ) {
      const typesDropdown: BoardTabControl =
        this.boardService.selectedBoard.tab.Controls.find(
          (x: BoardTabControl) => x.key === TabControlNames.types
        );
      const targetDropdown: BoardTabControl =
        this.boardService.selectedBoard.tab.Controls.find(
          (x: BoardTabControl) => x.key === TabControlNames.targets
        );
      const expandSwitch = this.boardService.selectedBoard.tab.Controls.find(
        (x: BoardTabControl) => x.key === TabControlNames.expand
      );
      isNoActivity = this.boardService.selectedBoard.tab.Controls.find(
        (x: BoardTabControl) => x.key === TabControlNames.noActivity
      )?.value;
      isMsaIndicator = this.boardService.selectedBoard.tab.Controls.find(
        (x: BoardTabControl) => x.key === TabControlNames.msaIndicator
      )?.value;

      selectedType = GridFilters.Types.find(
        (x: TextValuePair) =>
          x.value.toLowerCase() === typesDropdown.value.toLowerCase()
      );

      this.selectedTarget = targets.find(
        (x: TextValuePair) =>
          x.value.toLowerCase() === targetDropdown.value.toLowerCase()
      );

      this.isExpanded = expandSwitch?.value;
      this.expandChangedEvent.emit(expandSwitch?.value);

      this.boardService.selectedBoard = null;
    }

    const isFullyWeighted: boolean = this.selectedFilters.fullyWeighted;

    this.gridToolbarConfig = new GridToolbarConfig({
      componentName: ComponentNames.opportunities,
      controls: [
        new GridToolbarDropdown({
          controlName: ComponentNames.targets,
          items: targets,
          onChange: this.onTargetChanged.bind(this),
          selected: this.selectedTarget,
        }),
        new GridToolbarDropdown({
          controlName: ComponentNames.types,
          items: GridFilters.Types,
          onChange: this.onOppTypeChanged.bind(this),
          selected: selectedType,
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.save,
          icon: faSave as IconProp,
          onClick: this.onSave.bind(this),
          text: 'SAVE',
          isDisabled: () => {
            return (
              !this.hasAccountPlan || !this.hasEdited || this.isReadOnlyMode
            );
          },
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.export,
          icon: faDownload as IconProp,
          onClick: this.onFileExport.bind(this),
          text: 'EXPORT',
          isDisabled: (x: GridToolbarConfig) => {
            const targetsDropdown: GridToolbarDropdown =
              this.gridToolbarConfig.findControl(ComponentNames.targets);

            return (
              targetsDropdown.selected.value === ViewOptions.CompareProjection
            );
          },
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.expand,
          icon: faExpand as IconProp,
          onClick: () => {
            this.isExpanded = true;
            this.expandChangedEvent.emit(true);
          },
          text: 'EXPAND',
          isVisible: () => {
            return !this.isExpanded;
          },
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.compress,
          icon: faCompress as IconProp,
          onClick: () => {
            this.isExpanded = false;
            this.expandChangedEvent.emit(false);
          },
          text: 'EXIT',
          isVisible: () => {
            return this.isExpanded;
          },
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.reset,
          icon: faReply as IconProp,
          onClick: this.onReset.bind(this),
          text: 'RESET',
          isDisabled: () => {
            return (
              !this.hasAccountPlan ||
              this.isReadOnlyMode ||
              this.hasEdited ||
              this.selectedOpportunities.length === 0 ||
              this.selectedOpportunities.length > this.maxEditedRecords ||
              this.selectedOpportunities.filter((x: OpportunityResponseItem) =>
                x.hasDifferences()
              ).length === 0
            );
          },
        }),
        new GridToolbarSwitch({
          controlName: ComponentNames.noActivity,
          text: 'NO ACTIVITY',
          onSwitch: this.onNoActivitySwitchChanged.bind(this),
          switchValue: isNoActivity,
        }),
        new GridToolbarSwitch({
          controlName: ComponentNames.msaIndicator,
          text: 'MSA CVF',
          onSwitch: this.onMsaIndicatorSwitchChanged.bind(this),
          switchValue: isMsaIndicator,
        }),
        new GridToolbarSwitch({
          controlName: ComponentNames.fullyWeighted,
          text: 'FULLY WEIGHTED',
          onSwitch: this.onFullyWeightedSwitchChanged.bind(this),
          switchValue: isFullyWeighted,
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.cloudLoader,
          icon: faCloudUpload as IconProp,
          onClick: null,
          text: 'Saving To Cloud',
          isVisible: () => {
            return this.showCloudIcon;
          },
        }),
      ],
      searchText: StorageUtils.get(StorageType.SessionStorage, 'search-filter'),
    });
  }

  initializeGridConfig(): void {
    this.gridConfig =
      this.gridConfig ??
      new GridConfig({
        columns: [],
        features: new GridConfigFeatures({
          hasTotalsRow: true,
        }),
        options: GridColDefs.getExtraGridOptions(this.filters),
        observables: new GridObservables({
          loadChanged: this.loadChangedEvent,
          updateColDefs: this.updateColDefsEvent,
          updateRowData: this.updateRowDataEvent,
          filterChanged: this.filtersChangedEvent,
          expandChanged: this.expandChangedEvent,
          stopEditing: this.stopEditingEvent,
        }),
      });

    this.gridConfig.observables.cellValueChanged.subscribe(
      (x: CellValueChangedEvent) => {
        this.onCellValueChanged(x);
      }
    );

    this.gridConfig.observables.selectionChanged.subscribe(
      (x: SelectionChangedEvent) => {
        this.selectedOpportunities = x.api.getSelectedRows();
      }
    );

    this.gridConfig.observables.cellEditingStarted.subscribe(
      (x: CellEditingStartedEvent) => {
        const opportunitiesEdited: Array<OpportunityResponseItem> =
          this.opportunities.filter((x: OpportunityResponseItem) => x.isEdited);
        if (
          opportunitiesEdited.length >= this.maxEditedRecords &&
          !includes(
            opportunitiesEdited.map((x: OpportunityResponseItem) => x.Id),
            x.data.Id
          )
        ) {
          this.stopEditingEvent.next(true);
          const execeedMessage: AppMessage = MessageTemplates.ExportSgBreakdown;
          execeedMessage.body.forEach((x: string) => {
            x = x.replace(
              '[maxEditedRecords]',
              this.maxEditedRecords.toString()
            );
          });
          this.appMessagesService.show(execeedMessage, { centered: true });
        }
      }
    );

    this.updateColDefs();
  }

  updateColDefs(): void {
    this.updateColDefsEvent.next(
      GridColDefs.getColDefs(
        this.gridToolbarConfig,
        this.hasAccountPlan,
        this.isReadOnlyMode,
        this.isAdmin,
        [
          new GridCellIcon({
            icon: faPencilAlt as IconProp,
            tooltip: 'Edit',
            onClick: this.onEditOpportunity.bind(this),
            showIconByRow: (x: OpportunityResponseItem) => {
              return (
                x.IsCompleteFinancials &&
                !this.isReadOnlyMode &&
                this.hasAccountPlan
              );
            },
          }),
          new GridCellIcon({
            icon: faEye as IconProp,
            tooltip: 'View',
            onClick: this.onViewOpportunity.bind(this),
            showIconByRow: (x: OpportunityResponseItem) => {
              return (
                x.IsCompleteFinancials &&
                (!this.hasAccountPlan || this.isReadOnlyMode)
              );
            },
          }),
          new GridCellIcon({
            icon: faCloudUpload as IconProp,
            tooltip: 'In-Progress',
            onClick: null,
            showIconByRow: (x: OpportunityResponseItem) => {
              return x.isDataUpdatedOnGcp;
            },
          }),
        ]
      )
    );
  }

  getData(): void {
    this.loadChangedEvent.next(true);
    const targetsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.targets);

    const extraParams: Record<string, any> = {
      groupBy: 'Opportunity',
      dataSet: targetsDropdown.selected.value,
      sapCodePlan: this.selectedFilters.plan.year.toString(),
    };

    this.subscription.add(
      this.betaMessage.isCloudSubject$.subscribe((betaFlag) => {
        this.isBeta = betaFlag;
        this.isCloud =
          (betaFlag && !this.isFlipFlopEnabled) ||
          (!betaFlag && this.isFlipFlopEnabled);
        this.opportunitiesGridService
          .getOpportunities(
            RequestPayload.createRequest(this.selectedFilters, extraParams),
            this.isCloud
          )
          .then((x: Array<OpportunityResponseItem>) => {
            this.opportunities = x;
            this.showNotifications();
            this.updateRowDataEvent.next(this.mapToGridData(x));
            this.loadChangedEvent.next(false);
            this.expandChangedEvent.emit(this.isExpanded);
          });
      })
    );
  }

  mapToGridData(response: Array<OpportunityResponseItem>): GridData {
    let opportunities: Array<OpportunityResponseItem> = cloneDeep(response);

    const noActivitySwitch: GridToolbarSwitch =
      this.gridToolbarConfig.findControl(ComponentNames.noActivity);

    const msaIndicatorSwitch: GridToolbarSwitch =
      this.gridToolbarConfig.findControl(ComponentNames.msaIndicator);

    const typesDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.types);

    if (noActivitySwitch.switchValue) {
      opportunities = opportunities.filter(
        (x: OpportunityResponseItem) => !x.isInactive()
      );
    }

    if (msaIndicatorSwitch.switchValue) {
      opportunities = opportunities.filter(
        (x: OpportunityResponseItem) => x.MsaIndicator
      );
    }

    switch (typesDropdown.selected.value) {
      case OpportunityTypes.Qualified:
        opportunities = opportunities.filter((x: OpportunityResponseItem) =>
          x.isQualified()
        );
        break;
      case OpportunityTypes.Unqualified:
        opportunities = opportunities.filter((x: OpportunityResponseItem) =>
          x.isUnqualfied()
        );
        break;
    }

    return new GridData({
      rows: opportunities,
      pinnedTop: [],
    });
  }

  showNotifications(): void {
    const appDate: ActiveDate = this.filters.activeDates.find(
      (x: ActiveDate) => x.DateTypeCd === ActiveDates.ApplicationDate
    );

    if (
      this.opportunities.some((x: OpportunityResponseItem) => {
        return (
          GridColDefs.Shared.getOppDateId(
            this.filters,
            x.StatusSinceDate?.toString()
          ) < appDate.StartTimeId ||
          GridColDefs.Shared.getOppDateId(
            this.filters,
            x.StartDate?.toString()
          ) < appDate.StartTimeId
        );
      })
    ) {
      MessageTemplates.OpportunitiesElapsedDates.id = `${AppModulePaths.ieopportunity.id}-elapsed-${this.selectedFilters.customer.MasterCustomerNumber}`;
      this.appMessagesService.show(MessageTemplates.OpportunitiesElapsedDates, {
        centered: true,
      });
    }
  }

  validateFilterSelection(timeframeChanged?: boolean): void {
    let colDefsUpdated = false;

    const targetsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.targets);

    GridValidationsUtils.validateSubMetricSelection(
      targetsDropdown,
      this.filters,
      this.selectedFilters,
      () => {
        this.updateColDefs();
        colDefsUpdated = true;
      }
    );

    if (timeframeChanged && !colDefsUpdated) {
      this.updateColDefs();
    }
  }

  onOppTypeChanged(
    selected: TextValuePair,
    dropdown: GridToolbarDropdown
  ): void {
    dropdown.selected = selected;
    this.updateRowDataEvent.next(this.mapToGridData(this.opportunities));
  }

  onTargetChanged(
    selected: TextValuePair,
    dropdown: GridToolbarDropdown
  ): void {
    dropdown.selected = selected;
    this.filtersService.updateTarget(selected);
    this.updateColDefs();
    this.getData();
  }

  onFileExport(): void {
    new Promise(
      (
        resolve: (x: Record<string, boolean>) => void,
        reject: () => void
      ): void => {
        const sgMessage: AppMessage = MessageTemplates.ExportSgBreakdown;
        const wmuMessage: AppMessage = MessageTemplates.ExportWmuBreakdown;
        let isSgBreakdown = false;
        let isWmuBreakdown = false;
        sgMessage.buttons.forEach((x: AppMessageButton) => {
          x.action = (): void => {
            isSgBreakdown = x.text === 'Yes';
            this.appMessagesService.close(sgMessage.id);
            this.appMessagesService.show(wmuMessage, { centered: true });
          };
        });
        wmuMessage.buttons.forEach((x: AppMessageButton) => {
          x.action = (): void => {
            isWmuBreakdown = x.text === 'Yes';
            this.appMessagesService.close(wmuMessage.id);
            resolve({
              isSgBreakdown,
              isWmuBreakdown,
            });
          };
        });
        this.appMessagesService.show(sgMessage, { centered: true });
      }
    ).then((x: Record<string, boolean>) => {
      this.appMessagesService.show(MessageTemplates.FileExporterInit, {
        centered: true,
      });
      this.opportunitiesGridService
        .export(this.selectedFilters, this.gridToolbarConfig, x, this.isCloud)
        .then((x: boolean) => {
          if (!x) {
            this.appMessagesService.show(MessageTemplates.UnexpectedError, {
              centered: true,
            });
          }
        });
    });
  }

  onReset(): void {
    const resetMessage: AppMessage =
      MessageTemplates.ProjectionPercentageResetConfirm;
    resetMessage.buttons.find(
      (x: AppMessageButton) => x.text === 'Yes'
    ).action = (): void => {
      this.appMessagesService.close(resetMessage.id);

      this.selectedOpportunities.forEach((x: OpportunityResponseItem) => {
        const opportunity: OpportunityResponseItem = this.opportunities.find(
          (y: OpportunityResponseItem) => y.Id === x.Id
        );
        if (opportunity) {
          opportunity.reset();
        }
      });

      this.onSave();
    };
    this.appMessagesService.show(resetMessage, { centered: true });
  }

  onSave(): void {
    if (this.isCloud) {
      this.OnSaveCloud();
    } else {
      this.OnSaveCore();
    }
  }

  OnSaveCore(): void {
    if (this.opportunities.some((x: OpportunityResponseItem) => x.isInvalid)) {
      this.appMessagesService.show(
        MessageTemplates.OpportunitiesEditionInvalid,
        { centered: true }
      );
    } else {
      this.loadChangedEvent.next(true);
      const opportunitiesEdited = this.opportunities.filter(
        (x: OpportunityResponseItem) => x.isEdited
      );
      this.hasEdited = false; //Disable button, avoid multiple calls
      this.opportunitiesGridService
        .saveOpportunities(
          opportunitiesEdited,
          this.selectedFilters.customer,
          this.isCloud
        )
        .then((x: Array<OpportunityResponseItem>) => {
          if (isArray(x)) {
            this.hasEdited = false;
            this.selectedOpportunities = [];
            this.opportunities.forEach((x: OpportunityResponseItem) => {
              x.isEdited = false;
              x.isInvalid = false;
            });
            this.appToastsService.show(
              ToastTemplates.ProjectionPercentageSaved
            );
          } else {
            throw new Error(x);
          }
        })
        .catch((error) => {
          if (error.name === 'TimeoutError') {
            this.appToastsService.show(
              ToastTemplates.ProjectionPercentageSaved
            );
          } else {
            this.hasEdited = true; //Allow user to try again Save option
            this.appMessagesService.show(MessageTemplates.UnexpectedError, {
              centered: true,
            });
          }
        })
        .finally(() => {
          this.updateRowDataEvent.next(this.mapToGridData(this.opportunities));
          this.loadChangedEvent.next(false);
        });
    }
  }

  OnSaveCloud(): void {
    if (this.opportunities.some((x: OpportunityResponseItem) => x.isInvalid)) {
      this.appMessagesService.show(
        MessageTemplates.OpportunitiesEditionInvalid,
        { centered: true }
      );
    } else {
      this.loadChangedEvent.next(true);
      const opportunitiesEdited = this.opportunities.filter(
        (x: OpportunityResponseItem) => x.isEdited
      );
      this.hasEdited = false; //Disable button, avoid multiple calls
      this.opportunitiesGridService
        .saveOpportunities(
          opportunitiesEdited,
          this.selectedFilters.customer,
          this.isCloud
        )
        .then((x: any) => {
          if (isArray(x.opportunities)) {
            this.hasEdited = false;
            this.selectedOpportunities = [];
            this.opportunities.forEach((x: OpportunityResponseItem) => {
              x.isEdited = false;
              x.isInvalid = false;
            });
            this.appToastsService.show(
              ToastTemplates.ProjectionPercentageSaved
            );
          } else {
            throw new Error(x);
          }

          this.cloudRequestIds = x.requestIds;
          this.showCloudIcon = true;
          const cloudLoaderControl: GridToolbarIcon =
            this.gridToolbarConfig.controls.find((control) => {
              return control.controlName === ComponentNames.cloudLoader;
            });
          cloudLoaderControl.isVisible = () => {
            return this.showCloudIcon;
          };

          this.updateRowDataEvent.next(this.mapToGridData(this.opportunities));
          this.loadChangedEvent.next(false);
        })
        .catch((error) => {
          if (error.name === 'TimeoutError') {
            this.appToastsService.show(
              ToastTemplates.ProjectionPercentageSaved
            );
          } else {
            this.hasEdited = true; //Allow user to try again Save option
            this.appMessagesService.show(MessageTemplates.UnexpectedError, {
              centered: true,
            });
          }
        })
        .finally(() => {
          this.getProjectionUpdateStatus(this.cloudRequestIds).then(
            (response: boolean) => {
              if (response) {
                this.loadChangedEvent.next(true);
                this.updateRowDataEvent.next(
                  this.mapToGridData(this.opportunities)
                );

                this.showCloudIcon = false;
                const cloudLoaderControl: GridToolbarIcon =
                  this.gridToolbarConfig.controls.find((control) => {
                    return control.controlName === ComponentNames.cloudLoader;
                  });
                cloudLoaderControl.isVisible = () => {
                  return this.showCloudIcon;
                };
                this.loadChangedEvent.next(false);
              }
            }
          );
        });
    }
  }

  onSearchTextChanged(searchText: string): void {
    StorageUtils.set(StorageType.SessionStorage, 'search-filter', searchText);
    this.filtersChangedEvent.next(searchText);
  }

  onNoActivitySwitchChanged(
    value: boolean,
    switchControl: GridToolbarSwitch
  ): void {
    switchControl.switchValue = value;
    this.updateRowDataEvent.next(this.mapToGridData(this.opportunities));
  }

  onMsaIndicatorSwitchChanged(
    value: boolean,
    switchControl: GridToolbarSwitch
  ): void {
    switchControl.switchValue = value;
    this.updateRowDataEvent.next(this.mapToGridData(this.opportunities));
  }

  onFullyWeightedSwitchChanged(
    value: boolean,
    switchControl: GridToolbarSwitch
  ): void {
    this.filtersService.updateFullyWeighted(value);
    switchControl.switchValue = value;
  }

  onCellValueChanged(event: CellValueChangedEvent): void {
    if (event && Number(event.oldValue) !== Number(event.newValue)) {
      const editedOpportunity: OpportunityResponseItem = event.data;
      const value = Number(editedOpportunity.ProjectionPct);
      const source: OpportunityResponseItem = this.opportunities.find(
        (x: OpportunityResponseItem) => x.Id === editedOpportunity.Id
      );

      if (source) {
        this.hasEdited = true;
        source.isEdited = true;
        source.isInvalid = value < 0 || value > 100;
        source.ProjectionPct = source.isInvalid ? source.ProjectionPct : value;
      }
    }
  }

  onEditOpportunity(opportunity: OpportunityResponseItem): void {
    const targetsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.targets);

    this.oppPhasingModalService.openOppPhasingModalEmitted.next(
      new OpportunityPhasingModalConfig({
        filters: this.filters,
        selectedFilters: this.selectedFilters,
        customerFilters: this.customerFilters,
        dataSet: targetsDropdown.selected.value,
        isReadOnly: false,
        isAdmin: this.isAdmin,
        opportunityId: opportunity.Id,
      })
    );
  }

  getPhasingUpdateStatus(requestId: string): Promise<boolean> {
    const timeInterval = 2000;
    const timeoutDuration = 2000 * 30;
    return this.oppPhasingModalService
      .getUpdateStatus(requestId)
      .pipe(
        switchMap((x: boolean) => {
          return interval(timeInterval).pipe(
            concatMap(() =>
              this.oppPhasingModalService.getUpdateStatus(requestId)
            ),
            first((x: boolean) => {
              return x;
            })
          );
        }),
        timeout(timeoutDuration),
        catchError(this.errorHandler.bind(this))
      )
      .toPromise();
  }

  private errorHandler(): Observable<boolean> {
    throw {};
  }

  getProjectionUpdateStatus(requestIds: Array<string>): Promise<boolean> {
    const timeInterval = 2000;
    const timeoutDuration = 2000 * 30;
    return this.opportunitiesGridService
      .getUpdateStatus(requestIds)
      .pipe(
        switchMap((x: boolean) => {
          return interval(timeInterval).pipe(
            concatMap(() =>
              this.opportunitiesGridService.getUpdateStatus(requestIds)
            ),
            first((x: boolean) => {
              return x;
            })
          );
        }),
        timeout(timeoutDuration),
        catchError(this.errorHandler.bind(this))
      )
      .toPromise();
  }

  onViewOpportunity(opportunity: OpportunityResponseItem): void {
    const targetsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.targets);

    this.oppPhasingModalService.openOppPhasingModalEmitted.next(
      new OpportunityPhasingModalConfig({
        filters: this.filters,
        selectedFilters: this.selectedFilters,
        customerFilters: this.customerFilters,
        dataSet: targetsDropdown.selected.value,
        isReadOnly: true,
        isAdmin: this.isAdmin,
        opportunityId: opportunity.Id,
      })
    );
  }
}
