import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { FiltersService } from '../../services/filters.service';
import { SharedService } from '../../services/shared.service';
import { AppStateService } from '../../services/app-state.service';
import { AppState } from '../../services/entities/app-state/app-state';
import { BetaMessageService } from 'src/app/shared/services/beta-message.service';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-gcp-filter-toggle',
  templateUrl: './gcp-filter-toggle.component.html',
  styleUrls: ['./gcp-filter-toggle.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class GcpFilterToggleComponent implements OnInit {
  @Output() buttonClicked = new EventEmitter<boolean>();

  betaAccess$: Observable<boolean>;
  isCloudFilter = false;
  isFlipFlopEnabled: boolean;
  buttonText: string;
  public hasBetaAccess = false;
  subscription = new Subscription();

  constructor(
    private router: Router,
    private filtersService: FiltersService,
    private sharedService: SharedService,
    private appStateService: AppStateService,
    private betaMessage: BetaMessageService
  ) {}

  ngOnInit(): void {
    this.appStateService.appStateChanged.subscribe((AppState) => {
      this.isFlipFlopEnabled = AppState.gcpFlipFlopFlagEnabled;
      if (this.isFlipFlopEnabled) {
        this.isCloudFilter = true;
      }
      this.checkBetaAccess();

      if (this.isCloudFilter && this.hasBetaAccess) {
        //this.isPointingToGcp = true;
        this.buttonText = 'G';
      } else {
        //this.isPointingToGcp = false;
        this.buttonText = 'C';
      }
    });
  }

  onClick(): void {
    this.isCloudFilter = !this.isCloudFilter;
    if (this.isCloudFilter) {
      this.buttonText = 'G';
    } else {
      this.buttonText = 'C';
    }
    this.buttonClicked.emit(this.isCloudFilter);
  }

  userHasBetaAccess(): boolean {
    return this.hasBetaAccess;
  }

  checkBetaAccess(): void {
    this.subscription.add(
      this.appStateService.appStateChanged.subscribe((x: AppState) => {
        const betaAccessUserList = x.betaAccessUserList;
        this.hasBetaAccess = betaAccessUserList.includes(
          this.sharedService.getEnterpriseId()
        );
      })
    );
  }
}
