import { Component } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { CciGilcTileComponent } from '../../shared/components/tiles/gilc-tile/components/cci-gilc-tile/cci-gilc-tile.component';
import { CciTileComponent } from '../../shared/components/tiles/projection-tile/components/cci-tile/cci-tile.component';
import { RevenueGilcTileComponent } from '../../shared/components/tiles/gilc-tile/components/revenue-gilc-tile/revenue-gilc-tile.component';
import { RevenueTileComponent } from '../../shared/components/tiles/projection-tile/components/revenue-tile/revenue-tile.component';
import { SalesGilcTileComponent } from '../../shared/components/tiles/gilc-tile/components/sales-gilc-tile/sales-gilc-tile.component';
import { TrendTileComponent } from '../../shared/components/tiles/trend-tile/trend-tile.component';
import { TargetsTileComponent } from '../../shared/components/tiles/targets-tile/targets-tile.component';
import { SalesTileComponent } from '../../shared/components/tiles/projection-tile/components/sales-tile/sales-tile.component';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  standalone: true,
  imports: [
    SalesTileComponent,
    TargetsTileComponent,
    TrendTileComponent,
    SalesGilcTileComponent,
    RevenueTileComponent,
    RevenueGilcTileComponent,
    CciTileComponent,
    CciGilcTileComponent,
  ],
})
export class OverviewComponent {
  openSalesTrendModalEmittedEvent = new Subject<boolean>();
  openRevenueTrendModalEmittedEvent = new Subject<boolean>();
  openCciTrendModalEmittedEvent = new Subject<boolean>();

  subscription = new Subscription();

  constructor() {}

  public get metricType(): typeof MetricType {
    return MetricType;
  }

  onOpenTrendModalEmitted(isChartView: boolean, type: MetricType): void {
    switch (type) {
      case MetricType.Sales:
        this.openSalesTrendModalEmittedEvent.next(isChartView);
        break;
      case MetricType.Revenue:
        this.openRevenueTrendModalEmittedEvent.next(isChartView);
        break;
      case MetricType.Cci:
        this.openCciTrendModalEmittedEvent.next(isChartView);
        break;
    }
  }
}
