import { Component } from '@angular/core';
import { Subject } from 'rxjs';

import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';

import { GilcTileService } from 'src/app/shared/services/tiles/gilc-tile.service';
import { ProjectionTileService } from 'src/app/shared/services/tiles/projection-tile.service';
import { TargetTileService } from 'src/app/shared/services/tiles/target-tile.service';
import { TrendTileService } from 'src/app/shared/services/tiles/trend-tile.service';
import { PhasingGridComponent } from './components/phasing-grid/phasing-grid.component';
import { CciGilcTileComponent } from '../../shared/components/tiles/gilc-tile/components/cci-gilc-tile/cci-gilc-tile.component';
import { CciTileComponent } from '../../shared/components/tiles/projection-tile/components/cci-tile/cci-tile.component';
import { RevenueGilcTileComponent } from '../../shared/components/tiles/gilc-tile/components/revenue-gilc-tile/revenue-gilc-tile.component';
import { TargetsTileComponent } from '../../shared/components/tiles/targets-tile/targets-tile.component';
import { TrendTileComponent } from '../../shared/components/tiles/trend-tile/trend-tile.component';
import { RevenueTileComponent } from '../../shared/components/tiles/projection-tile/components/revenue-tile/revenue-tile.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-phasing',
  templateUrl: './phasing.component.html',
  styleUrls: ['./phasing.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    RevenueTileComponent,
    TrendTileComponent,
    TargetsTileComponent,
    RevenueGilcTileComponent,
    CciTileComponent,
    CciGilcTileComponent,
    PhasingGridComponent,
  ],
})
export class PhasingComponent {
  openTrendModalEmittedEvent = new Subject<boolean>();
  isGridExpanded: boolean;

  public get metricType(): typeof MetricType {
    return MetricType;
  }

  constructor(
    private targetTileService: TargetTileService,
    private trendTileService: TrendTileService,
    private projectionTileService: ProjectionTileService,
    private gilcTileService: GilcTileService
  ) {}

  onOpenTrendModalEmitted(isChartView: boolean, type: MetricType): void {
    this.openTrendModalEmittedEvent.next(isChartView);
  }

  onMetricChanged(selected: TextValuePair): void {
    this.targetTileService.metricChanged.next(selected);
    this.trendTileService.metricChanged.next(selected);
    this.projectionTileService.metricChanged.next(selected);
    this.gilcTileService.metricChanged.next(selected);
  }
}
