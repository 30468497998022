import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbAlertModule,
} from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { TrendModalConfig } from './entities/trend-modal-config';
import { TrendChartConfig } from '../trend-tile/components/trend-chart/entities/trend-chart-config';
import { TrendFiltersConfig } from '../trend-tile/components/trend-filters/entities/trend-filters-config';
import { TrendTileConstants } from '../trend-tile/constants/trend-tile.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { TrendTileDefinition } from '../entities/trend-tile-definition';
import {
  TrendModalFiltersChanged,
  TrendModalViewTypeChanged,
} from './entities/trend-modal-events';
import { TrendFiltersChanged } from '../trend-tile/components/trend-filters/entities/trend-filters-events';
import { TrendUtils } from '../utils/trend.utils';
import { TrendTableConfig } from '../trend-tile/components/trend-table/entities/trend-table-config';
import { GroupsBy } from 'src/app/shared/constants/filters.constants';
import { MessageTemplates } from 'src/app/shared/constants/messages.constants';

import { TrendModalService } from 'src/app/shared/services/tiles/trend-modal.service';
import { AppMessagesService } from 'src/app/shared/services/app-messages.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { AppState } from 'src/app/shared/services/entities/app-state/app-state';
import { BetaMessageService } from 'src/app/shared/services/beta-message.service';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { SpinnerComponent } from '../../base/spinner/spinner.component';
import { TrendTableComponent } from '../trend-tile/components/trend-table/trend-table.component';
import { TrendChartComponent } from '../trend-tile/components/trend-chart/trend-chart.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIf } from '@angular/common';
import { TrendFiltersComponent } from '../trend-tile/components/trend-filters/trend-filters.component';
import { DropDownComponent } from '../../base/drop-down/drop-down.component';
import { SharedModule } from 'src/app/shared-module';

@Component({
  selector: 'app-trend-modal',
  templateUrl: './trend-modal.component.html',
  styleUrls: ['./trend-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    DropDownComponent,
    TrendFiltersComponent,
    NgIf,
    SharedModule,
    FontAwesomeModule,
    TrendChartComponent,
    TrendTableComponent,
    SpinnerComponent,
  ],
})
export class TrendModalComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  @ViewChild('trendModal', { static: false }) trendModal: NgbModalRef;

  loaded = true;
  config: TrendModalConfig;
  trendChartConfig: TrendChartConfig;
  trendTableConfig: TrendTableConfig;
  trendFiltersConfig: TrendFiltersConfig;

  currentModal: NgbModalRef;
  isOpen = false;

  types: Array<TextValuePair> = [];
  selectedType: TextValuePair;

  showWmuAlert = true;
  showIndustryAlert = true;

  faExclamationCircle = faExclamationCircle as IconProp;
  isFlipFlopEnabled: boolean;
  isCloud: boolean;

  constructor(
    private modalService: NgbModal,
    private trendModalService: TrendModalService,
    private appMessagesService: AppMessagesService,
    private appStateService: AppStateService,
    private betaMessage: BetaMessageService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.trendModalService.openTrendModalEmitted.subscribe(
        (x: TrendModalConfig) => {
          this.config = cloneDeep(x);
          this.getTypeList();
          this.setFilters();

          if (x.isChartView) {
            this.renderChart();
          } else {
            this.renderTable();
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setFilters(): void {
    const definition: TrendTileDefinition = TrendUtils.getDefinition(
      this.config.type
    );

    this.trendFiltersConfig = new TrendFiltersConfig({
      componentName: this.selectedType.text,
      definition: definition,
      defaultFilters: cloneDeep(this.config.trendFilters),
      expandable: false,
      closable: true,
      showSubMetrics: true,
      showTimeframePicker: true,
    });
  }

  getTypeList(): void {
    this.types = [];

    const keys: Array<string> = Object.keys(TrendTileConstants);

    keys.forEach((x: string) => {
      const textValuePair = new TextValuePair({
        text: (TrendTileConstants as any)[x].title,
        value: (TrendTileConstants as any)[x].type,
      });

      if (textValuePair.value === this.config.type) {
        this.selectedType = textValuePair;
      }

      this.types.push(textValuePair);
    });
  }

  renderChart(): void {
    this.trendFiltersConfig.showSubMetrics = false;
    this.trendFiltersConfig.resettable = true;

    this.trendChartConfig = new TrendChartConfig({
      id: uuidv4(),
      width: 900,
      height: 600,
      definition: TrendUtils.getDefinition(this.config.type),
      response: this.config.response,
      filters: this.config.filters,
      selectedFilters: this.config.selectedFilters,
      trendFilters: this.config.trendFilters,
      type: this.config.type,
      isExpanded: true,
    });

    this.openModal();
  }

  renderTable(): void {
    this.trendFiltersConfig.showSubMetrics = true;
    this.trendFiltersConfig.resettable = false;

    this.trendTableConfig = new TrendTableConfig({
      id: uuidv4(),
      definition: TrendUtils.getDefinition(this.config.type),
      response: this.config.response,
      filters: this.config.filters,
      selectedFilters: this.config.selectedFilters,
      trendFilters: this.config.trendFilters,
      type: this.config.type,
    });

    this.openModal();
  }

  openModal(): void {
    if (!this.isOpen) {
      this.currentModal = this.modalService.open(this.trendModal, {
        windowClass: 'trend-modal',
        centered: true,
        size: 'lg',
      });

      this.isOpen = true;

      this.subscription.add(
        this.currentModal.dismissed.subscribe(() => {
          this.isOpen = false;
        })
      );
    }
  }

  loadStarted(): void {
    this.loaded = false;
  }

  loadCompleted(): void {
    this.loaded = true;
  }

  onCloseModal(): void {
    this.currentModal.dismiss();
  }

  onTypeChanged(type: TextValuePair): void {
    this.loadStarted();
    this.selectedType = type;
    this.trendModalService.filtersChanged.emit(
      new TrendModalFiltersChanged({
        isChartView: this.config.isChartView,
        tileParentId: this.config.tileParentId,
        type: this.selectedType.value,
        trendFilters: this.config.trendFilters,
        callback: () => {
          this.loadCompleted();
        },
      })
    );
  }

  onViewTypeChanged(): void {
    this.trendModalService.viewTypeChanged.emit(
      new TrendModalViewTypeChanged({
        isChartView: !this.config.isChartView,
        tileParentId: this.config.tileParentId,
      })
    );
  }

  onFiltersSelectionChanged(event: TrendFiltersChanged): void {
    this.showWmuAlert = event.trendFilters.groupBy.value === GroupsBy.WMU.id;
    this.showIndustryAlert =
      event.trendFilters.groupBy.value === GroupsBy.Industry.id;

    this.loadStarted();
    this.trendModalService.filtersChanged.emit(
      new TrendModalFiltersChanged({
        isChartView: this.config.isChartView,
        tileParentId: this.config.tileParentId,
        type: this.selectedType.value,
        trendFilters: event.trendFilters,
        reloadRequired: this.config.trendFilters.reloadRequired(
          event.trendFilters
        ),
        callback: () => {
          this.loadCompleted();
        },
      })
    );
  }

  onFileExport(extraParams: Record<string, any>) {
    this.appStateService.appStateChanged.subscribe((AppState) => {
      this.isFlipFlopEnabled = AppState.gcpFlipFlopFlagEnabled;
    });
    this.betaMessage.isCloudSubject$.subscribe((betaFlag) => {
      this.isCloud =
        (betaFlag && !this.isFlipFlopEnabled) ||
        (!betaFlag && this.isFlipFlopEnabled);
    });
    this.appMessagesService.show(MessageTemplates.FileExporterInit, {
      centered: true,
    });
    this.trendModalService
      .export(this.config.selectedFilters, extraParams, this.isCloud)
      .then((x: boolean) => {
        if (!x) {
          this.appMessagesService.show(MessageTemplates.UnexpectedError, {
            centered: true,
          });
        }
      });
  }

  onReset(): void {
    if (this.config.isChartView) {
      this.renderChart();
    } else {
      this.renderTable();
    }
  }
}
